import { dimsDesktop, dimsMobile } from "../../constants/Dimensions";
import { defaultTransitions } from "../../utils/const";

// Reload manually if Change
export const LoginRegisterStyle = (theme, isMobile) => {
  const Colors = theme.colors;
  const Spacing = theme.spacing;
  const dims = isMobile ? dimsMobile : dimsDesktop;
  const transitions = defaultTransitions(theme);

  const authBoxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.background,
    height: "100vh",
    transition: transitions,
  };

  const shrinkAnimation = {
    height: "0",
  };

  const growAnimation = {
    height: "100vh",
  };

  const authInnerBoxStyle = {
    position: "absolute",
    display: "flex",
    height: "355px",
    width: "80%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.surface,
    transition: transitions,
    boxShadow: theme.boxShadow,
    overflow: "hidden",
  };

  const loginBoxStyle = {
    ...authInnerBoxStyle,
  };

  const registerBoxStyle = {
    ...authInnerBoxStyle,
  };

  return {
    loginBoxStyle,
    registerBoxStyle,
    authBoxStyle,
    shrinkAnimation,
    growAnimation,
  };
};
