import React, { useState } from "react";
import * as authService from "../../../services/authService";
import { Card, Divider, Form, TextInput, Typography } from "../../ui/Index";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { CardStyles } from "../../styles/CardStyles";
import { validateRegistration } from "../../../utils/ButtonUtils";
import { SuccessButton } from "../../styles/ButtonStyles";

const RegisterForm = ({ t, theme, isMobile }) => {
  const [email, setEmail] = useState("admin@admin.com");
  const [registerPage, setRegisterPage] = useState(0);
  const [firstName, setFirstName] = useState("Admin");
  const [lastName, setLastName] = useState("Admin");
  const [password, setPassword] = useState("admin");
  const [repeatPassword, setRepeatPassword] = useState("admin");
  const [error, setError] = useState("");
  const { formStyle } = LayoutStyles(theme, isMobile);
  const { LoginRegisterCardStyle } = CardStyles(theme, isMobile);

  const fieldNamesPageFirst = ["Email"];

  const fieldNamesPageSecond = [
    "First Name",
    "Last Name",
    "Password",
    "Repeat Password",
  ];

  const fieldNamesPageThird = [];

  const isEmailValid = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleChangePage = () => {
    if (registerPage === 0) {
      if (!isEmailValid(email)) {
        console.log("email not valid " + email);
        setError("Please enter a valid email address.");
      } else {
        setRegisterPage(1);
      }
    } else {
      setRegisterPage(0);
    }
  };
  const handleChange = (e, fieldName) => {
    const { value } = e.target;
    switch (fieldName) {
      case "Email":
        setEmail(value);
        break;
      case "First Name":
        setFirstName(value);
        break;
      case "Last Name":
        setLastName(value);
        break;
      case "Password":
        setPassword(value);
        break;
      case "Repeat Password":
        setRepeatPassword(value);
        break;
      default:
        break;
    }
  };

  const getValueForFieldName = (fieldName) => {
    switch (fieldName) {
      case "Email":
        return email;
      case "First Name":
        return firstName;
      case "Last Name":
        return lastName;
      case "Password":
        return password;
      case "Repeat Password":
        return repeatPassword;
      default:
        return "";
    }
  };
  //TODO (UX): Exist check for email needs to happen on "Next" Click
  const handleSubmit = async (e) => {
    console.log("Submit");
    e.preventDefault();
    const isValid = validateRegistration(
      email,
      password,
      firstName,
      lastName,
      repeatPassword,
      setError
    );

    if (!isValid) return;

    try {
      const userData = { email, password, firstName, lastName };
      await authService.registerUser(userData);
      console.log("Registration successful!");
      // Optionally, you can redirect the user to a verification page or display a message
    } catch (error) {
      if (error.response && error.response.status === 409) {
        setError("User already exists. Please choose a different email.");
      } else {
        setError("Registration failed: " + error.message);
        console.error("Registration failed:", error);
      }
    }
  };

  return (
    <Card title={t("signUp")} style={{ ...LoginRegisterCardStyle }}>
      {registerPage === 0 && (
        <Form style={{ ...formStyle }} onSubmit={handleSubmit}>
          {fieldNamesPageFirst.map((fieldName, index) => (
            <TextInput
              key={index}
              type={
                fieldNamesPageFirst.includes("Password") ? "password" : "text"
              }
              placeholder={fieldName}
              value={getValueForFieldName(fieldName)}
              onChange={(e) => handleChange(e, fieldName)}
            />
          ))}
          {error && <Typography color="error">{error}</Typography>} <Divider />
          <SuccessButton onClick={handleChangePage} type="button">
            {t("next")}
          </SuccessButton>
        </Form>
      )}
      {registerPage === 1 && (
        <Form style={{ ...formStyle }} onSubmit={handleSubmit}>
          {fieldNamesPageSecond.map((fieldName, index) => (
            <TextInput
              key={index}
              type={
                fieldName === "Password" || fieldName === "Repeat Password"
                  ? "password"
                  : "text"
              }
              placeholder={fieldName}
              value={getValueForFieldName(fieldName)}
              onChange={(e) => handleChange(e, fieldName)}
            />
          ))}
          {error && <Typography color="error">{error}</Typography>} <Divider />
          <SuccessButton type="submit">{t("signUp")}</SuccessButton>
        </Form>
      )}
    </Card>
  );
};

export default RegisterForm;
