import React from "react";
import PropTypes from "prop-types"; // Import PropTypes
import * as IndexUI from "../../ui/Index";
import {
  useNotificationInfo,
  useNotificationErr,
  useNotificationSuccess,
  onLogout,
} from "../../../utils/ButtonUtils";
import { useModalContext } from "../../layout/context/ModalContext";

const Dashboard = ({ t }) => {
  const handleNotificationInfo = useNotificationInfo("Info");
  const handleNotificationErr = useNotificationErr("Error");
  const handleNotificationSuccess = useNotificationSuccess("Error");
  const { changeModalContext } = useModalContext();

  const openModal = (context) => {
    console.log("open modal");
    changeModalContext("changeCompBaseData");
  };
  return (
    <IndexUI.Box>
      <div>{t(`dashboard`)}</div>
      <IndexUI.Button onClick={onLogout} variant={"danger"}>
        <IndexUI.Typography>{t(`logout`)}</IndexUI.Typography>
      </IndexUI.Button>
      <IndexUI.Button onClick={handleNotificationSuccess} variant={"success"}>
        <IndexUI.Typography>{t(`GetSuccess`)}</IndexUI.Typography>
      </IndexUI.Button>
      <IndexUI.Button onClick={handleNotificationInfo} variant={"warning"}>
        <IndexUI.Typography>{t(`GetInfo`)}</IndexUI.Typography>
      </IndexUI.Button>
      <IndexUI.Button onClick={handleNotificationErr} variant={"danger"}>
        <IndexUI.Typography>{t(`GetError`)}</IndexUI.Typography>
      </IndexUI.Button>
      <IndexUI.Button onClick={openModal} variant={"danger"}>
        <IndexUI.Typography>{t(`Open Edit Stammdaten`)}</IndexUI.Typography>
      </IndexUI.Button>
    </IndexUI.Box>
  );
};

// Define PropTypes for Dashboard component
Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
};

export default Dashboard;
