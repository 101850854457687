import React, { useEffect } from "react";
import { UserProvider } from "../../utils/api/provider/UserProvider";
import { useTheme } from "../provider/ThemeProvider";
import styled, { ThemeProvider } from "styled-components";
import { useTranslation } from "react-i18next";
import {
  NotificationProvider,
  useNotification,
} from "../../context/NotificationContext";
import { LayoutStyles } from "./context/LayoutStyles";
import { Box } from "../ui/Index";
import { SideBar, NavBar, Overlay, GlobalModal } from "./Index";
import { ModalProvider } from "./context/ModalContext";
import { defaultTransitions } from "../../utils/const";

const AppShell = ({ isOutside, children, isMobile }) => {
  const { theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          backgroundColor: theme.colors.background,
          height: "100vh",
          overflow: "auto",
          transition: defaultTransitions(theme),
        }}
      >
        {children}
      </div>
    </ThemeProvider>
  );
};

export default AppShell;
