import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "./components/provider/ThemeProvider";
import routes from "./components/routes/routes";

// eslint-disable-next-line
import i18n from "./i18n"; //NOSONAR
import {
  UserProvider,
  useComp,
  useUser,
} from "./utils/api/provider/UserProvider";
function App() {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const appRoutes = routes(isMobile);

  return (
    <Router>
      <ThemeProvider>
        <UserProvider>
          <Routes>
            {appRoutes.map((route) => (
              <Route key={route.path} path={route.path} element={route.element}>
                {route.children?.map((childRoute) => (
                  <Route
                    key={`${route.path}-${childRoute.path}`}
                    path={childRoute.path}
                    element={childRoute.element}
                  />
                ))}
              </Route>
            ))}
          </Routes>
        </UserProvider>
      </ThemeProvider>
    </Router>
  );
}

export default App;
