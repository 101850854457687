import React from "react";
import { useTheme } from "../provider/ThemeProvider";
import { useTranslation } from "react-i18next";
import { NotificationProvider } from "../../context/NotificationContext";
import { LayoutStyles } from "./context/LayoutStyles";
import { Box } from "../ui/Index";
import { SideBar, NavBar, Overlay, GlobalModal } from "./Index";
import { ModalProvider } from "./context/ModalContext";
import { defaultTransitions } from "../../utils/const";
import { ThemeProvider } from "styled-components";
import {
  useUser,
  useComp,
  useLoading,
} from "../../utils/api/provider/UserProvider";
import { SidebarProvider } from "./context/SidebarContext";
import { TabProvider } from "./context/TabContext";
import ContextTab from "../widgets/ContextTab";
import styled from "styled-components";
import { ContactProvider } from "./context/ContactContext";
import { DocsProvider } from "./context/DocsContext";

const AppContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.background};
  height: 100vh;
  overflow: auto;
  transition: ${({ theme }) => defaultTransitions(theme)};
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  display: flex;
  flex: 1; /* Ensure it takes up remaining space */
`;

const AppShell = ({ children, isMobile }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { contentBoxStyle } = LayoutStyles(theme, isMobile);
  const userData = useUser();
  const compData = useComp();
  const loading = useLoading();

  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <div
          style={{
            backgroundColor: theme.colors.background,
            height: "100vh",
            overflow: "auto",
            transition: defaultTransitions(theme),
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Loading...
        </div>
      </ThemeProvider>
    );
  }

  const mapChildren = React.Children.map(children, (child) =>
    React.cloneElement(child, { t, theme, isMobile, userData, compData })
  );

  return (
    <ThemeProvider theme={theme}>
      <AppContainer theme={theme}>
        <NotificationProvider>
          <ModalProvider>
            <SidebarProvider>
              <TabProvider>
                <NavBar
                  theme={theme}
                  t={t}
                  isMobile={isMobile}
                  userData={userData}
                  compData={compData}
                />
                <ContextTab />
                <ContactProvider>
                  <DocsProvider>
                    <ContentContainer>
                      <SideBar
                        theme={theme}
                        t={t}
                        isMobile={isMobile}
                        userData={userData}
                        compData={compData}
                      />
                      <Box style={{ ...contentBoxStyle }}>{mapChildren}</Box>
                    </ContentContainer>
                  </DocsProvider>
                </ContactProvider>

                <Overlay theme={theme} userData={userData} />
                <GlobalModal
                  t={t}
                  theme={theme}
                  isMobile={isMobile}
                  userData={userData}
                  compData={compData}
                />
              </TabProvider>
            </SidebarProvider>
          </ModalProvider>
        </NotificationProvider>
      </AppContainer>
    </ThemeProvider>
  );
};

export default AppShell;
