// animations.js

export const fadeAnimation = (pageIndex, fadeOut, animating, currentPage) => {
  let opacityValue;
  let heightValue;
  if (fadeOut && animating) {
    opacityValue = 0;
  } else if (!fadeOut && animating) {
    opacityValue = pageIndex === currentPage ? 1 : 0;
  } else {
    opacityValue = 1;
  }

  return {
    opacity: opacityValue,
    transition: "opacity 0.5s ease-in-out",
  };
};

export const slideAnimation = (pageIndex, fadeOut, animating, currentPage) => {
  const distance = pageIndex * 100; // Adjust the distance as needed
  let opacityValue =
    fadeOut && animating ? 0 : pageIndex === currentPage ? 1 : 0;
  return {
    opacity: opacityValue,
    transform: `translateX(-${distance}%)`,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };
};

export const zoomAnimation = (pageIndex, fadeOut, animating, currentPage) => {
  const scaleValue = pageIndex === currentPage ? 1 : 0.8; // Adjust the scale factor as needed
  let opacityValue =
    fadeOut && animating ? 0 : pageIndex === currentPage ? 1 : 0;
  return {
    opacity: opacityValue,
    transform: `scale(${scaleValue})`,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };
};
