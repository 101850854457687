import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import Box from "./Box";
import Button from "./Button";
import { CloseButton } from "../../styles/ButtonStyles";

const Modal = ({
  isOpen,
  onClose,
  children,
  theme,
  isMobile,
  isCloseButton,
}) => {
  const { modalContentStyle, modalBackdrop, modalContentOpenStyle } =
    LayoutStyles(theme, isMobile);
  const [isVisible, setIsVisible] = useState(isOpen);

  useEffect(() => {
    setIsVisible(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setIsVisible(false);

    onClose();
  };

  return (
    <Box
      style={{
        ...modalBackdrop,
        opacity: isVisible ? 1 : 0,
        visibility: isVisible ? "visible" : "hidden",
      }}
    >
      <Box
        style={{
          ...modalContentStyle,
          ...(isVisible && isOpen && modalContentOpenStyle),
          transform: `translate(-50%, ${isVisible ? "-50%" : "-150%"}) scale(${
            isVisible ? 1 : 0.8
          })`,
        }}
      >
        {isCloseButton && <CloseButton onClick={handleClose}>X</CloseButton>}
        {children}
      </Box>
    </Box>
  );
};

export default Modal;
