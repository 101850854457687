import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const DatePickerContainer = styled.div`
  display: inline-block;
`;

const DatePickerInput = styled.input`
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
`;

// DatePicker Component
const DatePicker = () => {
  const [selectedDate, setSelectedDate] = useState("");

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  return (
    <DatePickerContainer>
      <DatePickerInput
        type="date"
        value={selectedDate}
        onChange={handleDateChange}
      />
    </DatePickerContainer>
  );
};

export default DatePicker;
