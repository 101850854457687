import React, { useState } from "react";
import styled from "styled-components";
import * as IndexUI from "../../ui/Index";
import { onLogout } from "../../../utils/ButtonUtils";

// Styled Components
const AvatarContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const AvatarImage = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  cursor: pointer;
`;

const ContextMenu = styled.div`
  position: absolute;
  top: ${({ size }) => size};
  left: 0;
  background-color: #f9f9f9;
  min-width: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #ddd;
  }
`;

// Avatar Component
const Avatar = ({ t, src, name, size }) => {
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const toggleContextMenu = () => {
    setIsContextMenuOpen(!isContextMenuOpen);
  };

  const handleMenuItemClick = (option) => {
    console.log("Option selected:", option);
    setIsContextMenuOpen(false); // Close the context menu after selecting an item
  };

  return (
    <AvatarContainer>
      <AvatarImage
        onClick={toggleContextMenu}
        src={src}
        alt="Avatar"
        size={size}
      />
      {isContextMenuOpen && (
        <ContextMenu size={size}>
          <MenuItem onClick={() => handleMenuItemClick("Profile")}>
            Profile
          </MenuItem>
          <MenuItem onClick={() => handleMenuItemClick("Logout")}>
            <IndexUI.Typography onClick={onLogout}>
              {t(`logout`)}
            </IndexUI.Typography>
          </MenuItem>
          {/* Add more menu items as needed */}
        </ContextMenu>
      )}
    </AvatarContainer>
  );
};

export default Avatar;
