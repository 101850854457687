import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";

const TabContext = createContext();

export const useTabs = () => useContext(TabContext);

export const TabProvider = ({ children }) => {
  const savedTabs = Cookies.get("tabs");
  const [tabs, setTabs] = useState(savedTabs ? JSON.parse(savedTabs) : []);

  useEffect(() => {
    Cookies.set("tabs", JSON.stringify(tabs));
    console.log("tabs", tabs);
  }, [tabs]);

  const addTab = (tab) => {
    const isDuplicate = tabs.some((existingTab) => existingTab.id === tab.id);

    if (!isDuplicate) {
      setTabs((prevTabs) => [...prevTabs, tab]);
    } else {
      console.warn(`Tab with id ${tab.id} already exists.`);
    }
  };

  const removeTab = (id) => {
    setTabs((prevTabs) => prevTabs.filter((tab) => tab.id !== id));
  };

  const removeAllTabs = () => {
    setTabs([]);
  };

  return (
    <TabContext.Provider value={{ tabs, addTab, removeTab, removeAllTabs }}>
      {children}
    </TabContext.Provider>
  );
};
