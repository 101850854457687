import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
  max-width: 200px; /* Set a max-width for the dropdown */
`;

const DropdownButton = styled.div`
  padding: 8px;
  background-color: #333; /* Dark background color for the button */
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 14px; /* Smaller font size */
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  min-width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  max-height: 150px; /* Smaller max-height */
  overflow-y: auto;
  transition: all 0.3s ease-in-out;

  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 6px; /* Thin scrollbar width */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #f1f1f1; /* Color of the scrollbar thumb */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #fff; /* Color of the scrollbar track */
  }
`;

export const DropdownItem = styled.div`
  padding: 8px;
  cursor: pointer;
  background-color: #f9f9f9; /* Lighter background color */
  font-size: 14px; /* Smaller font size */
  &:hover {
    background-color: #ddd;
  }
`;

const Arrow = styled.span`
  border: solid #fff; /* White arrow color */
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: ${({ isOpen }) => (isOpen ? "rotate(225deg)" : "rotate(45deg)")};
  transition: transform 0.2s;
  margin-left: 10px;
`;

const Dropdown = ({ title, options = [], value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option) => {
    onChange(option.value);
    setIsOpen(false);
  };

  const selectedOption = options.find((option) => option.value === value);

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>
        {selectedOption ? selectedOption.label : title}
        <Arrow isOpen={isOpen} />
      </DropdownButton>
      <DropdownMenu isOpen={isOpen}>
        {options.map((option, index) => (
          <DropdownItem key={index} onClick={() => handleSelect(option)}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </DropdownContainer>
  );
};

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.any.isRequired,
    })
  ),
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default Dropdown;
