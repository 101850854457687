import React from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FaEdit, FaEnvelope, FaPrint, FaTrash } from "react-icons/fa";
import { Icon, Tooltip } from "../ui/Index";

const ToolbarContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ToolbarItem = styled.div`
  margin-right: 10px;
  cursor: pointer;
`;

const Toolbar = ({ actions, deleteConfirmation, setDelete }) => {
  const handleClick = (index) => {
    const action = actions[index];
    if (action && action.onClick) {
      action.onClick();
    }
  };

  return (
    <ToolbarContainer>
      <IconContext.Provider value={{ size: "20px", color: "black" }}>
        {actions.map((action, index) => (
          <Tooltip key={index} text={action.label}>
            <ToolbarItem onClick={() => handleClick(index)}>
              {deleteConfirmation && action.icon === "delete" ? (
                <span>DELETE?</span> // Handle delete confirmation if needed
              ) : (
                getIcon(action.icon)
              )}
              {action.child}
            </ToolbarItem>
          </Tooltip>
        ))}
      </IconContext.Provider>
    </ToolbarContainer>
  );
};

const getIcon = (iconName) => {
  switch (iconName) {
    case "edit":
      return (
        <Icon>
          <FaEdit />
        </Icon>
      );
    case "email":
      return (
        <Icon>
          <FaEnvelope />
        </Icon>
      );
    case "print":
      return (
        <Icon>
          <FaPrint />
        </Icon>
      );
    case "delete":
      return (
        <Icon>
          <FaTrash />
        </Icon>
      );
    default:
      return null;
  }
};

export default Toolbar;
