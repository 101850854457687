import React, { useState } from "react";
import * as IndexUI from "../ui/Index";
import { LayoutStyles } from "./context/LayoutStyles";
import { dimsDesktop, dimsMobile } from "../../constants/Dimensions";
import { useSidebarContext } from "./context/SidebarContext";
import { useModalContext } from "./context/ModalContext";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { SubMenuButton } from "../styles/ButtonStyles";
import { useContactContext } from "./context/ContactContext";
import { contactContexts, docsContexts } from "../../utils/const";
import { useDocsContext } from "./context/DocsContext";

// Styled components
const SubMenu = styled.div`
  overflow: hidden;
  max-height: ${({ isOpen }) => (isOpen ? "500px" : "0")};
  transition: max-height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  transform: ${({ isOpen }) =>
    isOpen ? "translateY(0)" : "translateY(-20px)"};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  position: relative;
  width: 100%;
  z-index: 1;
  color: ${({ theme }) => theme.colors.textPrimary};
  background: ${({ theme }) => theme.colors.surface};
  transition: color 0.3s ease, background 0.3s ease;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const SidebarContent = styled(IndexUI.Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease, padding 0.3s ease;
  transform: ${({ isCollapsed }) =>
    isCollapsed ? "translateX(-100%)" : "translateX(0)"};
  padding: ${({ isCollapsed }) => (isCollapsed ? "0" : "10px")};
  overflow: hidden;
  position: relative;
`;

const CollapseButton = styled.button`
  background: ${({ theme }) => theme.colors.primary};
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  align-self: flex-end;
  margin: 10px;
  &:hover {
    background: ${({ theme }) => theme.colors.secondary};
  }
`;

const SectionTitle = styled.h4`
  margin: 10px 0;
  padding: 0 10px;
  font-size: 1.1em;
  color: ${({ theme }) => theme.colors.textPrimary});
`;

const SectionContainer = styled.div`
  margin-bottom: 20px;
`;

const SubMenuSection = styled.div`
  margin-bottom: 15px;
`;
//TODO: Andere Hintergrundfarbe wenn in Kontext
const Sidebar = ({ t, isMobile, theme, width, height }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { sidebarStyles } = LayoutStyles(theme, isMobile);
  const dims = isMobile ? dimsMobile : dimsDesktop;
  const { context } = useSidebarContext();
  const { changeModalContext } = useModalContext();
  const { changeContactContext } = useContactContext();
  const { changeDocsContext } = useDocsContext();

  const navigate = useNavigate();

  const handleSubMenuClick = (menuName, to) => {
    console.log(`Clicked in ${menuName} to ${to}`);
    if (menuName === "newDoc") {
      changeModalContext("newDoc");
    } else if (menuName === "newCustomer") {
      changeModalContext("newCustomer");
    } else {
      navigate(to);
    }
  };

  const subMenu = {
    documents: {
      sections: [
        {
          title: "Documents",
          items: [
            {
              labelKey: "submenu.newDocument",
              onClick: () => changeDocsContext(docsContexts.DOCS_CREATE_NEW),
            },
            {
              labelKey: "submenu.allBills",
              onClick: () => changeDocsContext(docsContexts.DOCS_LIST_ALL),
            },
            {
              labelKey: "submenu.offers",
              onClick: () => handleSubMenuClick("offers", "/docs/offers"),
            },
          ],
        },
        {
          title: "Settings",
          items: [
            {
              labelKey: "submenu.positions",
              onClick: () => changeDocsContext(docsContexts.DOCS_POSITIONS),
            },
            {
              labelKey: "submenu.templates",
              onClick: () => changeDocsContext(docsContexts.DOCS_TEMPLATES),
            },
          ],
        },
      ],
    },
    customer: {
      sections: [
        {
          title: "",
          items: [
            {
              labelKey: "submenu.newCustomer",
              onClick: () =>
                changeContactContext(contactContexts.CUSTOMER_CREATE_NEW),
            },
          ],
        },
        {
          title: "Customer",
          items: [
            {
              labelKey: "submenu.customerListAll",
              onClick: () =>
                changeContactContext(contactContexts.CUSTOMER_LIST_ALL),
            },
            {
              labelKey: "submenu.customerListPriv",
              onClick: () =>
                changeContactContext(contactContexts.CUSTOMER_LIST_PRIV),
            },
            {
              labelKey: "submenu.customerListPrivNoMember",
              onClick: () =>
                changeContactContext(
                  contactContexts.CUSTOMER_LIST_PRIV_NO_MEMBER
                ),
            },
            {
              labelKey: "submenu.customerListComp",
              onClick: () =>
                changeContactContext(contactContexts.CUSTOMER_LIST_COMP),
            },
          ],
        },
      ],
    },
    settings: {
      sections: [
        {
          title: "Settings",
          items: [
            {
              labelKey: "submenu.ownCompany",
              onClick: () => handleSubMenuClick("settings", "/settings"),
            },
            {
              labelKey: "submenu.customFields",
              onClick: () => handleSubMenuClick("customFields", "/cfields"),
            },
            {
              labelKey: "submenu.memberstates",
              onClick: () =>
                handleSubMenuClick("cMemberstates", "/cmemberstates"),
            },
          ],
        },
        {
          title: "submenu.invoice",
          items: [
            {
              labelKey: "submenu.invoice",
              onClick: () =>
                handleSubMenuClick("invoiceNumberSettings", "/invoicenr"),
            },
          ],
        },
      ],
    },
  };

  return (
    <SidebarContainer
      style={{ ...sidebarStyles, width: isCollapsed ? "50px" : "250px" }}
    >
      <CollapseButton
        theme={theme}
        onClick={() => setIsCollapsed(!isCollapsed)}
      >
        {isCollapsed ? ">" : "<"}
      </CollapseButton>

      <SidebarContent isCollapsed={isCollapsed}>
        {context === "docs" && (
          <SubMenu isOpen={!isCollapsed}>
            {subMenu.documents.sections.map((section, sectionIndex) => (
              <SectionContainer key={sectionIndex}>
                <SectionTitle>{t(section.title)}</SectionTitle>
                {section.items.map((item, itemIndex) => (
                  <SubMenuSection key={itemIndex}>
                    <SubMenuButton onClick={item.onClick}>
                      {t(item.labelKey)}
                    </SubMenuButton>
                  </SubMenuSection>
                ))}
              </SectionContainer>
            ))}
          </SubMenu>
        )}
        {context === "contacts" && (
          <SubMenu isOpen={!isCollapsed}>
            {subMenu.customer.sections.map((section, sectionIndex) => (
              <SectionContainer key={sectionIndex}>
                <SectionTitle>{t(section.title)}</SectionTitle>
                {section.items.map((item, itemIndex) => (
                  <SubMenuSection key={itemIndex}>
                    <SubMenuButton onClick={item.onClick}>
                      {t(item.labelKey)}
                    </SubMenuButton>
                  </SubMenuSection>
                ))}
              </SectionContainer>
            ))}
          </SubMenu>
        )}
        {context === "settings" && (
          <SubMenu isOpen={!isCollapsed}>
            {subMenu.settings.sections.map((section, sectionIndex) => (
              <SectionContainer key={sectionIndex}>
                <SectionTitle>{t(section.title)}</SectionTitle>
                {section.items.map((item, itemIndex) => (
                  <SubMenuSection key={itemIndex}>
                    <SubMenuButton onClick={item.onClick}>
                      {t(item.labelKey)}
                    </SubMenuButton>
                  </SubMenuSection>
                ))}
              </SectionContainer>
            ))}
          </SubMenu>
        )}
      </SidebarContent>
    </SidebarContainer>
  );
};

export default Sidebar;
