import React, { createContext, useState, useContext } from "react";

const SidebarContext = createContext();

export const SidebarProvider = ({ children }) => {
  const [context, setContext] = useState(undefined);

  const changeSidebarContext = (newContext) => {
    setContext(newContext);
  };

  return (
    <SidebarContext.Provider value={{ context, changeSidebarContext }}>
      {children}
    </SidebarContext.Provider>
  );
};

// Custom hook for using the Sidebar context
export const useSidebarContext = () => useContext(SidebarContext);
