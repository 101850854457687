export const isDev = false;

export const linkConfig = {
  apiUrl: isDev ? "http://localhost:3001" : "https://billbuddy.soldyn.de",
  baseUrl: isDev
    ? "http://localhost:3000/"
    : "https://rechnung.solutiondynamic.de/",
  apiSuffix: "/api",
  isDev: isDev,
  apiCustomer: "customer-data",
  apiContactCompany: "company",
};

export default linkConfig;
