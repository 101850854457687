import React, { useEffect, useState } from "react";
import axios from "axios";
import { linkConfig } from "../../../config";
const EmailVerification = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState(
    "Verifying your email..."
  );

  useEffect(() => {
    console.log("useEffect triggered");

    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");

    console.log("Token extracted from query string:", token);

    if (!token) {
      console.log("Token not found in the URL");
      setVerificationMessage("Token not found in the URL");
      return;
    }
    verifyEmail(token);
  }, []);
  const verifyEmail = async (token) => {
    console.log("Sending Token:", token);

    try {
      const response = await axios.get(
        `${linkConfig.apiUrl}${linkConfig.apiSuffix}/verify-email?token=${token}`
      );

      console.log("Verification response:", response.data);

      setVerificationMessage(response.data.message);

      // Redirect to another site upon successful verification
      if (response.data.message === "Email verified successfully") {
        setIsVerified(true);
        window.location.href = "/auth";
      }
    } catch (error) {
      console.error("Error verifying email:", error.message);
      setVerificationMessage("An error occurred while verifying your email.");
    }
  };
  console.log("Component re-rendered");

  return (
    <div>
      <h1>Email Verification</h1>
      {!isVerified && <p>{verificationMessage}</p>}
    </div>
  );
};

export default EmailVerification;
