import React, { useState, useEffect } from "react";
import { useAnimation } from "../provider/AnimationProvider";
import { ColumnBox, RowBox } from "../styles/BoxStyles";
import { PagerButton } from "../styles/ButtonStyles";

const StaticPager = ({
  children,
  currentPage,
  onPageChange,
  buttonNames,
  showFirstButton,
  showAnyButton = true,
}) => {
  const { getModalAnimationStyles } = useAnimation();
  const [animating, setAnimating] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    console.log("currentPage changed:", currentPage);
    const timeout = setTimeout(() => {
      setAnimating(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, [currentPage]);

  const handlePageChange = (pageIndex) => {
    if (buttonNames[pageIndex].disabled) return; // Don't change page if button is disabled
    setAnimating(true);
    setFadeOut(true);
    setTimeout(() => {
      onPageChange(pageIndex);
      setFadeOut(false);
    }, 500);
  };

  return (
    <ColumnBox style={{ width: "100%" }}>
      {showAnyButton && (
        <ColumnBox
          style={{
            display: "flex",
            width: "100%",
            gap: "12px",
            justifyContent: "center",
            opacity: fadeOut ? 0 : 1,
            transition: "opacity 0.5s ease",
          }}
        >
          {React.Children.map(children, (child, index) =>
            index === 0 && !showFirstButton ? null : (
              <PagerButton
                key={index}
                onClick={() => handlePageChange(index)}
                disabled={buttonNames[index].disabled} // Set button disabled status
              >
                {buttonNames[index].name}
              </PagerButton>
            )
          )}
        </ColumnBox>
      )}

      {React.Children.map(children, (child, index) => {
        return (
          <ColumnBox
            key={index}
            style={getModalAnimationStyles(
              index,
              fadeOut,
              animating,
              currentPage
            )}
          >
            {index === currentPage ? child : null}
          </ColumnBox>
        );
      })}
    </ColumnBox>
  );
};

export default StaticPager;
