import React, { useState } from "react";
import { Box, Divider, Typography, TextInput } from "../../ui/Index";
import { RowBox } from "../../styles/BoxStyles";

const SearchField = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    onSearch(query);
  };

  return (
    <Box style={{}}>
      <RowBox
        style={{ justifyContent: "space-between", alignItems: "flex-start" }}
      >
        <TextInput
          placeholder={"Search"}
          value={searchQuery}
          onChange={handleSearchChange}
        />
      </RowBox>
    </Box>
  );
};

export default SearchField;
