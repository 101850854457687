// routes.js
import * as LayoutIndex from "../layout/Index";
import * as DevIndex from "../development/Index";
import * as PageIndex from "../pages/Index";
import PrivateRoute from "./PrivateRoute";

const routes = (isMobile) => [
  {
    path: "/",
    element: <PrivateRoute />,
    children: [
      {
        path: "/",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.Home />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/docs",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.Docs element={"bill"} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/docs/:docKey",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.DocsDetails />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/docs/offers",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.Docs element={"offer"} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/docs/bills",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.Docs element={"bill"} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/contact",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.Customer />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/contact/:customerKey",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.CustomerDetails />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/contact/comp/:customerKey",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.CustomerDetails isCompany={true} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/components",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <DevIndex.ComponentList />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/cfields",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.AddCustomInput element={"customer"} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/cmemberstates",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.MemberStates element={"customer"} />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/invoicenr",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.InvoiceNumberSettings />
          </LayoutIndex.AppShell>
        ),
      },
      {
        path: "/settings",
        element: (
          <LayoutIndex.AppShell isMobile={isMobile}>
            <PageIndex.SettingsDash element={"customer"} />
          </LayoutIndex.AppShell>
        ),
      },
    ],
  },
  {
    path: "/register",
    element: (
      <LayoutIndex.OutsideShell isMobile={isMobile}>
        <PageIndex.LoginRegister />
      </LayoutIndex.OutsideShell>
    ),
  },
  {
    path: "/auth",
    element: (
      <LayoutIndex.OutsideShell isMobile={isMobile}>
        <PageIndex.LoginRegister />
      </LayoutIndex.OutsideShell>
    ),
  },
  {
    path: "/verify-email",
    element: <PageIndex.EmailVerification />,
  },
];

export default routes;
