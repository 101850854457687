import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Typography from "./Typography";
import Box from "./Box"; // Assuming Box component handles layout with styled-components
import { NumericFormat } from "react-number-format"; // Import the NumericFormat component

const InputContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Input = styled.input`
  padding: 6px;
  font-size: 12px;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  margin-bottom: 4px;
  background-color: ${(props) => (props.hasChanged ? "#e0f7fa" : "#fff")};
  padding-left: ${(props) => (props.hasPrefix ? "30px" : "6px")};
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => (props.showPassword ? "green" : "red")};
  cursor: pointer;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 4px;
`;

const Prefix = styled.span`
  position: absolute;
  left: 10px;
  font-size: 12px;
  color: #888;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 4px;
`;

const TextInput = ({
  title,
  value: initialValue,
  onChange,
  type = "text",
  placeholder,
  error,
  validation,
  disabled,
  isHorizontal = false,
  currency = "EUR", // Default currency set to EUR
}) => {
  const [value, setValue] = useState(initialValue);
  const [hasChanged, setHasChanged] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  const handleChange = (values) => {
    let newValue = values.value;
    if (typeof newValue === "string") {
      newValue = newValue.replace(",", ".");
    }
    setValue(newValue);
    setHasChanged(true);

    if (onChange) {
      onChange({ target: { value: newValue } });
    }
  };

  const handleKeyDown = (e) => {
    if (type === "currency" && e.key === ",") {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const inputValue = e.target.value;
      const newValue =
        inputValue.substring(0, cursorPosition) +
        "." +
        inputValue.substring(cursorPosition);
      setValue(newValue);
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.setSelectionRange(
            cursorPosition + 1,
            cursorPosition + 1
          );
        }
      }, 0);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: isHorizontal ? "row" : "column",
      }}
    >
      {title && type !== "info" && (
        <TitleContainer>
          <Typography style={{ minWidth: "100px" }} variant={"span"}>
            {title}
          </Typography>
        </TitleContainer>
      )}
      {type === "info" && <Typography variant={"p"}>{value}</Typography>}
      {type !== "info" && (
        <InputContainer>
          {type === "currency" && (
            <Prefix>{currency === "USD" ? "$" : "€"}</Prefix>
          )}
          {type === "currency" ? (
            <NumericFormat
              ref={inputRef}
              value={value}
              displayType={"input"}
              thousandSeparator={true}
              prefix={currency === "USD" ? "$" : "€"}
              decimalScale={2}
              fixedDecimalScale
              onValueChange={handleChange}
              onKeyDown={handleKeyDown}
              disabled={disabled}
              style={{
                width: "100%",
                padding: "6px",
                fontSize: "12px",
                border: "1px solid #ccc",
                borderRadius: "4px",
                outline: "none",
                marginBottom: "4px",
                backgroundColor: hasChanged ? "#e0f7fa" : "#fff",
                paddingLeft: currency ? "30px" : "6px",
              }}
            />
          ) : (
            <Input
              ref={inputRef}
              type={type === "password" && showPassword ? "text" : type}
              placeholder={placeholder}
              value={value}
              onChange={(e) => handleChange({ value: e.target.value })}
              onKeyDown={handleKeyDown}
              disabled={disabled}
            />
          )}
          {type === "password" && (
            <Placeholder
              showPassword={showPassword}
              onClick={togglePasswordVisibility}
            >
              {
                //showPassword ? "🔓" : "🔒"
              }
            </Placeholder>
          )}
        </InputContainer>
      )}

      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Box>
  );
};

export default TextInput;
