// Pager.js
import React, { useState, useEffect } from "react";
import { useAnimation } from "../provider/AnimationProvider";

const Pager = ({
  children,
  onNextPage,
  onPrevPage,
  disabledNext,
  showButtons = true,
}) => {
  const { getModalAnimationStyles } = useAnimation();
  const [currentPage, setCurrentPage] = useState(0);
  const [animating, setAnimating] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  const nextPage = () => {
    setAnimating(true);
    setFadeOut(true);
    setTimeout(() => {
      setCurrentPage(currentPage + 1);
      setFadeOut(false);
      if (onNextPage) {
        onNextPage();
      }
    }, 500);
  };

  const prevPage = () => {
    setFadeOut(true);
    setTimeout(() => {
      setCurrentPage(currentPage - 1);
      setFadeOut(false);
      if (onPrevPage) {
        onPrevPage();
      }
    }, 500);
  };

  const pageCount = React.Children.count(children);
  const hasNextPage = currentPage < pageCount - 1;
  const hasPrevPage = currentPage > 0;

  useEffect(() => {
    console.log("currentPage changed:", currentPage);
    const timeout = setTimeout(() => {
      setAnimating(true);
    }, 10);
    return () => clearTimeout(timeout);
  }, [currentPage]);

  return (
    <div>
      {React.Children.map(children, (child, index) => {
        return (
          <div
            key={index}
            style={getModalAnimationStyles(
              index,
              fadeOut,
              animating,
              currentPage
            )}
          >
            {index === currentPage ? child : null}
          </div>
        );
      })}
      <div>
        {hasPrevPage && showButtons && (
          <button onClick={prevPage}>Previous</button>
        )}
        {hasNextPage && showButtons && (
          <button disabled={disabledNext} onClick={nextPage}>
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default Pager;
