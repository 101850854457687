import React from "react";
import { DefaultButton } from "../../styles/ButtonStyles";
const Button = ({ children, style = {}, onClick, disabled, type }) => {
  // console.log("Disabled:", disabled);
  return (
    <DefaultButton
      onClick={onClick}
      disabled={disabled}
      style={style}
      type={type}
    >
      {children}
    </DefaultButton>
  );
};

export default Button;
