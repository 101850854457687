// Reload manually if Change

export const dimsDesktop = {
  NavBarHeight: "45px",
  AvatarSize: "35px",
  LogoSize: "35px",
  sidebarWidth: "255px",
  sidebarHeight: "100%",
};

export const dimsMobile = {
  NavBarHeight: "45px",
  AvatarSize: "35px",
  LogoSize: "35px",
  sidebarWidth: "255px",
  sidebarHeight: "100%",
};
