import React, { useState, useCallback, useEffect } from "react";
import { useDebounce } from "use-debounce";
import { TextInput, Button, Box, Typography, Divider } from "../../ui/Index";
import { RowBox } from "../../styles/BoxStyles";
import {
  addNewDoc,
  fetchDocsPositions,
  fetchInvoiceNumbers,
  updateInvoiceNumber,
} from "../../../services/docsService";
import TextInputSection from "./TextInputSection";
import CurrencyInput from "react-currency-input-field";
import { useModalContext } from "../../layout/context/ModalContext";
import SearchAndSelectModal from "../modals/SearchAndSelectModal";
import {
  fetchAllCompContactData,
  fetchAllPrivContactData,
  fetchCustomerByKey,
} from "../../../services/customerService";
import { currentDate } from "../../../utils/const";

const InvoiceForm = ({
  setInvoiceData,
  onDownload,
  documentRecipientInputs,
  documentSenderInputs,
  documentView,
  compData,
  theme,
}) => {
  const [formData, setFormData] = useState(
    documentRecipientInputs.reduce(
      (acc, input) => ({ ...acc, [input.key]: input.value }),
      {}
    ),
    documentSenderInputs.reduce(
      (acc, input) => ({ ...acc, [input.key]: input.value }),
      {}
    )
  );

  const [positions, setPositions] = useState([
    { name: "", price: "", amount: "" },
  ]);

  const [debouncedInvoiceData] = useDebounce({ ...formData, positions }, 500);
  const [templates, setTemplates] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState(``);
  const [contactsPriv, setContactsPriv] = useState([]);
  const [contactsComp, setContactsComp] = useState([]);
  const [showPositionTemplateSel, setShowPositionTemplateSel] = useState(false);
  const [showPrivContactSel, setShowPrivContactSel] = useState(false);
  const [showCompContactSel, setShowCompContactSel] = useState(false);
  const { changeModalContext } = useModalContext();
  const updateInvoiceData = useCallback(() => {
    setInvoiceData(debouncedInvoiceData);
  }, [debouncedInvoiceData, setInvoiceData]);

  useEffect(() => {
    updateInvoiceData();
  }, [debouncedInvoiceData, updateInvoiceData]);
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      senderName: compData?.comp_name,
      senderAddress: compData?.comp_street,
      senderpCode: compData?.comp_pcode,
      senderCity: compData?.comp_state,
      date: currentDate,
    }));

    fetchInvoiceNumber();
  }, [compData, invoiceNumber]);

  const fetchInvoiceNumber = async () => {
    try {
      const fetchData = await fetchInvoiceNumbers(compData?.comp_key);
      setInvoiceNumber(
        fetchData.prefix + fetchData.nextInvoiceNumber + fetchData.suffix
      );
      console.log("fetchData.nextInvoiceNumber", fetchData);
      setFormData((prevData) => ({
        ...prevData,
        invoiceNumber: invoiceNumber,
      }));
    } catch (err) {
      console.error("Error fetching invoice number:", err);
    }
  };

  const handleChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const handlePositionChange = (index, field, value) => {
    const newPositions = [...positions];
    newPositions[index] = { ...newPositions[index], [field]: value };
    setPositions(newPositions);
  };

  const addPosition = () => {
    setPositions([...positions, { name: "", price: "", amount: "" }]);
  };

  const removePosition = (index) => {
    const newPositions = positions.filter((_, i) => i !== index);
    setPositions(newPositions);
  };

  const fetchTemplates = async () => {
    try {
      const response = await fetchDocsPositions(compData?.comp_key);
      console.log("Fetched Templates:", response);
      setTemplates(response || []);
    } catch (error) {
      console.error("Failed to fetch templates", error);
      setTemplates([]);
    }
  };

  const fetchPrivContacts = async () => {
    console.log("formData");
    console.log(formData);
    try {
      const resPrivContacts = await fetchAllPrivContactData(compData?.comp_key);
      console.log("Fetched resPrivContacts:", resPrivContacts);

      setContactsPriv(resPrivContacts);
    } catch (error) {
      console.error("Failed to fetch contactsPriv:", error.message);
      setContactsPriv([]);
    }
  };

  const fetchCompContacts = async () => {
    console.log("formData");
    console.log(formData);
    try {
      const resCompContacts = await fetchAllCompContactData(compData?.comp_key);
      console.log("Fetched resCompContacts:", resCompContacts);

      setContactsComp(resCompContacts);
    } catch (error) {
      console.error("Failed to fetch contactsPriv:", error.message);
      setContactsComp([]);
    }
  };

  const handleContactTemplateSel = (template) => {
    console.log("Template selected:", template);

    // Example assuming you want to update the senderName field in formData
    // You need to update this according to the actual fields in your template
    const { firstName, lastName, address, pCode, city } = template;
    console.log(firstName);
    // Update formData with new senderName
    setFormData((prevData) => ({
      ...prevData,
      recipientName: `${firstName || ""} ${lastName || ""}`.trim() || "",
      recipientAddress: address,
      recipientPostCode: pCode,
      recipientCity: city,
    }));
  };

  const handlePositionsTemplateSel = (template) => {
    console.log("Template selected:", template);

    if (!template.pos_name || !template.pos_price) {
      console.error("Invalid template data:", template);
      return;
    }

    const selectedPosition = {
      name: template.pos_name,
      price: template.pos_price,
      amount: "",
    };

    setPositions((prevPositions) => [...prevPositions, selectedPosition]);

    setShowPositionTemplateSel(false);
  };

  const saveDocument = async (name, type, invoiceData) => {
    console.log("Saving document", invoiceData);
    try {
      // Fetch the current invoice number

      // Update the invoice number
      await updateInvoiceNumber(compData?.comp_key, invoiceNumber);

      // Add the invoice number to the document data
      const documentWithInvoiceNumber = {
        ...invoiceData,
        invoiceNumber: invoiceNumber,
      };

      console.log("Document with invoice number", documentWithInvoiceNumber);

      // Save the document with the updated invoice number
      await addNewDoc(
        compData?.comp_key,
        name,
        type,
        documentWithInvoiceNumber
      );
    } catch (err) {
      console.error("Failed to save document:", err);
    }
  };

  const handleSave = () => {
    saveDocument("name", "type", debouncedInvoiceData);
  };

  const handleDownload = () => {
    if (onDownload) {
      onDownload();
    }
  };

  return (
    <div>
      <Typography variant="h2">Rechnungserstellung</Typography>
      {showPrivContactSel && (
        <SearchAndSelectModal
          theme={theme}
          templates={contactsPriv}
          displayFields={["firstName", "lastName"]}
          handleTemplateSelection={handleContactTemplateSel}
          setShowTemplateSelector={setShowPrivContactSel}
        />
      )}
      {showCompContactSel && (
        <SearchAndSelectModal
          theme={theme}
          templates={contactsComp}
          displayFields={["firstName", "lastName"]}
          handleTemplateSelection={handleContactTemplateSel}
          setShowTemplateSelector={setShowCompContactSel}
        />
      )}
      <RowBox style={{ marginBottom: "20px" }}>
        <Box style={{ width: "100%" }}>
          <Divider />
          <Typography variant="h3">Vereinsdaten</Typography>
          {documentSenderInputs.map((input) => (
            <TextInput
              key={input.key}
              isHorizontal={true}
              title={input.label}
              type={input.type}
              placeholder={input.label}
              value={formData[input.key]}
              onChange={(e) => handleChange(input.key, e.target.value)}
            />
          ))}
          <Divider />
          <Button
            onClick={() => {
              fetchPrivContacts();
              setShowPrivContactSel(true);
            }}
            style={{ marginLeft: "10px", height: "25px" }}
          >
            Kontakt Suchen
          </Button>
          <Button
            onClick={() => {
              fetchCompContacts();
              setShowCompContactSel(true);
            }}
            style={{ marginLeft: "10px", height: "25px" }}
          >
            Firma Suchen
          </Button>
          {documentRecipientInputs.map((input) => (
            <TextInput
              key={input.key}
              isHorizontal={true}
              title={input.label}
              type={input.type}
              placeholder={input.label}
              value={formData[input.key]}
              onChange={(e) => handleChange(input.key, e.target.value)}
            />
          ))}
        </Box>
        {documentView}
      </RowBox>

      <Typography variant="h3">Positions</Typography>
      <Box
        style={{
          border: "1px solid #ddd",
          borderRadius: "5px",
          padding: "10px",
        }}
      >
        <div style={{ overflowX: "auto" }}>
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Name
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Price
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Amount
                </th>
                <th style={{ border: "1px solid #ddd", padding: "8px" }}>
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {positions.map((position, index) => (
                <tr key={index}>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    <TextInput
                      value={position.name}
                      onChange={(e) =>
                        handlePositionChange(index, "name", e.target.value)
                      }
                      placeholder="Name"
                    />
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    <TextInput
                      type="currency"
                      value={position.price}
                      onChange={(e) =>
                        handlePositionChange(index, "price", e.target.value)
                      }
                      placeholder="Price"
                    />
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    <TextInput
                      type="number"
                      value={position.amount}
                      onChange={(e) =>
                        handlePositionChange(index, "amount", e.target.value)
                      }
                      placeholder="Amount"
                    />
                  </td>
                  <td style={{ border: "1px solid #ddd", padding: "8px" }}>
                    <Button onClick={() => removePosition(index)}>
                      Remove
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Button onClick={addPosition} style={{ marginTop: "10px" }}>
          Add Position
        </Button>
        <Button
          onClick={() => {
            fetchTemplates();
            setShowPositionTemplateSel(true);
          }}
          style={{ marginTop: "10px" }}
        >
          Add from Templates
        </Button>
      </Box>
      <Button onClick={handleSave} style={{ marginTop: "20px" }}>
        Speichern
      </Button>
      <Button onClick={handleDownload} style={{ marginTop: "20px" }}>
        PDF herunterladen
      </Button>

      {showPositionTemplateSel && (
        <SearchAndSelectModal
          theme={theme}
          templates={templates} // The data you're selecting from
          displayFields={["pos_name", "pos_price"]} // Fields you want to display
          handleTemplateSelection={handlePositionsTemplateSel}
          setShowTemplateSelector={setShowPositionTemplateSel}
        />
      )}
    </div>
  );
};

export default InvoiceForm;
