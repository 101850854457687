import React from "react";
import styled from "styled-components";

// Styled Components
const RadioButtonContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RadioButtonInput = styled.input`
  margin-right: 5px;
`;

const RadioButtonLabel = styled.span`
  font-size: 14px;
`;

// RadioButton Component
const RadioButton = ({ label, checked, onChange }) => {
  return (
    <RadioButtonContainer>
      <RadioButtonInput type="radio" checked={checked} onChange={onChange} />
      <RadioButtonLabel>{label}</RadioButtonLabel>
    </RadioButtonContainer>
  );
};

export default RadioButton;
