import React from "react";

const Form = ({ children, style = {}, onSubmit }) => {
  return (
    <form style={style} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

export default Form;
