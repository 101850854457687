import linkConfig from "../config";
import { fetchData, sendData } from "../utils/Api";
import { getToken } from "../utils/const";
export const addNewDoc = async (compKey, name, type, doc) => {
  try {
    console.log(
      `Adding addNewDoc for compKeyyyyyyyyyyyyy: ${compKey}, doc:`,
      doc
    );

    // Fetch the current and next invoice number
    const invoiceNumbers = await fetchInvoiceNumbers(compKey);
    const newInvoiceNumber = invoiceNumbers.nextInvoiceNumber;
    console.log(`New invoice number: ${newInvoiceNumber}`);
    // Update the invoice number

    // Add the invoice number to the document
    const documentWithInvoiceNumber = {
      ...doc,
      invoiceNumber: newInvoiceNumber,
    };
    console.log(`documentWithInvoiceNumber: ${documentWithInvoiceNumber}`);

    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/docs`;

    // Format the data as an array, if required
    const combinedData = [
      { name: name, type: type, doc: documentWithInvoiceNumber },
    ];

    const response = await sendData(endpoint, combinedData, userToken, compKey);
    // await updateInvoiceNumber(compKey, newInvoiceNumber + 1);
    return response;
  } catch (error) {
    console.error("Error adding document:", error.message);
    throw error;
  }
};
// Function to fetch all documents for a user
export const fetchAllDocs = async (compKey) => {
  try {
    console.log("Fetching all documents with compKey:", compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/docs`;

    const allDocs = await fetchData(endpoint, userToken, compKey);
    return allDocs;
  } catch (error) {
    console.error("Error fetching all documents:", error.message);
    throw error;
  }
};

// Function to fetch a specific document by doc_key
export const fetchDocByKey = async (compKey, docKey) => {
  try {
    console.log(
      `Fetching document with docKey: ${docKey} and compKey: ${compKey}`
    );
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/docs/${docKey}`;

    const doc = await fetchData(endpoint, userToken, compKey);
    return doc;
  } catch (error) {
    console.error("Error fetching document by key:", error.message);
    throw error;
  }
};

export const fetchDocsPositions = async (compKey) => {
  try {
    console.log("Fetching all fetchDocsPositions data" + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/docPositions`;
    const allPositions = await fetchData(endpoint, userToken, compKey);

    return allPositions;
  } catch (error) {
    console.error("Error fetching all user data:", error.message);
    throw error;
  }
};

export const addDocsPosition = async (compKey, position) => {
  try {
    console.log(
      `Adding addDocsPosition for compKey: ${compKey}, position: ${position},`
    );
    console.log(position);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/docPositions`;

    const response = await sendData(endpoint, position, userToken, compKey);

    return response;
  } catch (error) {
    console.error("Error adding member state:", error.message);
    throw error;
  }
};

/////INVOICE//////
export const fetchInvoiceNumbers = async (compKey) => {
  try {
    console.log("Fetching invoice numbers with compKey:", compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/invoiceNumber`;

    const invoiceNumbers = await fetchData(endpoint, userToken, compKey);
    return invoiceNumbers; // Will include lastUsedInvoiceNumber, nextInvoiceNumber, prefix, suffix, startNumber, updatedAt
  } catch (error) {
    console.error("Error fetching invoice numbers:", error.message);
    throw error;
  }
};

export const updateInvoiceNumber = async (
  compKey,
  newInvoiceNumber,
  prefix,
  suffix,
  startNumber
) => {
  try {
    console.log(`Updating invoice number for compKey: ${compKey}`);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/invoiceNumber`;

    const data = { newInvoiceNumber, prefix, suffix, startNumber };

    await sendData(endpoint, data, userToken, compKey);
  } catch (error) {
    console.error("Error updating invoice number settings:", error.message);
    throw error;
  }
};

export const updateInvoiceNumberSettings = async (
  compKey,
  prefix,
  suffix,
  lastUsedInvoiceNumber
) => {
  try {
    console.log(`Updating invoice number settings for compKey: ${compKey}`);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${userId}/invoiceNumberSettings`;

    const data = { prefix, suffix, lastUsedInvoiceNumber };

    await sendData(endpoint, data, userToken, compKey);
  } catch (error) {
    console.error("Error updating invoice number settings:", error.message);
    throw error;
  }
};
