import React from "react";
import styled from "styled-components";
import AddNewDocument from "./AddNewDocument";

const OverviewBox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  margin-left: 10px;
  border: 1px solid;
  border-radius: 10px;
`;

const DocOverview = () => {
  return <OverviewBox>OVERVIEW</OverviewBox>;
};

export default DocOverview;
