const Colors = {
  light: {
    primary: "#7f8090",
    primaryText: "#292c2c",
    primaryVariant: "#3700B3",
    secondary: "##C4FCEF",
    secondaryVariant: "#018786",
    tertiary: "##C4FCEF",
    background: "#c9cdd4",
    surface: "#a7aab4",
    error: "#B00020",
    success: "green",
    warning: "#FFA726",
    info: "#2196F3",
    disabled: "grey",
    onPrimary: "#FFFFFF",
    onSecondary: "#000000",
    onBackground: "#000000",
    onSurface: "#5c5d70",
    onError: "#FFFFFF",
    onSuccess: "#FFFFFF",
    onWarning: "#FFFFFF",
    onInfo: "#FFFFFF",
    onDisabled: "#FFFFFF",
    selectionColor: "#FFA726",
  },
  dark: {
    primary: "#36364a",
    primaryText: "#d9d9d9",
    primaryVariant: "#3700B3",
    secondary: "#03DAC6",
    secondaryVariant: "#03DAC6",
    tertiary: "##C4FCEF",
    background: "#1b1f2d",
    surface: "#28303e",
    error: "#000",
    success: "green",
    warning: "#FFA726",
    info: "#2196F3",
    disabled: "grey",
    onPrimary: "#000000",
    onSecondary: "#000000",
    onBackground: "#FFFFFF",
    onSurface: "#292c3c",
    onError: "#000000",
    onSuccess: "#FFFFFF",
    onWarning: "#FFFFFF",
    onInfo: "#FFFFFF",
    onDisabled: "#FFFFFF",
    selectionColor: "##FFA726",
  },
};

const baseTheme = {
  typography: {
    fontFamily: "Arial, sans-serif",
    fontSize: "16px",
    fontWeight: "normal",
    lineHeight: "1.5",
    letterSpacing: "0.5px",
  },
  spacing: {
    large: "16px",
    medium: "12px",
    small: "4px",
  },
  radius: {
    default: "8px",
  },
  timing: {
    Themechange: "0.3s",
  },
};

const themes = {
  const: {
    ...baseTheme,
  },
  light: {
    ...baseTheme,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    colors: {
      ...Colors.light,
    },
  },
  dark: {
    ...baseTheme,
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
    borderRadius: "6px",
    colors: {
      ...Colors.dark,
    },
  },
};

export default themes;
