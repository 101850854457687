import React, { useEffect, useState } from "react";
import { fetchDocByKey } from "../../../services/docsService";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import CreateDocument from "../../widgets/pdf/CreateDocument";

const defaultLabels = {
  invoiceNumber: "Invoice Number",
  date: "Date",
  senderName: "Sender Name",
  senderAddress: "Sender Address",
  senderCity: "Sender City",
  recipientName: "Recipient Name",
  recipientAddress: "Recipient Address",
  recipientCity: "Recipient City",
  positions: "Positions",
};

const DocsDetails = ({ compData }) => {
  const [data, setData] = useState(null);
  const [documentInputs, setDocumentInputs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentPath = window.location.pathname;
        const parts = currentPath.split("/");
        const uuid = parts[parts.length - 1];
        console.log(
          "Fetching data with compKey:",
          compData?.comp_key,
          "uuid:",
          uuid
        );

        const fetchedData = await fetchDocByKey(compData?.comp_key, uuid);
        console.log("Fetched data:", fetchedData);

        // Access the first element if fetchedData.doc is an array
        const documentData = Array.isArray(fetchedData.doc)
          ? fetchedData.doc[0]
          : fetchedData.doc;

        setData({ ...fetchedData, doc: documentData });

        // Generate document inputs based on the fetched data
        const newDocumentInputs = Object.keys(documentData)
          .filter((key) => key !== "positions")
          .map((key) => {
            return {
              key: key,
              label:
                defaultLabels[key] ||
                key.charAt(0).toUpperCase() +
                  key.slice(1).replace(/([A-Z])/g, " "),
              value: documentData[key],
              type: typeof documentData[key] === "string" ? "text" : "text",
              required: true,
            };
          });

        setDocumentInputs(newDocumentInputs);
      } catch (e) {
        console.error("Error fetching data:", e.message);
      }
    };

    fetchData();
  }, [compData?.comp_key]);

  // Create a function to generate the PDF document
  const Document = () => (
    <CreateDocument invoiceData={data.doc} documentInputs={documentInputs} />
  );

  return (
    <div>
      <h1>Documentation Details</h1>
      {data ? (
        <div>
          <PDFViewer
            style={{
              width: "100%",
              height: "600px", // Adjusted height
            }}
          >
            <Document />
          </PDFViewer>
          <p>
            <strong>{defaultLabels["doc_name"] || "Document Name"}:</strong>{" "}
            {data.doc_name}
          </p>
          <p>
            <strong>{defaultLabels["type"] || "Type"}:</strong> {data.type}
          </p>
          <p>
            <strong>{defaultLabels["comp_key"] || "Comp Key"}:</strong>{" "}
            {data.comp_key}
          </p>
          <div>
            <strong>
              {defaultLabels["Document Details"] || "Document Details"}:
            </strong>
            {Object.keys(data.doc)
              .filter((key) => key !== "positions")
              .map((key) => {
                const value = data.doc[key];
                return (
                  <div key={key}>
                    <strong>
                      {defaultLabels[key] ||
                        key.charAt(0).toUpperCase() + key.slice(1)}
                      :
                    </strong>{" "}
                    {typeof value === "object"
                      ? JSON.stringify(value, null, 2)
                      : value}
                  </div>
                );
              })}
            <div>
              <strong>{defaultLabels["positions"] || "Positions"}:</strong>
              {data.doc.positions && data.doc.positions.length > 0 ? (
                <ul>
                  {data.doc.positions.map((position, index) => (
                    <li key={index}>
                      <strong>{defaultLabels["name"] || "Name"}:</strong>{" "}
                      {position.name} <br />
                      <strong>{defaultLabels["price"] || "Price"}:</strong>{" "}
                      {position.price} <br />
                      <strong>
                        {defaultLabels["amount"] || "Amount"}:
                      </strong>{" "}
                      {position.amount}
                    </li>
                  ))}
                </ul>
              ) : (
                <p>
                  {defaultLabels["No positions available."] ||
                    "No positions available."}
                </p>
              )}
            </div>
          </div>
          <PDFDownloadLink
            document={<Document />}
            fileName="document.pdf"
            style={{
              textDecoration: "none",
              color: "#ffffff",
              backgroundColor: "#007bff",
              padding: "10px 20px",
              borderRadius: "5px",
              fontWeight: "bold",
              border: "none",
              cursor: "pointer",
            }}
          >
            {({ loading }) =>
              loading ? "Preparing document..." : "Download PDF"
            }
          </PDFDownloadLink>
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
};

export default DocsDetails;
