import { useNotification } from "../context/NotificationContext";
import { logoutUser } from "../services/authService";
import { getToken } from "./const";
//TODO (UX): Add localisation support
export const useNotificationInfo = (message) => {
  const { showNotification } = useNotification();
  return () => {
    showNotification("info", message);
  };
};
export const useNotificationSuccess = (message) => {
  const { showNotification } = useNotification();
  return () => {
    showNotification("success", message);
  };
};

export const useNotificationErr = (message) => {
  const { showNotification } = useNotification();
  return () => {
    showNotification("error", message);
  };
};

export const onAvatarClick = (message) => {
  console.log("onAvatarClick Needs to be implemented");
  console.log(message);
  console.log(
    "////////////////////////////////////////////////////////////////"
  );
};

export const onLogout = () => {
  logoutUser()
    .then(() => {
      console.log("Logout successful");
      window.location.href = "/";
    })
    .catch((error) => {
      // Handle any errors that occur during logout
      console.error("Error during logout:", error.message);
    });
};

export const validateRegistration = (
  email,
  password,
  firstName,
  lastName,
  repeatPassword,
  setError
) => {
  if (!email) {
    setError("Please enter an email address.");
    return false;
  }

  if (!password) {
    setError("Please enter a password.");
    return false;
  }
  if (!firstName) {
    setError("Please enter a first name.");
    return false;
  }
  if (!lastName) {
    setError("Please enter a last name.");
    return false;
  }
  if (password !== repeatPassword) {
    setError("Passwords do not match");
    return false;
  }
  return true;
};

export const validateLogin = (email, password, setError) => {
  if (!email) {
    setError("Please enter a email.");
    return false;
  }
  if (!password) {
    setError("Please enter a password.");
    return false;
  }
  return true;
};
