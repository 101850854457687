import { checkAuthentication } from "../services/authService";
import { getToken } from "./const";

const checkAuth = async () => {
  try {
    const token = getToken();
    console.log("get checkAuth token: " + token);
    if (!token) {
      return false;
    }
    return await checkAuthentication(token);
  } catch (error) {
    console.error("Error checking authentication:", error.message);
    return false;
  }
};

export default checkAuth;
