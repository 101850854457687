import React, { useState, useEffect } from "react";
import { Box, Divider, Typography } from "../../ui/Index";
import { RowBox } from "../../styles/BoxStyles";
import SearchField from "../../ui/components/SearchField";
import CinputsList from "../../widgets/lists/CinputsList";
import { AddButton } from "../../styles/ButtonStyles";
import { useModalContext } from "../../layout/context/ModalContext";
import {
  deleteCustomField,
  fetchAllcInputData,
} from "../../../services/customFieldService";
import handleSearch from "../../../utils/searchUtils"; // Import handleSearch function

const AddCustomInput = ({ t, element, compData, userData }) => {
  const { changeModalContext } = useModalContext();
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]); // State to hold all items
  const [filteredItems, setFilteredItems] = useState([]);
  const [activeItem, setActiveItem] = useState();

  // Function to fetch all custom input data
  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await fetchAllcInputData(compData?.comp_key);
      if (data) {
        setItems(data);
        setFilteredItems(data);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [compData]);

  // Function to refetch data after deleting an item
  const reloadData = async () => {
    await fetchData();
  };

  const handleSearchWrapper = (query) => {
    handleSearch(items, query, setFilteredItems);
  };

  const handleItemClick = (index) => {
    console.log("Item clicked:", filteredItems[index].cinput_id);
    setActiveItem(filteredItems[index].cinput_id);
  };

  const onAddcInput = () => {
    console.log("onAddcInput");
    changeModalContext("AddcInput");
  };

  return (
    <Box style={{}}>
      <Typography variant={"h3"}>Add Custom Field</Typography>
      <Divider />
      <RowBox style={{ justifyContent: "space-between" }}>
        <CinputsList
          items={filteredItems}
          onItemClick={handleItemClick}
          compData={compData}
          reloadData={reloadData}
        />
      </RowBox>
      <Divider />
    </Box>
  );
};

export default AddCustomInput;
