import React, { useState } from "react";
import { Box } from "../../ui/Index";
import {
  addNewcInput,
  deleteCustomField,
} from "../../../services/customFieldService";
import Table from "../../ui/components/Table";
import { useModalContext } from "../../layout/context/ModalContext";

const CinPutList = ({ items, compData, reloadData }) => {
  const [loading, setLoading] = useState(false);
  const { reloadModalPage } = useModalContext();
  const onDelete = async (itemId) => {
    setLoading(true);
    try {
      await deleteCustomField(compData.comp_key, itemId);
      if (items.length === 1) {
        window.location.reload();
      }
      if (reloadData) {
        await reloadData();
      }
    } catch (error) {
      console.error("Error deleting item:", error.message);
    }
    setLoading(false);
  };
  const onAddItem = async (item) => {
    // Add new item logic here
    console.log("onAddItem", item.Name);

    console.log(compData.comp_key);

    try {
      await addNewcInput(compData.comp_key, {
        name: item.name,
        type: "text",
        usecase: null,
        required: 0,
      });
    } catch (err) {
      console.error(`Error adding customer:`, err.message);
      throw err;
    } finally {
      reloadModalPage();
    }
  };
  const tableHeaders = [
    { key: "id", label: "ID", type: "info" },
    { key: "name", label: "Name", type: "string" },
  ];

  const data = items.map((item) => ({
    id: item.cinput_id,
    name: item.cinput_name,
  }));
  return (
    <Table
      headers={tableHeaders}
      data={data}
      onAddItem={onAddItem}
      onItemClick={(index) => console.log("Clicked item index:", index)}
      onDelete={onDelete}
      loading={loading}
    />
  );
};

export default CinPutList;
