import {
  deleteData,
  fetchData,
  sendData,
  updateData,
  updateField,
} from "../utils/Api";
import { getToken } from "../utils/const";
import { linkConfig } from "../config";

// Function to fetch all user data

export const fetchAllcInputData = async (compKey) => {
  try {
    console.log("Fetching all cInput data for compKey: " + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/cinputs`; // Adjust the endpoint according to your backend API
    const allcInputData = await fetchData(endpoint, userToken, compKey);

    return allcInputData;
  } catch (error) {
    console.error("Error fetching all cInput data:", error.message);
    throw error;
  }
};

export const addNewcInput = async (compKey, inputData) => {
  console.log("Fetching all addNewcInput data" + inputData);
  const userToken = getToken();
  if (!userToken) {
    throw new Error("User token not found");
  }
  try {
    // Modify the sendData call to include the inputData as part of the request body
    await sendData(
      `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/cinputs`,
      {
        name: inputData.name,
        type: inputData.type,
        usecase: inputData.usecase,
        required: inputData.required,
      },
      userToken,
      compKey
    );
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw Error("already exists.");
    } else {
      throw error;
    }
  }
};

export const deleteCustomField = async (compKey, item) => {
  console.log("deleteCustomField : " + item);
  const userToken = getToken();
  if (!userToken) {
    throw new Error("User token not found");
  }

  try {
    await deleteData(
      `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/cinputs`,
      userToken,
      compKey,
      item
    );
  } catch (error) {
    throw new Error("Error deleting custom field");
  }
};
