import { useNotification } from "../context/NotificationContext";
import { sendData, fetchData } from "../utils/Api";
import { deleteToken, getToken, setToken } from "../utils/const";
import { linkConfig } from "../config";
const endpoints = {
  registerEndpoint: `${linkConfig.apiSuffix}/register`,
  loginEndpoint: `${linkConfig.apiSuffix}/login`,
  authEndpoint: `${linkConfig.apiSuffix}/checkAuthentication`,
  logoutEndpoint: `${linkConfig.apiSuffix}/logout`,
  checkEmailEndpoint: `${linkConfig.apiSuffix}/email-confirmation-status`,
};
export const registerUser = async (userData) => {
  try {
    await sendData(endpoints.registerEndpoint, userData);
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error("Email already exists."); // Throw specific error for existing user
    } else {
      throw error;
    }
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await sendData(endpoints.loginEndpoint, userData);
    const { token, comp_key } = response;
    console.log("loginUser Token:", token);
    console.log("loginUser Comp Key:", comp_key);
    setToken(token);
    window.location.href = "/";
    return { token, comp_key };
  } catch (error) {
    throw error;
  }
};

export const logoutUser = async (userData) => {
  try {
    const token = getToken();
    await sendData(endpoints.logoutEndpoint, userData, token);
    console.log("Received token:", token);
    deleteToken();
    console.log("logoutUser Token:", token);
  } catch (error) {
    throw error;
  }
};

export const checkAuthentication = async (token) => {
  console.log("Checking authentication token" + token);
  try {
    const data = await fetchData(endpoints.authEndpoint, token);
    return data.isLoggedIn;
  } catch (error) {
    console.error("Error checking authentication:", error.message);
    return false;
  }
};

export const fetchEmailConfirmationStatus = async (token, showNotification) => {
  try {
    console.log("Fetching email confirmation status...");
    const data = await fetchData(endpoints.checkEmailEndpoint, token);
    console.log("Email confirmation status data:", data);
    const emailIsConfirmed = data.usrIsEmailConfirmed; // Adjust according to the response structure
    console.log("Email is confirmed:", emailIsConfirmed);

    if (!emailIsConfirmed) {
      showNotification("error", "Please confirm your email address.");
    }

    return emailIsConfirmed;
  } catch (error) {
    console.error(
      "Error checking fetchEmailConfirmationStatus:",
      error.message
    );
    return false;
  }
};
