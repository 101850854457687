import React, { useEffect } from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(100%);
  }
`;

const NotificationContainer = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  color: #fff;
  padding: 10px 20px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: ${({ isOpen }) => (isOpen ? slideIn : slideOut)} 0.5s ease forwards;
`;

const Notification = ({ type, message, theme, setOpen }) => {
  useEffect(() => {
    let timer;

    // If setOpen function is provided, set a timer to dismiss the notification after 3 seconds
    if (setOpen) {
      timer = setTimeout(() => {
        setOpen(false);
      }, 3000);
    }

    return () => clearTimeout(timer);
  }, [setOpen]);

  const getBackgroundColor = () => {
    switch (type) {
      case "error":
        return theme.colors.error;
      case "success":
        return theme.colors.success;
      default:
        return theme.colors.info;
    }
  };

  const handleClick = () => {
    if (setOpen) {
      setOpen(false); // Close the notification when clicked if setOpen function is provided
    }
  };

  return (
    <NotificationContainer
      type={type}
      isOpen={true} // Always open for now
      style={{ backgroundColor: getBackgroundColor() }}
      onClick={handleClick}
    >
      {message}
    </NotificationContainer>
  );
};

export default Notification;
