import React, { createContext, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

const ContactContext = createContext();

export const ContactProvider = ({ children }) => {
  const [context, setContext] = useState(undefined);
  const nav = useNavigate();
  const changeContactContext = (newContext) => {
    console.log(newContext);
    setContext(newContext);
    nav("/contact");
  };

  return (
    <ContactContext.Provider value={{ context, changeContactContext }}>
      {children}
    </ContactContext.Provider>
  );
};

// Custom hook for using the Sidebar context
export const useContactContext = () => useContext(ContactContext);
