import React, { useState } from "react";
import * as authService from "../../../services/authService";
import {
  Button,
  Card,
  TextInput,
  Form,
  Divider,
  Typography,
} from "../../ui/Index";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { CardStyles } from "../../styles/CardStyles";
import { validateLogin } from "../../../utils/ButtonUtils";
import { useNavigate } from "react-router-dom";
import { SuccessButton } from "../../styles/ButtonStyles";

const LoginForm = ({ theme, isMobile, t }) => {
  const [email, setEmail] = useState("admin@admin.com");
  const [password, setPassword] = useState("admin");
  const [error, setError] = useState(""); // State for holding error message
  const { formStyle } = LayoutStyles(theme, isMobile);
  const { LoginRegisterCardStyle } = CardStyles(theme, isMobile);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    const { value } = e.target;
    switch (field) {
      case "Email":
        setEmail(value);
        break;
      case "Password":
        setPassword(value);
        break;
      default:
        break;
    }
  };

  const getValueForField = (field) => {
    switch (field) {
      case "Email":
        return email;
      case "Password":
        return password;
      default:
        return "";
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateLogin(email, password, setError);
    if (!isValid) return;

    try {
      await authService.loginUser({ email, password });
      console.log("Login successful!");
    } catch (error) {
      setError("Login failed: " + error.message);
      console.error("Login failed:", error);
    }
  };

  const fieldNames = ["Email", "Password"];

  return (
    <Card title={"Login"} style={{ ...LoginRegisterCardStyle }}>
      <Form style={{ ...formStyle }} onSubmit={handleSubmit}>
        {fieldNames.map((fieldName, index) => (
          <TextInput
            key={index}
            type={fieldName === "Password" ? "password" : "text"}
            placeholder={fieldName}
            value={getValueForField(fieldName)}
            onChange={(e) => handleChange(e, fieldName)}
          />
        ))}
        {error && <Typography color="error">{error}</Typography>}{" "}
        {/* Display error message */}
        <Divider />
        <SuccessButton type="submit">{t("logIn")}</SuccessButton>
      </Form>
    </Card>
  );
};

export default LoginForm;
