import React from "react";
import styled from "styled-components";
// Styled Components
const ProgressBarContainer = styled.div`
  width: ${({ width }) => width}px;
  background-color: #f0f0f0;
  border-radius: 4px;
  overflow: hidden;
`;

const ProgressIndicator = styled.div`
  height: 100%;
  width: ${({ value }) => value}%;
  background-color: #007bff;
`;

// Progress Component
const Progress = ({ value, width }) => {
  return (
    <ProgressBarContainer width={width}>
      <ProgressIndicator value={value} />
    </ProgressBarContainer>
  );
};

export default Progress;
