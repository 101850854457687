// ButtonStyles.js
import styled, { css } from "styled-components";
import { defaultTransitions } from "../../utils/const";
export const DefaultButton = styled.button`
  ${({ theme }) => `
    background-color:  ${theme.colors.primary};
    color: ${theme.colors.primaryText};
    border: none;
    cursor: pointer;
    transition: ${defaultTransitions(theme)};

    &:hover {
      background-color: ${theme.colors.primary};
    }
  `}
`;

export const MenuButton = styled(DefaultButton)`
  ${({ theme, isActive }) => `
    padding: 10px;
    background-color: ${isActive ? `${theme.colors.primary}` : "transparent"};
    cursor: pointer;
    transition: ${defaultTransitions(theme)};
  `}
`;

export const SubMenuButton = styled(DefaultButton)`
  ${({ theme, isActive }) => `
  width: 100%;
  padding: 10px;
  background-color: ${isActive ? "red" : theme.colors.onSurface};
  cursor: pointer;
  transition: ${defaultTransitions(theme)};
  text-align: center;
  white-space: nowrap;
  overflow: hidden; 
  text-overflow: ellipsis;
  `}
`;

export const PagerButton = styled(DefaultButton)`
  ${({ theme, disabled }) => `
    padding: 10px;
    width: 100%;
    background-color: transparent;
    border: 1px solid ${theme.colors.primary};
    border-radius: ${theme.radius.default};
    cursor: ${
      disabled ? "not-allowed" : "pointer"
    }; // Change cursor based on disabled state
    transition: ${defaultTransitions(theme)};

    ${
      disabled &&
      css`
        opacity: 0.5; // Change opacity for disabled state
      `
    }
  `}
`;

export const CloseButton = styled(DefaultButton)`
  ${({ theme }) => `
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    padding: ${theme.spacing.small};
    background-color: transparent;
    border-radius: ${theme.radius.default};
    cursor: pointer;
    color: red;
    font-size: 16px;

    &:hover {
      background-color: red;
      color: #ccc;
    }
  `}
`;

export const SuccessButton = styled(DefaultButton)`
  ${({ theme }) => `
    background-color: ${theme.colors.success};
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: ${defaultTransitions(theme)};

    &:hover {
      background-color: ${theme.colors.successDark};
    }
  `}
`;

export const EditButton = styled(DefaultButton)`
  ${({ theme }) => `
    background-color: ${theme.colors.warning};
    color: white;
    padding: 10px;
    border: none;
    cursor: pointer;
    transition: ${defaultTransitions(theme)};

    &:hover {
      background-color: ${theme.colors.successDark};
    }
  `}
`;

export const AddButton = styled(DefaultButton)`
  ${({ theme }) => `
  position: relative;
    background-color: ${theme.colors.success};
    color: ${theme.colors.primaryText};
    border: none;
    cursor: pointer;
    transition: ${defaultTransitions(theme)};
    height: 25px;
    &:hover {
      background-color: ${theme.colors.successDark};
    }
  `}
`;

export const DelButton = styled(DefaultButton)`
  ${({ theme }) => `
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    padding: ${theme.spacing.small};
    background-color: transparent;
    border-radius: ${theme.radius.default};
    cursor: pointer;
    color: ${theme.colors.primaryText};
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background-color: red;
      color: #ccc;
    }
  `}
`;

export const CancelButton = styled(DefaultButton)`
  ${({ theme }) => `
    top: 10px; /* Adjust top position as needed */
    right: 10px; /* Adjust right position as needed */
    padding: ${theme.spacing.small};
    border-radius: ${theme.radius.default};
    cursor: pointer;
    font-size: 16px;

    &:hover {
      color: #ccc;
    }
  `}
`;
