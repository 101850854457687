import { fetchData, updateField } from "../utils/Api";
import { getToken } from "../utils/const";
import { linkConfig } from "../config";
// Function to fetch all user data
export const fetchAllUserData = async () => {
  try {
    console.log("Fetching all user data");
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    // Decode the token to access its payload
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id; // Assuming the user ID is stored in 'sub' field

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/user-data/${userId}`;
    const allUserData = await fetchData(endpoint, userToken);
    console.log(allUserData);
    return allUserData;
  } catch (error) {
    console.error("Error fetching all user data:", error.message);
    throw error;
  }
};

// Function to fetch a single field
export const fetchUserDataField = async (fieldName) => {
  try {
    console.log(`Service fetchUserDataField ${fieldName}`);
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    // Decode the token to access its payload
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id; // Assuming the user ID is stored in 'sub' field

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/user-data/${userId}/${fieldName}`;
    const response = await fetchData(endpoint, userToken);

    // Extract the specific field value from the response
    const fieldValue = response[fieldName];

    return fieldValue;
  } catch (error) {
    console.error(`Error fetching user ${fieldName}:`, error.message);
    throw error;
  }
};

export const updateUserDataField = async (userId, fieldName, fieldValue) => {
  try {
    console.log(
      `Updating userId ${userId}, field: ${fieldName}, value: ${fieldValue}`
    );
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/user-data/${userId}/${fieldName}`;
    const response = await updateField(
      endpoint,
      fieldName,
      fieldValue,
      userToken
    );

    return response;
  } catch (err) {
    console.error(
      `Error updating ${fieldName} for company ${userId}:`,
      err.message
    );
    throw err;
  }
};
export const updateSingleUserData = async (userId, field, value) => {
  try {
    return await updateUserDataField(userId, field, value);
  } catch (err) {
    console.error(
      `Error updating company name for user ${userId}:`,
      err.message
    );
    throw err;
  }
};

export const getEmail = async () => {
  return fetchUserDataField("usr_email");
};

export const getUsername = async () => {
  return fetchUserDataField("username");
};

export const getIsEmailConfirmed = async () => {
  return fetchUserDataField("usr_is_email_confirmed");
};

export const getUserCompKey = async () => {
  return fetchUserDataField("comp_key");
};

export const getUserFirstTime = async () => {
  return fetchUserDataField("usr_first_time");
};
