import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { Box, DeleteButton, TextInput } from "../../ui/Index";
import Toolbar from "../../widgets/Toolbar";
import SearchField from "./SearchField";
import handleSearch from "../../../utils/searchUtils";
import { AddButton } from "../../styles/ButtonStyles";
import { RowBox } from "../../styles/BoxStyles";

const TableWrapper = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableCell = styled.td`
  padding: 10px;
  border: 1px solid #222222;
  color: #ffffff;
`;

const ActionCell = styled(TableCell)`
  text-align: center;
  position: relative;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: ${(props) => props.theme.colors.surface};
  }
  &:hover {
    background-color: #555555;
  }
  cursor: ${(props) => (props.clickable ? "pointer" : "default")};
  background-color: ${(props) => (props.selected ? "#4287f5" : "inherit")};
`;

const CheckboxCell = styled(TableCell)`
  text-align: center;
`;

const CheckboxInput = styled.input`
  cursor: pointer;
`;

const ActionButton = styled.button`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: 1;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: ${(props) => props.position.top}px;
  left: ${(props) => props.position.left}px;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1000;
  display: ${(props) => (props.visible ? "block" : "none")};
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
`;

const MenuItem = styled.div`
  padding: 10px;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Table = ({
  headers,
  data,
  onDelete,
  loading,
  onItemClick,
  onAddItem,
  onCategoryChange,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
  const [selectedCategory, setSelectedCategory] = useState({
    value: "All",
    label: "All",
  });
  const [isAdding, setIsAdding] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [activeRow, setActiveRow] = useState(null);
  const dropdownRef = useRef(null);

  const tableCategories = [
    { value: "All", label: "All" },
    { value: "Privat", label: "Privat" },
    { value: "Business", label: "Business" },
  ];

  useEffect(() => {
    if (data && data.length > 0) {
      sortData(data, sortConfig.key, sortConfig.direction);
    }
  }, [data, sortConfig.key, sortConfig.direction]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const sortData = (data, key, direction) => {
    const sortedData = [...data].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === "asc" ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === "asc" ? 1 : -1;
      }
      return 0;
    });
    setFilteredItems(sortedData);
  };

  const handleSort = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });
    sortData(filteredItems, key, direction);
  };

  const handleSearchWrapper = (query) => {
    handleSearch(data, query, setFilteredItems);
  };

  const handleCheckboxChange = (customer_key) => {
    console.log("handleCheckboxChange", customer_key);
    setSelectedItems((prevSelectedItems) => {
      if (prevSelectedItems.includes(customer_key)) {
        return prevSelectedItems.filter((key) => key !== customer_key);
      } else {
        return [...prevSelectedItems, customer_key];
      }
    });
  };

  const handleItemClick = (index, item) => {
    const clickedElement = document.activeElement.tagName;
    if (clickedElement !== "INPUT" && clickedElement !== "BUTTON") {
      onItemClick(index, item);
    }
  };

  const deleteSelectedItems = () => {
    selectedItems.forEach((customer_key) => {
      onDelete(customer_key);
    });
    setSelectedItems([]);
  };

  const isSelected = (customer_key) => {
    return selectedItems.includes(customer_key);
  };

  const handleCategoryChange = (selectedOption) => {
    setSelectedCategory(selectedOption);
    if (onCategoryChange) {
      onCategoryChange(selectedOption);
    }
  };

  const toolbarActions = [
    {
      label: "Edit",
      onClick: () => console.log("Edit clicked"),
      icon: "edit",
    },
    {
      label: "Email",
      onClick: () => console.log("Email clicked"),
      icon: "email",
    },
    {
      label: "Print",
      onClick: () => console.log("Print clicked"),
      icon: "print",
    },
    {
      label: "Delete",
      onClick: deleteSelectedItems,
      icon: "delete",
    },
  ];

  const handleAddItemClick = () => {
    setIsAdding(true);
    setNewItem({});
  };

  const handleNewItemChange = (key, value) => {
    setNewItem((prevItem) => ({
      ...prevItem,
      [key]: value,
    }));
  };

  const handleSaveNewItem = () => {
    onAddItem(newItem);
    setIsAdding(false);
  };

  const handleActionClick = (e, item) => {
    e.stopPropagation();
    const rect = e.target.getBoundingClientRect();
    setDropdownPosition({ top: rect.bottom, left: rect.left });
    setDropdownVisible(activeRow !== item.customer_key || !dropdownVisible);
    setActiveRow(item.customer_key);
  };

  const handleMenuSelect = (action) => {
    console.log(`${action} clicked for item ${activeRow}`);
    // Implement your action here
    setDropdownVisible(false);
  };

  const renderInputField = (header) => {
    const { type } = header;
    const key = header.key;
    if (type === "boolean") {
      return (
        <select
          value={newItem[key] || ""}
          onChange={(e) => handleNewItemChange(key, e.target.value)}
        >
          <option value="">Select...</option>
          <option value="true">True</option>
          <option value="false">False</option>
        </select>
      );
    }
    if (type === "info") {
      return null;
    }
    if (type === "currency") {
      return (
        <TextInput
          type="currency"
          value={newItem[key] || ""}
          onChange={(e) => handleNewItemChange(key, e.target.value)}
        />
      );
    }
    return (
      <TextInput
        type="text"
        value={newItem[key] || ""}
        onChange={(e) => handleNewItemChange(key, e.target.value)}
      />
    );
  };

  return (
    <div>
      <RowBox>
        <SearchField onSearch={handleSearchWrapper} />
        <Toolbar actions={toolbarActions} />
        <AddButton onClick={handleAddItemClick}>Hinzufügen</AddButton>
      </RowBox>
      <div>Selected: {selectedItems.length}</div>
      <TableWrapper>
        <thead>
          <tr>
            <CheckboxCell></CheckboxCell>
            {headers.map((header, index) =>
              header.key !== "customer_key" ? (
                <TableCell
                  key={index}
                  onClick={() => handleSort(header.key)}
                  style={{ cursor: "pointer" }}
                >
                  {header.label}
                  {sortConfig.key === header.key && (
                    <span>{sortConfig.direction === "asc" ? " ↑" : " ↓"}</span>
                  )}
                </TableCell>
              ) : null
            )}
            <ActionCell>Action</ActionCell>
          </tr>
        </thead>
        <tbody>
          {isAdding && (
            <TableRow>
              <CheckboxCell></CheckboxCell>
              {headers.map(
                (header) =>
                  header.key !== "customer_key" && (
                    <TableCell key={header.key}>
                      {renderInputField(header)}
                    </TableCell>
                  )
              )}
              <ActionCell>
                <button onClick={handleSaveNewItem}>Save</button>
                <button onClick={() => setIsAdding(false)}>Cancel</button>
              </ActionCell>
            </TableRow>
          )}
          {filteredItems.length === 0 ? (
            <tr>
              <TableCell colSpan={headers.length + 2}>No data</TableCell>
            </tr>
          ) : (
            filteredItems.map((item, index) => (
              <TableRow
                key={item.customer_key}
                onClick={() => handleItemClick(index, item)}
                clickable={true}
                selected={isSelected(item.customer_key)}
              >
                <CheckboxCell>
                  <CheckboxInput
                    type="checkbox"
                    checked={isSelected(item.id)}
                    onChange={(e) => {
                      e.stopPropagation();
                      handleCheckboxChange(item.id);
                    }}
                  />
                </CheckboxCell>
                {Object.entries(item).map(
                  ([key, value], cellIndex) =>
                    key !== "customer_key" && (
                      <TableCell key={cellIndex}>
                        {typeof value === "string"
                          ? value
                          : JSON.stringify(value)}
                      </TableCell>
                    )
                )}
                <ActionCell>
                  <DeleteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      onDelete(item.customer_key);
                    }}
                  >
                    Delete
                  </DeleteButton>
                </ActionCell>
              </TableRow>
            ))
          )}
        </tbody>
      </TableWrapper>
    </div>
  );
};

export default Table;
