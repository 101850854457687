import React, { useEffect, useState } from "react";
import { useDocsContext } from "../../layout/context/DocsContext";
import { docsContexts } from "../../../utils/const";
import { ColumnBox, RowBox } from "../../styles/BoxStyles";
import Table from "../../ui/components/Table";
import {
  addDocsPosition,
  fetchAllDocs,
  fetchDocsPositions,
} from "../../../services/docsService";
import { Divider } from "../../ui/Index";
import DocOverview from "../../widgets/pdf/DocOverview";
import AddNewDocument from "../../widgets/pdf/AddNewDocument";
import { useNavigate } from "react-router-dom";

const Docs = ({ compData, theme }) => {
  const { context } = useDocsContext();
  const [loading, setLoading] = useState();
  const [fetchedTableData, setFetchedTableData] = useState([]);
  const nav = useNavigate();
  const [documentSenderInputs] = useState([
    {
      key: "senderName",
      label: "Sender Name",
      value: "",
      type: "info",
      required: true,
    },
    {
      key: "senderAddress",
      label: "Sender Street & Nr.",
      value: "",
      type: "info",
      required: true,
    },
    {
      key: "senderpCode",
      label: "Post Code",
      value: "",
      type: "info",
      required: true,
    },
    {
      key: "senderCity",
      label: "Sender City",
      value: "",
      type: "info",
      required: true,
    },
  ]);
  const [documentRecipientInputs] = useState([
    {
      key: "invoiceNumber",
      label: "Invoice Number",
      value: "",
      type: "info",
      required: true,
    },
    { key: "date", label: "Date", value: "", type: "date", required: true },
    {
      key: "recipientName",
      label: "Recipient Name",
      value: "",
      type: "text",
      required: true,
    },
    {
      key: "recipientAddress",
      label: "Recipient Street & Nr.",
      value: "",
      type: "text",
      required: true,
    },
    {
      key: "recipientpCode",
      label: "Recipient Post Code",
      value: "",
      type: "text",
      required: true,
    },
    {
      key: "recipientCity",
      label: "Recipient City",
      value: "",
      type: "text",
      required: true,
    },
    // Add more document inputs as needed
  ]);
  const [tableHeaders, setTableHeaders] = useState([
    { key: "id", label: "ID", type: "info" },
    { key: "name", label: "Name", type: "string" },
  ]);

  const fetchDocsData = async (context) => {
    setLoading(true);
    try {
      switch (context) {
        case docsContexts.DOCS_LIST_ALL:
          try {
            const allDocs = await fetchAllDocs(compData?.comp_key);
            console.log("Fetched documents:", allDocs); // Inspect data here
            setTableHeaders([
              { key: "doc_key", label: "ID", type: "info" },
              { key: "name", label: "Name", type: "string" },
              { key: "type", label: "Type", type: "string" },
              { key: "invoiceNumber", label: "Invoice number", type: "string" },
            ]);
            setFetchedTableData(allDocs);
          } catch (err) {
            console.error("Error fetching all docs:", err.message);
          }
          break;

        case docsContexts.DOCS_POSITIONS:
          try {
            const data = await fetchDocsPositions(compData?.comp_key);
            setFetchedTableData(data);
            setTableHeaders([
              { key: "id", label: "ID", type: "info" },
              { key: "name", label: "Name", type: "string" },
              { key: "desc", label: "Description", type: "string" },
              { key: "price", label: "Price", type: "currency" },
              { key: "vat", label: "VAT", type: "string" },
              { key: "total", label: "Total", type: "string" },
            ]);
          } catch (err) {
            console.error("Error fetching positions:", err.message);
          }
          break;

        case docsContexts.DOCS_TEMPLATES:
          try {
            setTableHeaders([
              { key: "id", label: "ID", type: "info" },
              { key: "name", label: "Name", type: "string" },
            ]);
          } catch (err) {
            console.error("Error fetching templates:", err.message);
          }
          break;

        default:
          console.error("Unknown context:", context);
          break;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = (id) => {
    console.log("onDelete", id);
  };

  const onItemClick = (id, item) => {
    console.log("onItemClick", id, item);
    nav(`/docs/${item.id}`);
  };

  const onAddItem = async (item) => {
    console.log("onAddItem", item);
    switch (context) {
      case docsContexts.DOCS_POSITIONS:
        try {
          await addDocsPosition(compData?.comp_key, item);
        } catch (err) {
          console.error("Error adding item:", err.message);
        } finally {
          // fetchDocsData(context);
        }
        break;
      case docsContexts.DOCS_TEMPLATES:
        try {
          //TODO ADD TEMPLATE
          console.log("Template added successfully");
        } catch (err) {
          console.error("Error adding template:", err.message);
        }
        break;
      default:
        console.error("Unknown context:", context);
        break;
    }
  };

  useEffect(() => {
    fetchDocsData(context);
  }, [context]);

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  const tableData = fetchedTableData.map((item) => {
    switch (context) {
      case docsContexts.DOCS_LIST_ALL:
        return {
          id: item.doc_key,
          name: item.name,
          type: item.type,
        };
      case docsContexts.DOCS_POSITIONS:
        return {
          id: item.pos_id,
          name: item.pos_name,
          desc: item.pos_desc,
          price: item.pos_price,
          vat: item.pos_vat,
          total: item.pos_total,
        };
      default:
        return {};
    }
  });

  // Conditional rendering based on the context
  const renderComponent = () => {
    switch (context) {
      case docsContexts.DOCS_LIST_ALL:
      case docsContexts.DOCS_POSITIONS:
        return (
          <Table
            headers={tableHeaders}
            data={tableData}
            onDelete={onDelete}
            loading={loading}
            onItemClick={onItemClick}
            onAddItem={onAddItem}
          />
        );

      case docsContexts.DOCS_TEMPLATES:
        return (
          <RowBox>
            <Table
              headers={tableHeaders}
              data={tableData}
              onDelete={onDelete}
              loading={loading}
              onItemClick={onItemClick}
              onAddItem={onAddItem}
            />
            <Divider />
            <DocOverview />
          </RowBox>
        );
      case docsContexts.DOCS_CREATE_NEW:
        return (
          <AddNewDocument
            compData={compData}
            documentRecipientInputs={documentRecipientInputs}
            documentSenderInputs={documentSenderInputs}
            theme={theme}
          />
        );

      default:
        return <div>Unsupported context</div>;
    }
  };

  return <ColumnBox>{renderComponent()}</ColumnBox>;
};

export default Docs;
