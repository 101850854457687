import React from "react";
import styled from "styled-components";

// Styled Components
const DividerLine = styled.hr`
  border: none;
  border-top: 1px solid ${(props) => props.color || "#ccc"};
  margin: ${(props) => props.margin || "6px 0"};
`;

// Divider Component
const Divider = ({ gapSize, color }) => {
  return <DividerLine margin={gapSize} color={color} />;
};

export default Divider;
