import { composeInitialProps } from "react-i18next";
import { fetchData, sendData, updateData, updateField } from "../utils/Api";
import { getToken } from "../utils/const";
import { linkConfig } from "../config";

export const fetchAllCompanyData = async (compKey) => {
  try {
    console.log("Fetching all Comp data" + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    // Decode the token to access its payload
    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}`;
    const allCompData = await fetchData(endpoint, userToken, compKey);

    return allCompData;
  } catch (error) {
    console.error("Error fetching all Company data:", error.message);
    throw error;
  }
};

const fetchCompanyDataField = async (compKey, fieldName) => {
  try {
    console.log(`Fetching Comp ${fieldName}`);
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/${fieldName}`;
    const response = await fetchData(endpoint, userToken);

    // Extract the specific field value from the response
    const fieldData = response[fieldName];

    return fieldData;
  } catch (err) {
    console.error(`Error fetching user ${fieldName}:`, err.message);
    throw err;
  }
};

export const updateSingleCompanyData = async (
  compKey,
  fieldName,
  fieldValue
) => {
  try {
    console.log(
      `Updating Comp ${compKey}, field: ${fieldName}, value: ${fieldValue}`
    );
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }
    const subkey = "";
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/${fieldName}`;
    const response = await updateField(
      endpoint,
      fieldName,
      fieldValue,
      userToken,
      subkey,
      compKey
    );

    return response; // Assuming response contains updated company data
  } catch (err) {
    console.error(
      `Error updating ${fieldName} for company ${compKey}:`,
      err.message
    );
    throw err;
  }
};

export const getCompEmail = async () => {
  return fetchCompanyDataField("email");
};
export const fetchMemberStateById = async (compKey, memberStateId) => {
  try {
    console.log("Fetching member state for ID: " + memberStateId);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/member-state/${memberStateId}`;
    const memberState = await fetchData(endpoint, userToken, compKey);

    return memberState;
  } catch (error) {
    console.error("Error fetching member state by ID:", error.message);
    throw error;
  }
};
export const fetchAllMemberStates = async (compKey) => {
  try {
    console.log("Fetching all member states for compKey: " + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/member-states`;
    const memberStates = await fetchData(endpoint, userToken, compKey);

    return memberStates;
  } catch (error) {
    console.error("Error fetching member states:", error.message);
    throw error;
  }
};

export const addMemberState = async (compKey, stateName, isMember) => {
  try {
    console.log(
      `Adding member state for compKey: ${compKey}, stateName: ${stateName}, isMember: ${isMember}`
    );
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/member-states`;
    const response = await sendData(
      endpoint,
      { stateName, isMember },
      userToken,
      compKey
    );

    return response;
  } catch (error) {
    console.error("Error adding member state:", error.message);
    throw error;
  }
};

export const updateMemberState = async (compKey, stateName, isMember) => {
  try {
    console.log(
      `Updating member state for compKey: ${compKey}, stateName: ${stateName}, isMember: ${isMember}`
    );
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/member-states/${stateName}`;
    const response = await updateData(endpoint, { isMember }, userToken);

    return response;
  } catch (error) {
    console.error("Error updating member state:", error.message);
    throw error;
  }
};
