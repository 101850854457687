import React, { createContext, useContext, useState, useEffect } from "react";
import themes from "../Themes";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const savedTheme = localStorage.getItem("theme");
  const [currentTheme, setCurrentTheme] = useState(savedTheme || "light");

  useEffect(() => {
    localStorage.setItem("theme", currentTheme);
  }, [currentTheme]);

  const toggleTheme = () => {
    setCurrentTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  const theme = themes[currentTheme];

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty("--selection-color", theme.selectionColor);
  }, [theme]);

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};
