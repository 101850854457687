// Accordion.js

import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { useDynamicHeight } from "../../../utils/LayoutUtils";
import { defaultTransitions } from "../../../utils/const";

// Styled Components
const AccordionContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 4px;
`;

const AccordionHeader = styled.div`
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.text};
  cursor: pointer;
`;

const AccordionContent = styled.div`
  padding: ${({ isOpen, theme }) => (isOpen ? theme.spacing.small : "0px")};
  height: ${({ contentHeight }) => `${contentHeight}px`};
  overflow: hidden;

  display: flex;
  justify-content: center; /* Center the children horizontally */
  align-items: center; /* Center the children vertically */

  background-color: ${({ theme }) => theme.colors.secondary};
  color: ${({ theme }) => theme.colors.text};
  transition: height 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

// Accordion Component
const Accordion = ({ title, children, getOpenValue }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { contentHeight, contentRef } = useDynamicHeight(isOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
    getOpenValue(isOpen);
  };

  return (
    <AccordionContainer>
      <AccordionHeader onClick={toggleAccordion}>
        <span>{title}</span>
      </AccordionHeader>
      <AccordionContent isOpen={isOpen} contentHeight={contentHeight}>
        <div ref={contentRef}>{children}</div>
      </AccordionContent>
    </AccordionContainer>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  getOpenValue: PropTypes.node,
};

export default Accordion;
