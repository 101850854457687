export const TypographyStyles = {
  h1: {
    fontSize: "2.5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h2: {
    fontSize: "2rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  h3: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    lineHeight: 1.2,
  },
  span: {
    fontSize: "1rem",
    lineHeight: 1.5,
  },
  link: {
    fontSize: "1rem",
    lineHeight: 1.5,
    cursor: "pointer",
    textDecoration: "underline",
  },
};

export default TypographyStyles;
