// UserCompanyForm.js

import React, { useEffect, useState } from "react";
import {
  Button,
  TextInput,
  Form,
  Divider,
  Typography,
  Accordion,
  Box,
} from "../../ui/Index";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../ui/components/CheckBox";
import { useModalContext } from "../../layout/context/ModalContext";
import { updateSingleCompanyData } from "../../../services/compServices";
import { updateData } from "../../../utils/Api";
import { updateSingleUserData } from "../../../services/userService";
import { SuccessButton } from "../../styles/ButtonStyles";

const ChangeCompDataForm = ({
  theme,
  isMobile,
  setOnSubmit,
  userData,
  compData,
}) => {
  const { changeModalContext, reloadModalPage } = useModalContext();
  const [requiredFields, setRequiredFields] = useState([
    "Company Name",
    "Company Street",
  ]);

  const [fields, setFields] = useState({
    "Company Name": "",
    "Company Street": "",
    "Company Post Code": "",
    "Company State": "",
    "Company Country": "",
    "Company Vat Code": "",
  });

  const [error, setError] = useState("");
  const { formStyle } = LayoutStyles(theme, isMobile);

  useEffect(() => {
    console.log(compData);
    if (compData) {
      setFields((prevFields) => ({
        ...prevFields,
        "Company Name": compData.comp_name || "",
        "Company Street": compData.comp_street || "",
        "Company Post Code": compData.comp_pcode || "",
        "Company State": compData.comp_state || "",
        "Company Country": compData.comp_country || "",
        "Company Vat Code": compData.comp_vat || "",
      }));
    }
  }, [compData]);

  const handleChange = (e, field) => {
    const { value } = e.target;

    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const updateCompData = async () => {
    console.log("updateCompData " + compData.comp_key);

    try {
      console.log("updateCompanyName userData" + compData.comp_key);
      if (fields["Company Name"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_name",
          fields["Company Name"]
        );
      }

      if (fields["Company Street"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_street",
          fields["Company Street"]
        );
      }

      if (fields["Company Post Code"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_pcode",
          fields["Company Post Code"]
        );
      }

      if (fields["Company State"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_state",
          fields["Company State"]
        );
      }

      if (fields["Company Country"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_country",
          fields["Company Country"]
        );
      }

      if (fields["Company Vat Code"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_vat",
          fields["Company Vat Code"]
        );
      }
      await updateSingleUserData(userData.usr_id, "usr_first_time", 0);
    } catch (err) {
      console.log(err + compData.comp_key);
      setError(err);
      return;
    } finally {
      setOnSubmit(false);
      changeModalContext("");
      reloadModalPage();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = requiredFields.filter((field) => !fields[field]);
    if (missingFields.length > 0) {
      setError("Please fill in all required fields.");
    } else {
      setError("");
      updateCompData();
    }
  };

  return (
    <Form style={{ ...formStyle }} onSubmit={handleSubmit}>
      {Object.keys(fields).map((fieldName, index) => (
        <TextInput
          key={index}
          type={"text"}
          placeholder={fieldName}
          error={requiredFields.includes(fieldName) ? error : undefined}
          value={fields[fieldName]}
          onChange={(e) => handleChange(e, fieldName)}
        />
      ))}

      <SuccessButton type="submit">Speichern</SuccessButton>
    </Form>
  );
};

export default ChangeCompDataForm;
