import React, { useEffect, useState } from "react";
import * as IndexUI from "../ui/Index";
import { useNotification } from "../../context/NotificationContext";
import { getIsEmailConfirmed } from "../../services/userService";

const Overlay = ({ t, isMobile, theme, userData }) => {
  const { notification } = useNotification();
  const [emailActive, setEmailActive] = useState(false);

  useEffect(() => {
    if (userData && userData.usr_is_email_confirmed !== undefined) {
      if (userData.usr_is_email_confirmed) {
        setEmailActive(true);
      }
    }
  }, [userData]);
  return (
    <div>
      {notification && (
        <IndexUI.Notification
          type={notification.type}
          message={notification.message}
          theme={theme}
        />
      )}
      {!emailActive && (
        <IndexUI.Notification
          type="info"
          message="Please verify your email address."
          theme={theme}
        />
      )}
    </div>
  );
};

export default Overlay;
