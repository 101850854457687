import React, { useState, useEffect } from "react";
import { Box, Divider, Dropdown, Form, TextInput } from "../../ui/Index";
import { SuccessButton } from "../../styles/ButtonStyles";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { useModalContext } from "../../layout/context/ModalContext";
import {
  addNewContact,
  fetchAllPrivContactData,
  updateCompanyCustomerField,
  updateSingleCustomerField,
} from "../../../services/customerService";
import { fetchAllcInputData } from "../../../services/customFieldService";
import AddPhoneInput from "../buttons/AddPhoneInput";
import TextInputSection from "./TextInputSection";
import { TextFormBox } from "../../styles/BoxStyles";
import { useLocation, useNavigate } from "react-router-dom";
import {
  filteredPhoneDataForUpdate,
  filterTypeProperty,
} from "../../../utils/dataUtils";
import {
  fetchAllMemberStates,
  fetchMemberStateById,
} from "../../../services/compServices";

const CustomerForm = ({
  theme,
  isMobile,
  saveData,
  setData,
  compData,
  inputData,
  isBusiness = false,
  t,
}) => {
  const { formStyle } = LayoutStyles(theme, isMobile);
  const { changeModalContext, reloadModalPage } = useModalContext();
  const navigate = useNavigate();
  const location = useLocation();
  const [currentBirthdate, setCurrentBirthdate] = useState("");
  const [currentMemberstate, setCurrentMemberState] = useState("");
  const [textContactInputs, setTextContactInputs] = useState({
    personal: [
      {
        name: isBusiness ? "compSalutation" : "salutation",
        title: "Salutation",
        type: "dropdown",
        options: [
          { value: "Herr", label: "Herr" },
          { value: "Frau", label: "Frau" },
          { value: "Dr.", label: "Dr." },
          { value: "Prof.", label: "Prof." },
        ],
        value: "",
      },
      {
        name: isBusiness ? "compFirstName" : "firstName",
        title: "First Name",
        type: "text",
        value: "",
      },
      {
        name: isBusiness ? "compLastName" : "lastName",
        title: "Last Name",
        type: "text",
        value: "",
      },
      {
        name: isBusiness ? "compBirthdate" : "birthdate",
        title: "Birthdate",
        type: "date",
        value: currentBirthdate,
      },
      {
        name: isBusiness ? "compNotes" : "notes",
        title: "Notes",
        type: "text",
        value: "",
      },
    ],
    contact: [
      { name: "email", title: "Email", type: "text", value: "" },
      { name: "address", title: "Address", type: "text", value: "" },
      { name: "subAddress", title: "Sub Address", type: "text", value: "" },
      { name: "pCode", title: "PostCode", type: "text", value: "" },
      { name: "city", title: "City", type: "text", value: "" },
      { name: "state", title: "State", type: "text", value: "" },
      { name: "country", title: "Country", type: "text", value: "" },
    ],
    membership: [
      {
        name: "memberEntry",
        title: "Member Entry Date",
        type: "date",
        value: "",
      },
      {
        name: "memberExit",
        title: "Member Exit Date",
        type: "date",
        value: "",
      },
      {
        name: "memberStatus",
        title: "Member Status",
        type: "dropdown",
        options: [{ value: "Kein Mitglied", label: "Kein Mitglied" }],
        value: "",
      },
    ],
    company: [
      { name: "compName", title: "Company Name", type: "text", value: "" },
      { name: "compEmail", title: "Email", type: "text", value: "" },
      { name: "compWebsite", title: "Website", type: "text", value: "" },
      { name: "compAddress", title: "Street and Nr", type: "text", value: "" },
      {
        name: "compSubAddress",
        title: "Zusatz Address",
        type: "text",
        value: "",
      },
      { name: "compPcode", title: "Post Code", type: "number", value: "" },
      { name: "compState", title: "Company State", type: "text", value: "" },
      { name: "compCity", title: "Company City", type: "text", value: "" },
      {
        name: "compCountry",
        title: "Company Country",
        type: "text",
        value: "",
      },
      {
        name: "sponsorEntry",
        title: "Sponsor Entry Date",
        type: "text",
        value: "",
      },
      {
        name: "sponsorExit",
        title: "Sponsor Exit Date",
        type: "text",
        value: "",
      },
      {
        name: "sponsorStatus",
        title: "Sponsor Status",
        type: "text",
        value: "",
      },
    ],
  });

  const [phoneInputs, setPhoneInputs] = useState([]);
  const [customInputs, setCustomInputs] = useState([]);

  useEffect(() => {
    const fetchCustomInputs = async () => {
      if (compData) {
        try {
          // Fetch custom inputs
          const data = await fetchAllcInputData(compData.comp_key);
          if (data) {
            const cInputValues = inputData
              ? Object.keys(inputData)
                  .filter((key) => key.startsWith("customInput_"))
                  .map((key) => ({
                    value: inputData[key]?.value || "",
                    title: inputData[key]?.title || "",
                    type: inputData[key]?.type,
                  }))
              : [];
            const updatedCustomInputs = data.map((input, index) => ({
              ...input,
              value: cInputValues[index]?.value,
              title: input.cinput_name,
              type: input.cinput_type,
            }));
            console.log("updatedCustomInputs", updatedCustomInputs);
            console.log("cInputValues", cInputValues);
            console.log("inputData", inputData);
            setCustomInputs(updatedCustomInputs);
          }
        } catch (error) {
          console.error("Error fetching custom inputs:", error);
        }
      }
    };

    const setInputValue = () => {
      if (inputData) {
        const updatedPersonalInputs = textContactInputs.personal.map(
          (input) => ({
            ...input,
            value: inputData[input.name]?.value || "",
          })
        );

        const updatedContactInputs = textContactInputs.contact.map((input) => ({
          ...input,
          value: inputData[input.name]?.value || "",
        }));

        const updatedMembershipInputs = textContactInputs.membership.map(
          (input) => ({
            ...input,
            value: inputData[input.name]?.value || "",
          })
        );

        const updatedCompanyInputs = textContactInputs.company.map((input) => ({
          ...input,
          value: inputData[input.name]?.value || "",
        }));

        setTextContactInputs((prev) => ({
          ...prev,
          personal: updatedPersonalInputs,
          contact: updatedContactInputs,
          membership: updatedMembershipInputs,
          company: updatedCompanyInputs,
        }));

        const updatedPhoneInputs = Object.keys(inputData)
          .filter((key) => key.startsWith("phone_"))
          .map((key) => ({
            id: key,
            name: key,
            value: inputData[key]?.value || "",
            title: inputData[key]?.phone_type || "",
          }));

        setPhoneInputs(updatedPhoneInputs);
        console.log(updatedPhoneInputs);
      }
    };

    const fetchMemberStates = async (title) => {
      console.log("fetchMemberStates", title);

      try {
        const data = await fetchAllMemberStates(compData?.comp_key);

        // Transform the fetched data into the required format
        const formattedData = data.map((state) => ({
          value: state.state_id,
          label: state.state_name,
        }));
        console.log("formattedData Memberstate", formattedData);
        setTextContactInputs((prevState) => {
          const updatedMembership = prevState.membership.map((field) => {
            if (field.name === "memberStatus") {
              return {
                ...field,
                options: formattedData,
              };
            }
            return field;
          });

          return {
            ...prevState,
            membership: updatedMembership,
          };
        });
      } catch (error) {
        console.error("Error fetching member states data:", error);
      } finally {
        // setLoading(false); // Uncomment if you have loading state
      }
    };

    const fetchCurrentMemberState = async () => {
      console.log("fetchCurrentMemberState");

      try {
        const memberState = await fetchMemberStateById(
          compData?.comp_key,
          inputData?.memberStatus.value
        );
        console.log("currentMemberstate", memberState.stateName);
        setCurrentMemberState(memberState.stateName);
        setTextContactInputs((prevState) => {
          const updatedMembership = prevState.membership.map((field) => {
            if (field.name === "memberStatus") {
              return {
                ...field,
                title: memberState?.stateName, // Set the title dynamically here
              };
            }
            return field;
          });
          return {
            ...prevState,
            membership: updatedMembership,
          };
        });
      } catch (err) {
        console.error("Error fetching current member state:", err);
      }
    };
    const getBirthdateValue = () => {
      const birthdateInput = textContactInputs.personal.find(
        (input) => input.name === (isBusiness ? "compBirthdate" : "birthdate")
      );
      console.log("birthdateInput", birthdateInput.value);
      setCurrentBirthdate(birthdateInput.value);
      return birthdateInput ? birthdateInput.value : null;
    };
    fetchCustomInputs();
    fetchCurrentMemberState();
    fetchMemberStates(currentMemberstate);
    setInputValue();
    getBirthdateValue();
  }, [compData, inputData]);

  const onInputChange = (e, fieldName) => {
    const { value } = e.target;

    setTextContactInputs((prevInputs) => ({
      ...prevInputs,
      personal: prevInputs.personal.map((input) =>
        input.name === fieldName ? { ...input, value: value } : input
      ),
      company: prevInputs.company.map((input) =>
        input.name === fieldName ? { ...input, value: value } : input
      ),
      contact: prevInputs.contact.map((input) =>
        input.name === fieldName ? { ...input, value: value } : input
      ),
      membership: prevInputs.membership.map((input) =>
        input.name === fieldName ? { ...input, value: value } : input
      ),
    }));

    setCustomInputs((prevInputs) =>
      Array.isArray(prevInputs)
        ? prevInputs.map((input) =>
            input.cinput_name === fieldName ? { ...input, value: value } : input
          )
        : prevInputs
    );
  };

  const handlePhoneOptionSelect = (selectedOption, id) => {
    setPhoneInputs((prevInputs) => [
      ...prevInputs,
      {
        id: id,
        name: selectedOption,
        title: selectedOption,
        type: "text",
        phone_type: selectedOption,
        value: "",
      },
    ]);
  };

  const handlePhoneInputChange = (e, id) => {
    const { value } = e.target;
    setPhoneInputs((prevInputs) =>
      prevInputs.map((input) =>
        input.id === id ? { ...input, value: value } : input
      )
    );
  };

  const addContactData = async (
    commonData,
    phoneData,
    cInputData,
    isBusiness
  ) => {
    if (!inputData) {
      console.log("Form is being NOT inputData");

      try {
        const combinedContactData = {
          common: commonData,
          phones: phoneData,
          input: cInputData,
          isBusiness: isBusiness,
        };

        await addNewContact(compData.comp_key, combinedContactData);
      } catch (err) {
        console.error(`Error adding private customer:`, err.message);
        throw err;
      } finally {
        changeModalContext("");
        reloadModalPage();
      }
    }

    if (inputData) {
      const combinedContactData = {
        common: commonData,
        phones: phoneData,
        input: cInputData,
        isBusiness: isBusiness,
      };
      console.log("Save data:", combinedContactData);
      setData(combinedContactData);

      saveData();
    }
  };

  const handleSaveContactData = async (
    textContactInputs,
    phoneInputs,
    customInputs,
    isBusiness
  ) => {
    try {
      const { key, item } = location.state || {};
      console.log("customer Item:", item);
      setData(item);

      if (
        !key ||
        !item ||
        typeof key !== "string" ||
        typeof item !== "object"
      ) {
        const currentPath = window.location.pathname;
        const parts = currentPath.split("/");
        const uuid = parts[parts.length - 1];

        // Array to collect all promises for textContactInputs updates
        const updatePromises = [];

        // Process textContactInputs
        if (!isBusiness) {
          textContactInputs.personal.forEach((input) => {
            if (input.value) {
              // Add each update operation to the array of promises
              let updatePromise;
              switch (input.name) {
                case "salutation":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_salutation",
                    input.value
                  );
                  break;
                case "firstName":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_first_name",
                    input.value
                  );
                  break;
                case "lastName":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_last_name",
                    input.value
                  );
                  break;
                case "birthdate":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_birthdate",
                    input.value
                  );
                  break;
                default:
                  break;
              }

              if (updatePromise) {
                updatePromises.push(updatePromise);
              }
            }
          });
          textContactInputs.contact.forEach((input) => {
            if (input.value) {
              // Add each update operation to the array of promises
              let updatePromise;
              switch (input.name) {
                case "email":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_email",
                    input.value
                  );
                  break;
                case "address":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_address",
                    input.value
                  );
                  break;
                case "subAddress":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_sub_address",
                    input.value
                  );
                  break;
                case "pCode":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_postal_code",
                    input.value
                  );
                  break;
                case "city":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_city",
                    input.value
                  );
                  break;
                case "state":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_state",
                    input.value
                  );
                  break;
                case "country":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_country",
                    input.value
                  );
                  break;
                default:
                  break;
              }

              if (updatePromise) {
                updatePromises.push(updatePromise);
              }
            }
          });
          textContactInputs.membership.forEach((input) => {
            if (input.value) {
              // Add each update operation to the array of promises
              let updatePromise;
              switch (input.name) {
                case "memberEntry":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_entry_membership",
                    input.value
                  );
                  break;
                case "memberExit":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_exit_membership",
                    input.value
                  );
                  break;
                case "memberStatus":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_membership_status",
                    input.value
                  );
                  break;
                case "notes":
                  updatePromise = updateSingleCustomerField(
                    compData.comp_key,
                    uuid,
                    "pr_contacts_notes",
                    input.value
                  );
                  break;
                default:
                  break;
              }

              if (updatePromise) {
                updatePromises.push(updatePromise);
              }
            }
          });
        } else if (isBusiness) {
          console.log("Business update");
          textContactInputs.personal.forEach((input) => {
            if (input.value) {
              let updatePromise;
              switch (input.name) {
                case "compFirstName":
                  updatePromise = updateCompanyCustomerField(
                    compData.comp_key,
                    uuid,
                    "comp_contacts_first_name",
                    input.value
                  );
                  break;
                default:
                  break;
              }
              if (updatePromise) {
                updatePromises.push(updatePromise);
              }
            }
          });
        }

        // Wait for all textContactInputs updates to complete
        await Promise.all(updatePromises);

        // Process phoneInputs
        if (!isBusiness) {
          if (phoneInputs.length > 0) {
            const filteredPhoneDataResult =
              filteredPhoneDataForUpdate(phoneInputs);
            await updateSingleCustomerField(
              compData.comp_key,
              uuid,
              "pr_contacts_phone",
              filteredPhoneDataResult
            );
          }
        } else if (isBusiness) {
          if (phoneInputs.length > 0) {
            const filteredPhoneDataResult =
              filteredPhoneDataForUpdate(phoneInputs);
            await updateCompanyCustomerField(
              compData.comp_key,
              uuid,
              "comp_contacts_phone",
              filteredPhoneDataResult
            );
          }
        }

        if (!isBusiness) {
          if (customInputs.length > 0) {
            const filteredCustomInputData = filterTypeProperty(customInputs);
            await updateSingleCustomerField(
              compData.comp_key,
              uuid,
              "pr_contacts_custom_inputs",
              filteredCustomInputData
            );
          }
        } else if (isBusiness) {
          if (customInputs.length > 0) {
            const filteredCustomInputData = filterTypeProperty(customInputs);
            await updateCompanyCustomerField(
              compData.comp_key,
              uuid,
              "comp_contacts_custom_inputs",
              filteredCustomInputData
            );
          }
        }
        if (!isBusiness) {
          navigate(`/contact/${uuid}`);
        } else {
          navigate(`/contact/comp/${uuid}`);
        }
      }

      // After successful updates, navigate to customer details page
    } catch (error) {
      console.error("Error updating customer data: ", error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // New additional action: Log a message
    console.log("Form is being submitted isBusiness " + isBusiness);

    if (!inputData) {
      await addContactData(
        textContactInputs,
        phoneInputs,
        customInputs,
        isBusiness
      );
    }
    if (inputData) {
      handleSaveContactData(
        textContactInputs,
        phoneInputs,
        customInputs,
        isBusiness
      );
    }

    // Another additional action: Show a success message
    //alert("Form has been successfully submitted!");
  };

  return (
    <TextFormBox
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form style={{ ...formStyle, width: "80%" }} onSubmit={onSubmit}>
        <Dropdown
          title={textContactInputs.personal[0].title || "Select"}
          options={textContactInputs.personal[0].options || []}
          value={textContactInputs.personal[0].value}
          onChange={(selectedValue) =>
            onInputChange(
              { target: { value: selectedValue } },
              textContactInputs.personal[0].name ||
                textContactInputs.personal[0].cinput_name ||
                textContactInputs.personal[0].id
            )
          }
        />
        <TextInputSection
          isInputHorizontal={true}
          inputs={textContactInputs.personal.slice(1)}
          onInputChange={onInputChange}
          inputsPerRow={2}
        />
        <Divider />
        {isBusiness && (
          <Box>
            <TextInputSection
              inputs={textContactInputs.company}
              onInputChange={onInputChange}
              inputsPerRow={3}
            />
            <Divider />
          </Box>
        )}
        {!isBusiness && (
          <Box>
            <TextInputSection
              inputs={textContactInputs.contact}
              onInputChange={onInputChange}
              inputsPerRow={3}
            />
          </Box>
        )}

        {!isBusiness && (
          <Box>
            <TextInputSection
              inputs={textContactInputs.membership}
              onInputChange={onInputChange}
              inputsPerRow={3}
            />
            <Divider />
          </Box>
        )}

        <>
          <Box>
            {phoneInputs.map((input) => (
              <TextInput
                key={input.id}
                name={input.name}
                title={input.title}
                type={input.type}
                value={input.value}
                onChange={(e) => handlePhoneInputChange(e, input.id)}
              />
            ))}
            <AddPhoneInput onSelectOption={handlePhoneOptionSelect} />
          </Box>
          <Divider />
        </>

        <TextInputSection
          inputs={customInputs}
          onInputChange={onInputChange}
          inputsPerRow={3}
        />
        <Divider />
        {!inputData && <SuccessButton type="submit">Hinzufügen</SuccessButton>}
        {inputData && <SuccessButton type="submit">Speichern</SuccessButton>}
      </Form>
    </TextFormBox>
  );
};

export default CustomerForm;
