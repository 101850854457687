import React from "react";
import styled from "styled-components";

// Styled Components
const CheckBoxContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const CheckBoxInput = styled.input`
  margin-right: 10px;
`;

const CheckBoxLabel = styled.span`
  font-size: 14px;
`;

// CheckBox Component
const CheckBox = ({ label, checked, onChange }) => {
  return (
    <CheckBoxContainer>
      <CheckBoxInput type="checkbox" checked={checked} onChange={onChange} />
      <CheckBoxLabel>{label}</CheckBoxLabel>
    </CheckBoxContainer>
  );
};

export default CheckBox;
