import axios from "axios";
import config from "../config";
const apiURL = config.apiUrl;
const apiSuffix = config.apiSuffix;
const api = axios.create({
  baseURL: apiURL,
});
export const fetchData = async (endpoint, token, compKey) => {
  try {
    console.log("Fetching data from token: " + token);
    console.log("Fetching data from endpoint: " + endpoint);
    console.log("Fetching data from compKey: " + compKey);
    const response = await api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        compkey: compKey,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const fetchDataByKey = async (endpoint, token, compKey, subKey) => {
  try {
    console.log("Fetching data from token:", token);
    console.log("Fetching data from endpoint:", endpoint);
    console.log("Fetching data from compKey:", compKey);

    const response = await api.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        compkey: compKey,
        subKey: subKey,
      },
    });

    console.log("Raw response:", response);
    console.log("Response data:", response.data);

    // Ensure response.data is an object before returning
    if (typeof response.data === "object") {
      return response.data;
    } else if (typeof response.data === "string") {
      // If the response is a string, try to parse it as JSON
      try {
        const parsedData = JSON.parse(response.data);
        console.log("Parsed response data:", parsedData);
        return parsedData;
      } catch (parseError) {
        console.error("Error parsing response JSON:", parseError);
        throw new Error("Invalid JSON response");
      }
    } else {
      console.error("Unexpected response data type:", typeof response.data);
      throw new Error("Invalid response format");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const sendData = async (endpoint, data, token, compKey) => {
  console.log("Sending data from token: " + token);
  console.log("endpoint: " + endpoint);
  console.log("Sending data to endpoint: ", data);
  try {
    const response = await api.post(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        compkey: compKey,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error sending data:", error);
    throw error;
  }
};

export const updateData = async (endpoint, token, data) => {
  console.log("Updating data from token: " + token);
  console.log("Updating data from data: " + JSON.stringify(data));
  try {
    const response = await api.put(endpoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // compKey: "1234",
      },
      body: JSON.stringify(data),
    });
    return response.data;
  } catch (error) {
    console.error("Error updating data:", error);
    throw error;
  }
};

export const updateField = async (
  endpoint,
  fieldName,
  fieldValue,
  token,
  subKey,
  compKey
) => {
  try {
    console.log(
      `Updating field at endpoint ${endpoint} with value ${fieldValue}`
    );
    console.log(`compKey ${compKey}`);
    console.log(`Subkey ${subKey}`);
    const response = await api.put(
      endpoint,
      { [fieldName]: fieldValue }, // Constructing the object with the field name and value
      {
        headers: {
          Authorization: `Bearer ${token}`,
          compkey: compKey,
          subkey: subKey,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error updating field at endpoint ${endpoint}:`, error);
    throw error;
  }
};

export const deleteData = async (endpoint, token, compKey, item) => {
  console.log("delete DATA " + JSON.stringify(item));
  try {
    const response = await api.delete(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        compkey: compKey,
        item: item,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};
