// ThemeToggleButton.js
import React from "react";
import { useTheme } from "../../provider/ThemeProvider";

const ThemeSwitcher = () => {
  const { toggleTheme } = useTheme();

  return <button onClick={toggleTheme}>Toggle Theme</button>;
};

export default ThemeSwitcher;
