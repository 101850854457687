import React, { useState, useEffect } from "react";
import { Box, DetailView } from "../../ui/Index";
import { useLocation, useNavigate } from "react-router-dom";
import {
  filterDataByTitle,
  filterDataByType,
  filterTypeProperty,
  filteredPhoneDataForUpdate,
  formatCompanyContactData,
  formatContactData,
} from "../../../utils/dataUtils";
import {
  fetchCompanyCustomerByKey,
  fetchCustomerByKey,
  updateCompanyCustomerField,
  updateSingleCustomerField,
} from "../../../services/customerService";
import CustomerForm from "../../widgets/forms/CustomerForm";

const CustomerDetails = ({ t, theme, compData, isCompany = false }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();
  const [customerFromLink, setCustomerFromLink] = useState();
  const [error, setError] = useState(null);

  const fetchdata = async (compKey, customerKey, isBusinessFromTab = false) => {
    console.log("Coming from Link: Fetching data", compKey);
    setLoading(true);
    try {
      if (isCompany) {
        console.log("IS COMPANY OR WHAT", compKey);
        const fetchedData = await fetchCompanyCustomerByKey(
          compKey,
          customerKey
        );
        console.log("Fetched company customer data", fetchedData);

        if (Array.isArray(fetchedData) && fetchedData.length > 0) {
          const formattedData = formatCompanyContactData(fetchedData);
          console.log("Formatted data from CompanyDetails", formattedData);
          setData(formattedData);
        } else {
          console.error("Invalid or empty data returned:", fetchedData);
          setError("Invalid or empty data returned.");
        }
      } else {
        const fetchedData = await fetchCustomerByKey(compKey, customerKey);
        console.log("Fetched private customer data", fetchedData);

        if (Array.isArray(fetchedData) && fetchedData.length > 0) {
          const formattedData = formatContactData(fetchedData);
          console.log("Formatted data from CustomerDetails", formattedData);
          setData(formattedData);
        } else {
          console.error("Invalid or empty data returned:", fetchedData);
          setError("Invalid or empty data returned.");
        }
      }
    } catch (err) {
      console.error(`Error fetching customer ${customerKey}:`, err.message);
      setError(`Error fetching customer ${customerKey}: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const { key, item } = location.state || {};
    console.log("location.state:", location.state); // Debug output
    console.log("item:", item); // Debug output

    if (!key || !item || typeof key !== "string" || typeof item !== "object") {
      const currentPath = window.location.pathname;
      const parts = currentPath.split("/");
      const uuid = parts[parts.length - 1];
      console.log(
        "Fetching data with compKey:",
        compData.comp_key,
        "uuid:",
        uuid,
        "isBusiness:",
        item?.isBusiness
      ); // Debug output
      fetchdata(compData.comp_key, uuid, item?.isBusiness);
    } else {
      setData(item);
    }
  }, [location.state, compData.comp_key]);

  const handleBackButtonClick = () => {
    navigate("/contact");
  };

  const handleSaveContactData = async () => {
    console.log('Save "data": ', data);
    const commonData = filterDataByType(data, "common");
    const phoneData = filterDataByType(data, "phone");
    const customInputData = filterDataByType(data, "customInput");
    const customerKey = filterDataByTitle(data, "customer_key");

    if (!customerKey || customerKey.length === 0) {
      console.error("customerKey is undefined or empty");
      return;
    }

    const customerKeyValue = customerKey[0]?.value;
    if (!customerKeyValue) {
      console.error("customerKey value is undefined");
      return;
    }

    console.log('customerKey "Key": ', customerKeyValue);
    console.log('commonData "data": ', commonData);
    console.log('phoneData "data": ', phoneData);
    console.log('customInputData "data": ', customInputData);

    if (commonData && phoneData && customInputData) {
      const salutation = commonData.find(
        (item) => item.common.value === "salutation"
      );
      const firstName = commonData.find((item) => item.title === "firstName");
      const lastName = commonData.find((item) => item.title === "lastName");
      const birthdate = commonData.find((item) => item.title === "birthdate");
      const email = commonData.find((item) => item.title === "email");
      const address = commonData.find((item) => item.title === "address");
      const subAddress = commonData.find((item) => item.title === "subAddress");
      const pCode = commonData.find((item) => item.title === "pCode");
      const city = commonData.find((item) => item.title === "city");
      const state = commonData.find((item) => item.title === "state");
      const country = commonData.find((item) => item.title === "country");
      const memberEntry = commonData.find(
        (item) => item.title === "memberEntry"
      );
      const memberExit = commonData.find((item) => item.title === "memberExit");
      const memberStatus = commonData.find(
        (item) => item.title === "memberStatus"
      );
      const notes = commonData.find((item) => item.title === "notes");

      const filteredPhoneDataResult = filteredPhoneDataForUpdate(phoneData);
      const filteredCustomInputData = filterTypeProperty(customInputData);
      console.log('filteredCustomInputData "data": ', filteredCustomInputData);

      try {
        if (salutation) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_salutation",
            salutation.value
          );
        }
        if (firstName) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_first_name",
            firstName.value
          );
        }
        if (lastName) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_last_name",
            lastName.value
          );
        }
        if (birthdate) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_birthdate",
            birthdate.value
          );
        }
        if (email) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_email",
            email.value
          );
        }
        if (address) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_address",
            address.value
          );
        }
        if (subAddress) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_sub_address",
            subAddress.value
          );
        }
        if (pCode) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_postal_code",
            pCode.value
          );
        }
        if (city) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_city",
            city.value
          );
        }
        if (state) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_state",
            state.value
          );
        }
        if (country) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_country",
            country.value
          );
        }
        if (memberEntry) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_entry_membership",
            memberEntry.value
          );
        }
        if (memberExit) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_exit_membership",
            memberExit.value
          );
        }
        if (memberStatus) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_membership_status",
            memberStatus.value
          );
        }
        if (notes) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_notes",
            notes.value
          );
        }
        if (filteredPhoneDataResult) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_phone",
            filteredPhoneDataResult
          );
        }
        if (filteredCustomInputData) {
          await updateSingleCustomerField(
            compData.comp_key,
            customerKeyValue,
            "pr_contacts_custom_inputs",
            filteredCustomInputData
          );
        }
        navigate(`/contact/${customerKeyValue}`);
      } catch (error) {
        console.error("Error updating customer data: ", error);
      }
    }
  };
  if (loading) {
    return <p>Loading...</p>; // Display a loading message or spinner when loading
  }
  return (
    <>
      {error ? (
        <p>{error}</p>
      ) : (
        <Box>
          <CustomerForm
            t={t}
            theme={theme}
            inputData={data}
            compData={compData}
            isBusiness={isCompany}
            setData={setData} // Pass the setData function to the child
          />
        </Box>
      )}
    </>
  );
};

export default CustomerDetails;
