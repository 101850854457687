// Customer.js
import React, { useEffect, useState } from "react";
import {
  deleteContactField,
  fetchAllCompContactData,
  fetchAllPrivContactData, // Ensure you have the actual function for fetching data
} from "../../../services/customerService";
import Table from "../../ui/components/Table";
import { Box } from "../../ui/Index";
import { useNavigate } from "react-router-dom";
import { ColumnBox } from "../../styles/BoxStyles";
import { useTabs } from "../../layout/context/TabContext";
import { useUser } from "../../../utils/api/provider/UserProvider";
import { useContactContext } from "../../layout/context/ContactContext";
import { contactContexts } from "../../../utils/const";
import AddCustomerModal from "../../widgets/modals/AddCustomerModal";

const Customer = ({ t, element, compData, theme }) => {
  const [loading, setLoading] = useState(false);
  const [formattedItems, setFormattedItems] = useState([]);
  const [contactData, setContactData] = useState([]);
  const [addNewCustomer, setAddNewCustomer] = useState(false);
  const tableHeaders = [
    { key: "id", label: "ID", type: "info" },
    { key: "firstname", label: "First Name", type: "string" },
    { key: "lastName", label: "Last Name", type: "string" },
    // weitere Spalten
  ];

  const navigate = useNavigate();
  const { addTab } = useTabs();
  const userData = useUser();
  const { context } = useContactContext();
  const [dataSource, setDataSource] = useState(null);

  // Data fetching functions with console logs
  const fetchCustomerListAll = async () => {
    setContactData([]);
    setFormattedItems([]);
    setLoading(true);
    try {
      const compKey = compData?.comp_key;
      if (!compKey) {
        throw new Error("Company key is not defined");
      }

      const privData = await fetchAllPrivContactData(compKey).catch((error) => {
        console.error("Error fetching private contact data:", error.message);
        return [];
      });

      const compContactData = await fetchAllCompContactData(compKey).catch(
        (error) => {
          console.error("Error fetching company contact data:", error.message);
          return [];
        }
      );

      // Add type to each contact
      const privDataWithType = privData.map((item) => ({
        ...item,
        contactType: "private",
      }));
      const compDataWithType = compContactData.map((item) => ({
        ...item,
        contactType: "company",
      }));

      const combinedData = [...privDataWithType, ...compDataWithType];

      setContactData(combinedData);
      setFormattedItems(combinedData);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    } finally {
      setLoading(false);
    }
  };
  const fetchCustomerListPriv = async () => {
    setContactData([]);
    setFormattedItems([]);
    setLoading(true);
    try {
      const privData = await fetchAllPrivContactData(compData?.comp_key);
      if (privData) {
        const privDataWithType = privData.map((item) => ({
          ...item,
          contactType: "private",
        }));
        setContactData(privDataWithType);
        setFormattedItems(privDataWithType);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchCustomerListPrivNoMember = async () => {
    setContactData([]);
    setFormattedItems([]);
    console.log("Fetching data for CUSTOMER_LIST_PRIV_NO_MEMBER context...");
    try {
      // Replace with actual data fetching logic
      const data = await fetchAllPrivContactData(/* your params here */);
      console.log("Data fetched for CUSTOMER_LIST_PRIV_NO_MEMBER:", data);
      setContactData(data);
      setFormattedItems(data);
    } catch (error) {
      console.error(
        "Error fetching CUSTOMER_LIST_PRIV_NO_MEMBER data:",
        error.message
      );
    }
  };

  const fetchCustomerListComp = async () => {
    setContactData([]);
    setFormattedItems([]);
    setLoading(true);
    try {
      const compContactData = await fetchAllCompContactData(compData?.comp_key);
      if (compContactData) {
        const compDataWithType = compContactData.map((item) => ({
          ...item,
          contactType: "company",
        }));
        setContactData(compDataWithType);
        setFormattedItems(compDataWithType);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const fetchContactData = async (context) => {
    setLoading(true);
    try {
      switch (context) {
        case contactContexts.CUSTOMER_LIST_ALL:
          setAddNewCustomer(false);
          try {
            await fetchCustomerListAll();
          } catch (error) {
            console.error(
              "Error fetching private contact data:",
              error.message
            );
            return;
          }
          break;
        case contactContexts.CUSTOMER_LIST_PRIV:
          setAddNewCustomer(false);
          try {
            await fetchCustomerListPriv();
          } catch (error) {
            console.error(
              "Error fetching private contact data:",
              error.message
            );
            return;
          }
          break;
        case contactContexts.CUSTOMER_LIST_PRIV_NO_MEMBER:
          setAddNewCustomer(false);
          try {
            await fetchCustomerListPrivNoMember();
          } catch (error) {
            console.error(
              "Error fetching private contact data:",
              error.message
            );
            return;
          }
          break;
        case contactContexts.CUSTOMER_LIST_COMP:
          setAddNewCustomer(false);
          try {
            await fetchCustomerListComp();
          } catch (error) {
            console.error(
              "Error fetching private contact data:",
              error.message
            );
            return;
          }
          break;
        case contactContexts.CUSTOMER_CREATE_NEW:
          setAddNewCustomer(true);
          return;
        default:
          console.error("Unknown context:", context);
          break;
      }
    } catch (error) {
      console.error("Error in fetchContactData:", error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactData(context);
  }, [context]);

  const reloadData = async () => {
    await fetchContactData(context);
  };

  const onItemClick = (index) => {
    const item = formattedItems[index];
    const key = item.customer_key;
    const isCompany = item.contactType === "company"; // Determine if the item is a company

    console.log("Clicked item index:", index);
    console.log("Clicked item key:", key);
    console.log("Clicked Customer:", item);
    console.log("Is Company:", isCompany);

    if (isCompany) {
      navigate(`/contact/comp/${key}`, { state: { key, item } });
    } else {
      navigate(`/contact/${key}`, { state: { key, item } });
    }
    addTab({
      id: key,
      name: `${item.firstName} ${item.lastName}`,
      isBusiness: isCompany,
    });
  };

  const onDelete = async (itemId) => {
    setLoading(true);
    console.log("Deleted item index for customer:", itemId);
    try {
      await deleteContactField(compData.comp_key, userData.id, itemId);
      if (formattedItems.length === 1) {
        window.location.reload();
      } else {
        await reloadData();
      }
    } catch (error) {
      console.error("Error deleting item:", error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div>
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <ColumnBox>
      {addNewCustomer && (
        <AddCustomerModal theme={theme} compData={compData} t={t} />
      )}
      {!addNewCustomer && (
        <Table
          headers={tableHeaders}
          data={contactData.map((item) => ({
            id: item.customer_key,
            Name: item.firstName,
            Type: item.cinput_type,
            Required: item.cinput_required === 1 ? <Box>&#10003;</Box> : "",
          }))}
          onDelete={onDelete}
          loading={loading}
          onItemClick={onItemClick}
        />
      )}
    </ColumnBox>
  );
};

export default Customer;
