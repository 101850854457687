import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchAllUserData } from "../../../services/userService";
import { fetchAllCompanyData } from "../../../services/compServices";

const UserContext = createContext();

const UserProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [compData, setCompData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log("Fetching user data...");
        const fetchedUserData = await fetchAllUserData();
        setUserData(fetchedUserData);

        // Fetch compData only if userData is available
        if (fetchedUserData) {
          console.log("Fetching company data...");
          const fetchedCompData = await fetchAllCompanyData(
            fetchedUserData.comp_key
          );
          setCompData(fetchedCompData);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setLoading(false);
        // Handle error fetching data
      }
    };

    fetchData();
  }, []);

  return (
    <UserContext.Provider value={{ userData, compData, loading }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { userData } = useContext(UserContext);
  return userData;
};

const useComp = () => {
  const { compData } = useContext(UserContext);
  return compData;
};

const useLoading = () => {
  const { loading } = useContext(UserContext);
  return loading;
};

export { UserProvider, useUser, useComp, useLoading };
