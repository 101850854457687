import React from "react";
import styled from "styled-components";

// Styled Components
const StyledIcon = styled.i`
  font-size: ${({ size }) => size}px;
  color: ${({ color }) => color};
`;

// Icon Component
const Icon = ({ size, color, children }) => {
  return (
    <StyledIcon size={size} color={color}>
      {children}
    </StyledIcon>
  );
};

export default Icon;
