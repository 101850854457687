import React, { useState } from "react";
import { Box, Divider, Typography } from "../../ui/Index";
import { AnimationProvider } from "../../provider/AnimationProvider";
import CustomerForm from "../forms/CustomerForm";
import StaticPager from "../StaticPager";

const AddCustomerModal = ({ theme, compData, t }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [showButtons, setShowButtons] = useState(true);
  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);
    if (pageIndex !== 0) {
      setShowButtons(false);
    } else {
      setShowButtons(true);
    }
  };

  // Ensure buttonNames array has both name and disabled properties
  const buttonNames = [
    { name: "", disabled: false },
    { name: "Privat", disabled: false },
    { name: "Firma", disabled: false },
  ];

  return (
    <Box
      style={{
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <AnimationProvider>
        <Typography variant={"h3"}>Add New Contact</Typography>
        <Divider />
        <StaticPager
          currentPage={currentPage}
          onPageChange={handlePageChange}
          buttonNames={buttonNames}
          showFirstButton={false}
          showAnyButton={showButtons}
        >
          <div></div>
          <CustomerForm
            t={t}
            theme={theme}
            compData={compData}
            isBusiness={false}
          />
          <CustomerForm
            t={t}
            theme={theme}
            compData={compData}
            isBusiness={true}
          />
        </StaticPager>
      </AnimationProvider>
    </Box>
  );
};

export default AddCustomerModal;
