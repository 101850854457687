import React from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 14px;
  width: 100%;
`;

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
`;

const Title = styled.span`
  font-weight: bold;
  margin-right: 8px;
`;

const Value = styled.span`
  flex-grow: 1;
`;

const EditableInput = ({ title, value, readOnly, onChange }) => {
  const handleChange = (event) => {
    onChange(event.target.value); // Pass the new value back to the parent component
  };

  if (readOnly) {
    return (
      <StyledContainer>
        <Title>{title}: </Title>
        <StyledInput
          title={title || ""}
          value={value || ""}
          onChange={handleChange}
        />
      </StyledContainer>
    );
  } else {
    return (
      <StyledContainer>
        <Title>{title}: </Title>
        <Value>{value}</Value>
      </StyledContainer>
    );
  }
};

export default EditableInput;
