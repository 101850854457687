import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Dropdown, TextInput } from "../../ui/Index";

const TextInputSection = ({
  inputs = [],
  isInputHorizontal,
  onInputChange,
  altTitle,
  inputsPerRow,
}) => {
  const [formattedInputs, setFormattedInputs] = useState([]);

  useEffect(() => {
    const initFormattedInputs = inputs.map((input) => {
      if (input.type === "date") {
        return {
          ...input,
          value: formatDateValue(input.value),
        };
      }
      return input;
    });
    setFormattedInputs(initFormattedInputs);
  }, [inputs]);

  const formatDateValue = (value) => {
    if (isNaN(Date.parse(value))) {
      return value;
    }
    const date = new Date(value);
    const timezoneOffset = date.getTimezoneOffset() * 60000; // in milliseconds
    const localDate = new Date(date.getTime() - timezoneOffset);
    return localDate.toISOString().split("T")[0];
  };

  const sliceInputsIntoRows = (inputs) => {
    const rows = [];
    for (let i = 0; i < inputs.length; i += inputsPerRow) {
      const row = inputs.slice(i, i + inputsPerRow);
      rows.push(row);
    }
    return rows;
  };

  const renderInputRow = (row, rowIndex) => (
    <Box key={rowIndex} style={{ display: "flex", marginBottom: "10px" }}>
      {row.map((input, index) => (
        <Box
          key={index}
          style={{
            flex: "1 1 0",
            minWidth: "200px", // Set a minimum width for each input box
            maxWidth: "200px", // Set a maximum width for each input box
            marginRight: index < row.length - 1 ? "10px" : "0",
          }}
        >
          {input.type === "dropdown" ? (
            <Dropdown
              title={input.title || "Select"}
              options={input.options || []}
              value={input.value}
              onChange={(selectedValue) =>
                onInputChange(
                  { target: { value: selectedValue } },
                  input.name || input.cinput_name || input.id
                )
              }
            />
          ) : (
            <TextInput
              key={index}
              isHorizontal={isInputHorizontal}
              title={!altTitle ? input.title : altTitle}
              type={input.type}
              placeholder={altTitle}
              value={input.value}
              onChange={(e) =>
                onInputChange(e, input.name || input.cinput_name || input.id)
              }
            />
          )}
        </Box>
      ))}
    </Box>
  );

  const renderInputs = (inputs) => {
    const rows = sliceInputsIntoRows(inputs);
    return rows.map((row, index) => renderInputRow(row, index));
  };

  return <>{renderInputs(formattedInputs)}</>;
};

TextInputSection.propTypes = {
  inputs: PropTypes.array,
  altTitle: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
  inputsPerRow: PropTypes.number,
  isInputHorizontal: PropTypes.bool,
};

TextInputSection.defaultProps = {
  inputs: [],
};

export default TextInputSection;
