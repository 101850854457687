import { useState, useEffect, useRef } from "react";

export const useDynamicHeight = (isOpen) => {
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    setContentHeight(isOpen ? contentRef.current.clientHeight : 0);
  }, [isOpen]);

  return { contentHeight, contentRef };
};
