import React from "react";
import styled, { keyframes } from "styled-components";
// Keyframes für die Animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled Components
const LoaderContainer = styled.div`
  display: inline-block;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;

const LoaderSpinner = styled.div`
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: ${spin} 2s linear infinite;
`;

// Loader Component
const Loader = ({ size }) => {
  return (
    <LoaderContainer size={size}>
      <LoaderSpinner />
    </LoaderContainer>
  );
};

export default Loader;
