import React, { useState, useEffect } from "react";
import { Box, Typography } from "../../ui/Index";
import { AnimationProvider } from "../../provider/AnimationProvider";
import { PDFViewer, pdf } from "@react-pdf/renderer";
import CreateDocument from "./CreateDocument";
import InvoiceForm from "../forms/InvoiceForm";
import { RowBox } from "../../styles/BoxStyles";

const AddNewDocument = ({
  theme,
  documentRecipientInputs,
  documentSenderInputs,
  compData,
}) => {
  const [invoiceData, setInvoiceData] = useState({});
  const [shouldRenderPDF, setShouldRenderPDF] = useState(false);

  useEffect(() => {
    if (Object.keys(invoiceData).length > 0) {
      setShouldRenderPDF(true);
    }
  }, [invoiceData]);

  const handleDownloadPDF = async () => {
    if (Object.keys(invoiceData).length > 0) {
      const blob = await pdf(
        <CreateDocument invoiceData={invoiceData} />
      ).toBlob();
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "invoice.pdf";
      link.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
      }}
    >
      <AnimationProvider>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            marginTop: "20px",
            gap: "20px", // Adding space between the form and PDF viewer
          }}
        >
          <InvoiceForm
            setInvoiceData={setInvoiceData}
            onDownload={handleDownloadPDF}
            documentRecipientInputs={documentRecipientInputs}
            documentSenderInputs={documentSenderInputs}
            compData={compData}
            theme={theme}
          />
          <Box
            style={{
              width: "50%",
              height: "600px", // Fixed height for consistency
              border: "1px solid black",
              overflow: "auto", // Ensure content is scrollable if it overflows
            }}
          >
            {shouldRenderPDF ? (
              <PDFViewer
                style={{
                  width: "100%",
                  height: "100%",
                }}
              >
                <CreateDocument
                  invoiceData={invoiceData}
                  documentRecipientInputs={documentRecipientInputs}
                  documentSenderInputs={documentSenderInputs}
                />
              </PDFViewer>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Box>
        </Box>
      </AnimationProvider>
    </Box>
  );
};

export default AddNewDocument;
