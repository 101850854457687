import React, { useState } from "react";
import * as IndexUI from "../ui/Index";
const ComponentList = ({ isMobile }) => {
  const tabs = [
    { title: "Tab 1", content: <div>Tab 1 Content</div> },
    { title: "Tab 2", content: <div>Tab 2 Content</div> },
    { title: "Tab 3", content: <div>Tab 3 Content</div> },
  ];
  const options = ["Option 1", "Option 2", "Option 3"];

  const [isChecked, setIsChecked] = useState(false);
  const [isBoxChecked, setIsBoxChecked] = useState(false);

  const handleCheckBoxChange = (event) => {
    setIsChecked(event.target.checked);
  };
  const [selectedOption, setSelectedOption] = useState("option1");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSelect = (option) => {
    console.log("Selected:", option);
  };
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleItemClick = (item) => {
    console.log("Clicked on:", item);
  };
  return (
    <div>
      <IndexUI.ThemeSwitcher />
      <IndexUI.Accordion title="Accordion Title 1">
        <p>Accordion Content 1</p>
      </IndexUI.Accordion>
      <IndexUI.Accordion title="Accordion Title 2">
        <p>Accordion Content 2</p>
      </IndexUI.Accordion>
      <IndexUI.Avatar imageUrl="url_zum_bild" name="Max Mustermann" size={50} />
      <IndexUI.BreadCrumb
        items={["Home", "Products", "Electronics"]}
        onItemClick={handleItemClick}
      />
      <IndexUI.Button variant={"info"}>info</IndexUI.Button>
      <IndexUI.Button variant={"success"}>success</IndexUI.Button>
      <IndexUI.Button variant={"warning"}>warning</IndexUI.Button>
      <IndexUI.Button variant={"danger"}>danger</IndexUI.Button>
      <IndexUI.Button variant={"disabled"}>disabled</IndexUI.Button>
      <IndexUI.Button variant={"link"}>link</IndexUI.Button>
      <IndexUI.Card
        title="Card Title"
        content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
      />
      <IndexUI.Checkbox
        label="Checkbox Label"
        checked={isBoxChecked}
        onChange={handleCheckBoxChange}
      />
      <IndexUI.DatePicker />
      <IndexUI.Divider />
      <IndexUI.Dropdown options={options} onSelect={handleSelect} />
      <IndexUI.Icon className="fas fa-home" size={24} color="blue" />
      <IndexUI.Loader size={50} />
      <button onClick={openModal}>Open Modal</button>
      <IndexUI.Modal isOpen={isModalOpen} onClose={closeModal}>
        <h2>Modal Content</h2>
        <p>This is the content of the modal.</p>
      </IndexUI.Modal>

      <IndexUI.Progress value={50} width={300} />
      <IndexUI.RadioButton
        label="Option 1"
        value="option1"
        checked={selectedOption === "option1"}
        onChange={handleOptionChange}
      />
      <IndexUI.RadioButton
        label="Option 2"
        value="option2"
        checked={selectedOption === "option2"}
        onChange={handleOptionChange}
      />
      <IndexUI.Switch checked={isChecked} />
      <IndexUI.Tabs tabs={tabs} />
      <IndexUI.TextInput />
      <IndexUI.TimePicker />
      <IndexUI.Tooltip text="This is a tooltip">
        <button>Hover me</button>
      </IndexUI.Tooltip>
    </div>
  );
};

export default ComponentList;
