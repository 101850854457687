import React, { createContext, useState, useContext } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [context, setContext] = useState("");

  const changeModalContext = (newContext) => {
    if (newContext === "") {
      setTimeout(() => {
        setContext(newContext);
      }, 300);
    } else {
      setContext(newContext);
    }
  };

  const reloadModalPage = () => {
    setTimeout(() => {
      window.location.reload();
    }, 300);
  };

  return (
    <ModalContext.Provider
      value={{ context, changeModalContext, reloadModalPage }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => {
  return useContext(ModalContext);
};

export default ModalContext;
