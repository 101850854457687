import React, { useState, useEffect } from "react";
import { Navigate, Outlet } from "react-router-dom";
import checkAuth from "../../utils/CheckAuth";
const PrivateRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAuthentication = async () => {
      const authenticated = await checkAuth();
      setIsAuthenticated(authenticated);
      setLoading(false);
    };

    fetchAuthentication();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/auth" />;
};

export default PrivateRoute;
