import React, { useState, useEffect } from "react";
import {
  fetchInvoiceNumbers,
  updateInvoiceNumberSettings,
} from "../../../services/docsService";
import BatchInvoiceCreation from "../../widgets/pdf/BatchInvoiceCreation";

const InvoiceNumberSettings = ({ compData }) => {
  const [prefix, setPrefix] = useState("");
  const [suffix, setSuffix] = useState("");
  const [lastUsedInvoiceNumber, setLastUsedInvoiceNumber] = useState(""); // State for lastUsedInvoiceNumber
  const [updatedAt, setUpdatedAt] = useState(""); // State for updated_at date
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const response = await fetchInvoiceNumbers(compData?.comp_key);
        const { prefix, suffix, lastUsedInvoiceNumber, updatedAt } = response;
        setPrefix(prefix);
        setSuffix(suffix);
        setLastUsedInvoiceNumber(lastUsedInvoiceNumber); // Set the last used invoice number
        setUpdatedAt(new Date(updatedAt).toLocaleString()); // Format the date
      } catch (err) {
        console.error("Fehler beim Laden der Einstellungen:", err);
        setError("Fehler beim Laden der Einstellungen.");
      }
    };

    fetchSettings();
  }, []);

  const handleSave = async () => {
    try {
      // Check if inputs are valid
      if (!prefix || !suffix || !lastUsedInvoiceNumber) {
        setError("Alle Felder müssen ausgefüllt werden.");
        return;
      }

      // Make API request to save settings
      await updateInvoiceNumberSettings(
        compData?.comp_key,
        prefix,
        suffix,
        lastUsedInvoiceNumber
      );

      setSuccess("Einstellungen erfolgreich gespeichert.");
      setError(null);
      setUpdatedAt(new Date().toLocaleString()); // Update the local time
    } catch (err) {
      console.error("Fehler beim Speichern:", err);
      setError("Fehler beim Speichern der Einstellungen.");
    }
  };

  return (
    <div>
      <h2>Rechnungsnummern-Einstellungen</h2>
      <BatchInvoiceCreation />
      <span>
        Vorschau: {prefix}
        {lastUsedInvoiceNumber}
        {suffix}
      </span>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {success && <p style={{ color: "green" }}>{success}</p>}
      <div>
        <label>
          Prefix:
          <input
            type="text"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Suffix:
          <input
            type="text"
            value={suffix}
            onChange={(e) => setSuffix(e.target.value)}
          />
        </label>
      </div>
      <div>
        <label>
          Last Used Invoice Number:
          <input
            type="text"
            value={lastUsedInvoiceNumber}
            onChange={(e) => setLastUsedInvoiceNumber(e.target.value)}
          />
        </label>
      </div>
      <div>
        <p>Zuletzt aktualisiert: {updatedAt}</p>{" "}
        {/* Display the updated_at date */}
      </div>
      <button onClick={handleSave}>Speichern</button>
    </div>
  );
};

export default InvoiceNumberSettings;
