import React, { useState } from "react";
import { Box } from "../../ui/Index";
import { AddButton } from "../../styles/ButtonStyles";
import { DropdownItem } from "../../ui/components/Dropdown";
import { v4 as uuid } from "uuid"; // Import uuid for generating unique IDs

const AddPhoneInput = ({ onSelectOption }) => {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ x: 0, y: 0 });
  const options = ["Private", "Business"];

  const handleButtonClick = (event) => {
    event.preventDefault();
    const buttonRect = event.target.getBoundingClientRect();
    setDropdownPosition({ x: buttonRect.left, y: buttonRect.bottom });
    setDropdownVisible(true);
  };

  const handleOptionClick = (option) => {
    const id = uuid(); // Generate unique ID for the phone number
    onSelectOption(option, id); // Pass the selected option and ID to the parent component
    setDropdownVisible(false); // Close the dropdown after selecting an option
  };

  return (
    <Box style={{ position: "relative" }}>
      <form>
        <AddButton type="button" onClick={handleButtonClick}>
          Add Phone Input
        </AddButton>
      </form>
      {dropdownVisible && (
        <div
          style={{
            position: "relative",
            top: "0",
            left: "0",
            backgroundColor: "#ffffff",
            border: "1px solid #ccc",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
            zIndex: 999,
          }}
        >
          {options.map((option, index) => (
            <DropdownItem key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </DropdownItem>
          ))}
        </div>
      )}
    </Box>
  );
};

export default AddPhoneInput;
