import React from "react";
import styled from "styled-components";
import EditableInput from "./EditableInput";
import TextInputSection from "../../widgets/forms/TextInputSection";

const TableRow = styled.tr``;
const TableCell = styled.td``;
const BackButton = styled.button``;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
`;

const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.span`
  font-size: 1.2rem;
  font-weight: bold;
`;

const ValueInput = styled.input`
  font-size: 1rem;
  padding: 8px;
  border: 1px solid #ccc;
  background-color: rgba(0, 0, 50, 0.5); /* Semi-transparent dark blue */
  color: #fff; /* White text color */
  pointer-events: none; /* Prevents interaction */
`;

const DetailView = ({
  data,
  setData,
  editMode,
  headers,
  handleBackButtonClick,
  handleEditButtonClick,
}) => {
  const handleInputChange = (e, key) => {
    const newValue = e.target.value;
    setData({
      ...data,
      [key]: {
        ...data[key],
        value: newValue,
      },
    });
  };

  // Ensure data is defined and not null before accessing its keys
  const formattedData = data
    ? Object.keys(data).map((key) => ({
        ...data[key],
        name: key,
      }))
    : [];

  return (
    <>
      {data && (
        <TableRow>
          <TableCell colSpan={2}>
            <GridWrapper>
              {Object.keys(data).map((key, index) => (
                <GridItem key={index}>
                  <EditableInput
                    type="text"
                    title={data[key]?.title}
                    value={data[key]?.value !== null ? data[key]?.value : ""}
                    readOnly={!editMode} // Set readOnly based on editMode
                    onChange={(newValue) =>
                      handleInputChange({ target: { value: newValue } }, key)
                    }
                  />
                </GridItem>
              ))}
            </GridWrapper>
            <BackButton onClick={handleBackButtonClick}>Back</BackButton>
          </TableCell>
        </TableRow>
      )}
      <button onClick={handleEditButtonClick}>
        {editMode ? "Save" : "Edit"}
      </button>
    </>
  );
};

export default DetailView;
