import {
  deleteData,
  fetchData,
  fetchDataByKey,
  sendData,
  updateField,
} from "../utils/Api";
import { getToken } from "../utils/const";
import { linkConfig } from "../config";

export const fetchAllPrivContactData = async (compKey) => {
  try {
    console.log("Fetching all PRIV Customer data" + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    const endpointPriv = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${linkConfig.apiCustomer}/${userId}`;
    const allPrivData = await fetchData(endpointPriv, userToken, compKey);

    return allPrivData;
  } catch (error) {
    console.error("Error fetching all user data:", error.message);
    throw error;
  }
};
export const fetchAllCompContactData = async (compKey) => {
  try {
    console.log("Fetching all COMP Customer data" + compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    const endpointComp = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${linkConfig.apiCustomer}/${linkConfig.apiContactCompany}/${userId}`;
    const allCompData = await fetchData(endpointComp, userToken, compKey);
    return allCompData;
  } catch (error) {
    console.error("Error fetching all user data:", error.message);
    throw error;
  }
};

export const fetchCustomerByKey = async (compKey, customerKey) => {
  try {
    console.log("Fetching Customer by Key:", compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/${userId}/${customerKey}`;
    const allContactData = await fetchDataByKey(
      endpoint,
      userToken,
      compKey,
      customerKey
    );
    console.log("Fetched Customer by Key:", allContactData);

    // Ensure allContactData is an object before returning
    if (typeof allContactData === "object") {
      return allContactData;
    } else {
      console.error("Fetched customer data is not an object:", allContactData);
      throw new Error("Invalid data format");
    }
  } catch (error) {
    console.error("Error fetching all user data:", error.message);
    throw error;
  }
};

export const fetchCompanyCustomerByKey = async (compKey, customerKey) => {
  try {
    console.log("Fetching Company Customer by Key:", compKey);
    const userToken = getToken();
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;

    // Construct the endpoint URL for company customer data
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/company/${userId}/${customerKey}`;

    // Fetch the customer data using the constructed endpoint
    const allContactData = await fetchDataByKey(
      endpoint,
      userToken,
      compKey,
      customerKey
    );
    console.log("Fetched Company Customer by Key:", allContactData);

    // Ensure allContactData is an object before returning
    if (typeof allContactData === "object") {
      return allContactData;
    } else {
      console.error("Fetched customer data is not an object:", allContactData);
      throw new Error("Invalid data format");
    }
  } catch (error) {
    console.error("Error fetching company customer data:", error.message);
    throw error;
  }
};

// Function to fetch a single field
const fetchContactDataField = async (compKey, fieldName) => {
  try {
    console.log(`Fetching Comp ${fieldName}`);
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/comp-data/${compKey}/${fieldName}`;
    const response = await fetchData(endpoint, userToken);

    // Extract the specific field value from the response
    const fieldData = response[fieldName];

    return fieldData;
  } catch (err) {
    console.error(`Error fetching user ${fieldName}:`, err.message);
    throw err;
  }
};

export const addNewContact = async (compKey, contactData) => {
  console.log("Fetching all Comp data" + compKey);
  console.log("addNewContact", contactData);
  console.log("contactData isBusiness ? ", contactData.isBusiness);

  const userToken = getToken();
  if (!userToken) {
    throw new Error("User token not found");
  }
  const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
  const userId = tokenPayload.usr_id;
  try {
    if (!contactData.isBusiness) {
      await sendData(
        `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/${userId}`,
        contactData,
        userToken,
        compKey
      );
    } else {
      await sendData(
        `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/company/${userId}`,
        contactData,
        userToken,
        compKey
      );
    }
  } catch (error) {
    if (error.response && error.response.status === 400) {
      throw new Error("already exists.");
    } else {
      throw error;
    }
  }
};
export const updateSingleCustomerField = async (
  compKey,
  customerKey,
  fieldName,
  fieldValue
) => {
  try {
    console.log(
      `Updating Customer ${customerKey} from company ${compKey}, field: ${fieldName}, value: ${fieldValue}`
    );
    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/${userId}/${fieldName}`;

    const response = await updateField(
      endpoint,
      fieldName,
      fieldValue,
      userToken,
      customerKey,
      compKey
    );

    return response; // Assuming response contains updated Customer data
  } catch (err) {
    console.error(
      `Error updating ${fieldName} for Customer ${compKey}:`,
      err.message
    );
    throw err;
  }
};
export const updateCompanyCustomerField = async (
  compKey,
  customerKey,
  fieldName,
  fieldValue
) => {
  try {
    console.log(
      `Updating Company Customer ${customerKey} for company ${compKey}, field: ${fieldName}, value: ${fieldValue}`
    );

    const userToken = getToken(); // Get the token from local storage
    if (!userToken) {
      throw new Error("User token not found");
    }

    const tokenPayload = JSON.parse(atob(userToken.split(".")[1]));
    const userId = tokenPayload.usr_id;
    const endpoint = `${linkConfig.apiUrl}${linkConfig.apiSuffix}/customer-data/company/${userId}/${fieldName}`;

    // Update the field using the company-specific endpoint
    const response = await updateField(
      endpoint,
      fieldName,
      fieldValue,
      userToken,
      customerKey,
      compKey
    );

    return response; // Assuming response contains updated Customer data
  } catch (err) {
    console.error(
      `Error updating ${fieldName} for Company Customer ${customerKey}:`,
      err.message
    );
    throw err;
  }
};
export const deleteContactField = async (compKey, userID, customerKey) => {
  console.log("deleteContactField : " + customerKey);
  const userToken = getToken();
  if (!userToken) {
    throw new Error("User token not found");
  }

  try {
    await deleteData(
      `${linkConfig.apiUrl}${linkConfig.apiSuffix}/${linkConfig.apiCustomer}/${userID}/${customerKey}`,
      userToken,
      compKey,
      customerKey
    );
  } catch (error) {
    throw new Error("Error deleting custom field");
  }
};
// Functions to fetch individual fields
export const getCompEmail = async () => {
  return fetchContactDataField("email");
};
