import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTabs } from "../layout/context/TabContext";

const TabContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  background-color: #f5f5f5;
  overflow-x: auto;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px 15px;
  background-color: ${(props) => (props.isActive ? "#e0e0e0" : "white")};
  border-radius: 8px 8px 0 0;
  border: 1px solid #ccc;
  border-bottom: none;
  cursor: pointer;
  transition: background-color 0.2s, box-shadow 0.2s;

  &:hover {
    background-color: #e0e0e0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
`;

const TabName = styled.span`
  margin-right: 10px;
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: #999;
  transition: color 0.2s;

  &:hover {
    color: #000;
  }
`;

const CloseAllButton = styled.button`
  background: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  margin-left: auto;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #d32f2f;
  }
`;

const LoadingOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #333;
  z-index: 9999;
`;

const ContextTab = () => {
  const { tabs, removeTab, removeAllTabs } = useTabs();
  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleTabClick = (key, item) => {
    setIsLoading(true);
    console.log(item.isBusiness ? "TAB CLICK COMPANY" : "TAB CLICK USER", item);
    navigate(item.isBusiness ? `/contact/comp/${key}` : `/contact/${key}`, {
      state: { key, item },
    });
    setActiveTabId(key);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  return (
    <>
      {isLoading && <LoadingOverlay>Loading...</LoadingOverlay>}
      <TabContainer>
        {tabs.length === 0 ? (
          <div></div>
        ) : (
          <>
            {tabs.map((tab) => (
              <Tab
                key={tab.id}
                isActive={activeTabId === tab.id}
                onClick={() => handleTabClick(tab.id, tab)}
              >
                <TabName>{tab.name}</TabName>
                <CloseButton
                  onClick={(e) => {
                    e.stopPropagation();
                    removeTab(tab.id);
                  }}
                >
                  x
                </CloseButton>
              </Tab>
            ))}
            <CloseAllButton onClick={removeAllTabs}>
              Close All Tabs
            </CloseAllButton>
          </>
        )}
      </TabContainer>
    </>
  );
};

export default ContextTab;
