import React, { useEffect, useState } from "react";
import Table from "../../ui/components/Table";
import { AddButton } from "../../styles/ButtonStyles";
import { Box } from "../../ui/Index";
import {
  addMemberState,
  fetchAllMemberStates,
} from "../../../services/compServices";

const tableHeaders = [
  { key: "id", label: "ID", type: "info" },
  { key: "title", label: "Title", type: "string" },
  { key: "isMember", label: "Considered as member", type: "boolean" },
  // weitere Spalten
];

const MemberStates = ({ compData }) => {
  const [loading, setLoading] = useState(true);
  const [memberStates, setMemberStates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchAllMemberStates(compData?.comp_key);
        setMemberStates(data);
        console.log("setMemberStates", data);
      } catch (error) {
        console.error("Error fetching member states data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [compData]);

  const onItemDelete = () => {
    console.log("onItemDelete");
  };

  const onItemClick = () => {
    console.log("onItemClick");
  };

  const onAddItem = async (item) => {
    console.log("onAddItem", item);
    try {
      // Add item to the database
      await addMemberState(compData?.comp_key, item.title, item.isMember);
    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  const tableData = memberStates.map((state) => ({
    id: state.state_id,
    title: state.state_name,
    isMember: state.state_is_member ? "Yes" : "No",
  }));

  return (
    <Box>
      <Table
        headers={tableHeaders}
        data={tableData}
        onDelete={onItemDelete}
        loading={loading}
        onItemClick={onItemClick}
        onAddItem={onAddItem}
      />
    </Box>
  );
};

export default MemberStates;
