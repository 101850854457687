// searchUtils.js
const handleSearch = (items, query, setFilteredItems) => {
  // Implement your filtering logic here
  // For now, let's filter items based on text, secondaryText, and mainPrice
  const newFilteredItems = items.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === "string" &&
        value.toLowerCase().includes(query.toLowerCase())
    )
  );
  setFilteredItems(newFilteredItems);
};

export default handleSearch;
