import React, { useState } from "react";
import Button from "./Button";
import Box from "./Box";
import {
  CancelButton,
  CloseButton,
  DelButton,
  SuccessButton,
} from "../../styles/ButtonStyles";

const DeleteButton = ({ item, onDelete }) => {
  const [confirmation, setConfirmation] = useState(false);

  const onClick = () => {
    console.log("Ask for Delete item: " + JSON.stringify(item));
    setConfirmation(true);
  };

  const onConfirm = () => {
    console.log("Confirm Delete item: " + JSON.stringify(item));
    onDelete(item);
    setConfirmation(false);
  };

  const onCancel = () => {
    console.log("Cancel Delete item: " + JSON.stringify(item));
    setConfirmation(false);
  };

  return (
    <Box>
      {!confirmation && <Button onClick={onClick}>Delete</Button>}
      {confirmation && (
        <Box>
          <DelButton onClick={onConfirm}>Delete</DelButton>
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
        </Box>
      )}
    </Box>
  );
};

export default DeleteButton;
