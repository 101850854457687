import React, { useState } from "react";
import StructureSection from "../../widgets/structures/StructureSection";
import StructureItem from "../../widgets/structures/StructureItem";
import { Dropdown, PageBox } from "../../ui/Index";
import BaseData from "../../ui/components/BaseData";

const SettingsDash = ({ theme, compData }) => {
  const [darkMode, setDarkMode] = useState(false);
  const [emailNotifications, setEmailNotifications] = useState(true);

  return (
    <PageBox theme={theme}>
      <h1>Settings</h1>
      <StructureSection title="Own Company">
        <BaseData compData={compData} />
      </StructureSection>

      <StructureSection title="Bills Defaults">
        <StructureItem label="Umsatzsteuer">
          <Dropdown
            title="Umsatzsteuerpflicht"
            options={["0%", "7%", "19%"]}
            value={"Dsdada"}
            onChange={"handleUmsatzsteuerChange"}
          />
        </StructureItem>
      </StructureSection>
    </PageBox>
  );
};

export default SettingsDash;
