import { dimsMobile, dimsDesktop } from "../../constants/Dimensions";
import { defaultTransitions } from "../../utils/const";
//Reload manually if Change
export const CardStyles = (theme, isMobile) => {
  const Colors = theme.colors;
  const Spacing = theme.spacing;
  const dims = isMobile ? dimsMobile : dimsDesktop;
  const transitions = defaultTransitions(theme);

  const cardStyle = {
    position: "relative",
    height: "auto",
    width: "auto",
    backgroundColor: Colors.onPrimary,
    padding: Spacing.large,
    borderRadius: `${theme.radius.default} ${theme.radius.default} ${theme.radius.default} ${theme.radius.default}`,
    overflow: "hidden",
    transition: transitions,
  };

  const LoginRegisterCardStyle = {
    ...cardStyle,
  };

  return {
    cardStyle,
    LoginRegisterCardStyle,
  };
};
