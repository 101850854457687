import React from "react";
import PropTypes from "prop-types";
import { TypographyStyles } from "../../styles/TypographyStyles";
const Typography = ({ variant, children, style = {}, onClick }) => {
  // Retrieve the styles based on the variant prop
  const variantStyle = TypographyStyles[variant] || {}; // Default to an empty object if variant is not found

  // Merge the provided inline styles with the variant styles
  const typographyStyle = { ...style, ...variantStyle };

  return (
    <span onClick={onClick} style={typographyStyle}>
      {children}
    </span>
  );
};

export default Typography;
