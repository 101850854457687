import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const TabsContainer = styled.div`
  display: flex;
`;

const TabButton = styled.button`
  padding: 10px 20px;
  background-color: ${({ isActive }) => (isActive ? "#007bff" : "#ccc")};
  color: ${({ isActive }) => (isActive ? "#fff" : "#333")};
  border: none;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  outline: none;
`;

const TabContent = styled.div`
  border: 1px solid #ccc;
  border-top: none;
  padding: 20px;
  border-radius: 0 0 4px 4px;
`;

// Tabs Component
const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <div>
      <TabsContainer>
        {tabs.map((tab, index) => (
          <TabButton
            key={index}
            isActive={index === activeTab}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </TabButton>
        ))}
      </TabsContainer>
      <TabContent>{tabs[activeTab].content}</TabContent>
    </div>
  );
};

export default Tabs;
