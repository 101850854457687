import React from "react";
import styled from "styled-components";
import { Typography } from "../../ui/Index";

const SectionContainer = styled.div`
  margin-bottom: 30px;
`;

const SectionTitle = styled.h2`
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const StructureSection = ({ title, children }) => {
  return (
    <SectionContainer>
      <Typography variant={"span"}> {children}</Typography>
    </SectionContainer>
  );
};

export default StructureSection;
