import React, { useEffect } from "react";
import { Box, Typography, Button } from "../../ui/Index";
import handleSearch from "../../../utils/searchUtils";
import SearchField from "../../ui/components/SearchField";

const SearchAndSelectModal = ({
  theme,
  templates,
  handleTemplateSelection,
  setShowTemplateSelector,
  displayFields = [], // This should be an array of strings representing the fields
}) => {
  const [filteredItems, setFilteredItems] = React.useState(templates); // Initialize with templates
  useEffect(() => {
    if (templates && templates.length > 0) {
      setFilteredItems(templates); // Initialize with templates once available
    }
  }, [templates]);
  const handleSearchWrapper = (query) => {
    handleSearch(templates, query, setFilteredItems); // Filter templates based on the query
  };

  return (
    <Box
      style={{
        position: "fixed",
        top: "20%",
        left: "20%",
        width: "60%",
        backgroundColor: `${theme.colors.onSurface}`,
        boxShadow: "0 0 10px rgba(0,0,0,0.3)",
        padding: "20px",
        zIndex: 1000,
      }}
    >
      <Typography variant="h4">Select a Template</Typography>
      <Box>
        <SearchField onSearch={handleSearchWrapper} />{" "}
        {/* Pass the wrapper function */}
        {filteredItems && filteredItems.length > 0 ? (
          filteredItems.map((template) => (
            <Box
              key={template.pos_id || template.contact_id} // Ensure a unique key
              style={{
                border: "1px solid #ccc",
                padding: "10px",
                margin: "10px 0",
                cursor: "pointer",
              }}
              onClick={() => handleTemplateSelection(template)}
            >
              {displayFields.map((field, index) => (
                <Typography key={index} variant="body1">
                  {template[field]} {/* Display the specified fields */}
                </Typography>
              ))}
            </Box>
          ))
        ) : (
          <Typography variant="body1">No templates available</Typography>
        )}
      </Box>
      <Button
        onClick={() => setShowTemplateSelector(false)}
        style={{ marginTop: "20px" }}
      >
        Close
      </Button>
    </Box>
  );
};

export default SearchAndSelectModal;
