import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useModalContext } from "../layout/context/ModalContext";
import { dimsDesktop, dimsMobile } from "../../constants/Dimensions";
import { useSidebarContext } from "../layout/context/SidebarContext";
import { MenuButton, SubMenuButton } from "../styles/ButtonStyles";

const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MenuItem = styled.div`
  position: relative;
`;

const Menu = ({ theme, isMobile }) => {
  const [openMenus, setOpenMenus] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const { changeModalContext } = useModalContext();
  const dims = isMobile ? dimsMobile : dimsDesktop;
  const { changeSidebarContext } = useSidebarContext();

  const toggleMenu = (menuName) => {
    setOpenMenus((prevMenus) => ({
      ...prevMenus,
      [menuName]: !prevMenus[menuName],
    }));
  };

  const handleSubMenuClick = (menuName, to) => {
    console.log(`Clicked in ${menuName} to ${to}`);
    if (menuName === "newDoc") {
      changeModalContext("newDoc");
    } else if (menuName === "newCustomer") {
      changeModalContext("newCustomer");
    } else {
      navigate(to);
    }
  };

  const menuData = {
    documents: {
      onClick: () => {
        handleSubMenuClick("docs", "/docs");
        changeSidebarContext("docs");
      },
      path: "/docs",
      items: [],
    },
    customer: {
      onClick: () => {
        handleSubMenuClick("customerList", "/contact");
        changeSidebarContext("contacts");
      },
      path: "/contact",
      items: [],
    },
    settings: {
      onClick: () => {
        handleSubMenuClick("settings", "/settings");
        changeSidebarContext("settings");
      },
      path: "/settings",
      items: [],
    },
  };

  return (
    <MenuContainer>
      {Object.entries(menuData).map(([menuName, menuInfo]) => {
        const isActive = location.pathname === menuInfo.path;

        return (
          <MenuItem key={menuName}>
            <MenuButton
              theme={theme}
              isActive={isActive}
              style={{
                width: `${dims.sidebarWidth}`,
              }}
              onClick={() => {
                toggleMenu(menuName);
                menuInfo.onClick();
              }}
            >
              {t(`menu.${menuName}`)}
            </MenuButton>
          </MenuItem>
        );
      })}
    </MenuContainer>
  );
};

export default Menu;
