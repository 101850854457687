import React from "react";
import styled from "styled-components";

// Styled Components
const SwitchContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SwitchInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const SwitchSlider = styled.span`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  background-color: #ccc;
  border-radius: 20px;
  transition: background-color 0.3s ease;

  &::before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 2px;
    bottom: 2px;
    background-color: #fff;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  ${SwitchInput}:checked + & {
    background-color: #66bb6a;
  }

  ${SwitchInput}:checked + &::before {
    transform: translateX(20px);
  }
`;

// Switch Component
const Switch = ({ checked, onChange }) => {
  return (
    <SwitchContainer>
      <SwitchInput type="checkbox" checked={checked} onChange={onChange} />
      <SwitchSlider />
    </SwitchContainer>
  );
};

export default Switch;
