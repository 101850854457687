// UserCompanyForm.js

import React, { useState } from "react";
import {
  Button,
  TextInput,
  Form,
  Divider,
  Typography,
  Accordion,
  Box,
} from "../../ui/Index";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { useNavigate } from "react-router-dom";
import CheckBox from "../../ui/components/CheckBox";
import { useModalContext } from "../../layout/context/ModalContext";
import { updateSingleCompanyData } from "../../../services/compServices";
import { updateData } from "../../../utils/Api";
import {
  updateSingleUserData,
  updateUserDataField,
} from "../../../services/userService";
import { SuccessButton } from "../../styles/ButtonStyles";

const UserCompanyForm = ({
  theme,
  isMobile,
  setIsCompDataValid,
  userData,
  compData,
}) => {
  const { changeModalContext, reloadModalPage } = useModalContext();

  const [addBaseInfoLater, setAddBaseInfoLater] = useState(false);
  const [canGoNext, setCanGoNext] = useState(false);
  const [requiredFields, setRequiredFields] = useState([
    "Company Name",
    "Company Street",
  ]);
  const [email, setEmail] = useState("");
  const [fields, setFields] = useState({
    "Company Name": "",
    "Company Street": "",
    "Company Post Code": "",
    "Company State": "",
    "Company Country": "",
    "Company Vat Code": "",
  });

  const [currentPage, setCurrentPage] = useState(0);
  const [error, setError] = useState("");
  const { formStyle } = LayoutStyles(theme, isMobile);
  const navigate = useNavigate();

  const handleChange = (e, field) => {
    const { value } = e.target;

    setFields((prevFields) => ({
      ...prevFields,
      [field]: value,
    }));
  };

  const updateCompData = async () => {
    console.log("updateCompData");

    try {
      console.log("updateCompanyName userData" + userData.comp_key);
      if (fields["Company Name"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_name",
          fields["Company Name"]
        );
      }

      if (fields["Company Street"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_street",
          fields["Company Street"]
        );
      }

      if (fields["Company Post Code"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_pcode",
          fields["Company Post Code"]
        );
      }

      if (fields["Company State"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_state",
          fields["Company State"]
        );
      }

      if (fields["Company Country"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_country",
          fields["Company Country"]
        );
      }

      if (fields["Company Vat Code"]) {
        await updateSingleCompanyData(
          compData.comp_key,
          "comp_vat",
          fields["Company Vat Code"]
        );
      }
      console.log("userData.usr_id" + userData.usr_id);
      await updateUserDataField(userData.usr_id, "usr_first_time", 0);
    } catch (err) {
      console.log(err);
      setError(err.message || "An error occurred.");
      return;
    } finally {
      changeModalContext("");
      reloadModalPage();
    }
  };

  const onCheckboxChange = (e) => {
    const altReqFields = ["Company Name"];
    if (!addBaseInfoLater) {
      setRequiredFields(altReqFields);
    } else {
      setRequiredFields(["Company Name", "Company Street"]);
    }
    setAddBaseInfoLater(!addBaseInfoLater);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const missingFields = requiredFields.filter((field) => !fields[field]);
    if (missingFields.length > 0) {
      setError("Please fill in all required fields.");
      setCanGoNext(false);
      setIsCompDataValid(false);
    } else {
      setError("");
      setCanGoNext(true);
      setIsCompDataValid(true);
      updateCompData();
    }
  };

  const fieldSetsPerPage = ["Company Name"];

  const fieldSetCompanyBaseData = [
    "Company Street",
    "Company Post Code",
    "Company State",
    "Company Country",
    "Company Vat Code",
  ];

  const handleInputChange = (e, field) => {
    const { value } = e.target;
    handleChange({ target: { value } }, field);
  };

  const handleCompanyPostCodeChange = (e) => {
    // Restrict input to only numeric characters
    const value = e.target.value.replace(/\D/g, "");
    handleChange({ target: { value } }, "Company Post Code");
  };

  return (
    <Form style={{ ...formStyle }} onSubmit={handleSubmit}>
      {fieldSetsPerPage.map((fieldName, index) => (
        <TextInput
          key={index}
          type={"text"}
          placeholder={fieldName}
          error={requiredFields.includes(fieldName) && error}
          value={fields[fieldName]}
          onChange={(e) => handleInputChange(e, fieldName)}
        />
      ))}
      <Divider />
      {currentPage === 0 && (
        <Box>
          {fieldSetCompanyBaseData.map((fieldName, index) => (
            <TextInput
              key={index}
              type={fieldName === "Company Post Code" ? "number" : "text"}
              placeholder={fieldName}
              error={requiredFields.includes(fieldName) && error}
              value={fields[fieldName]}
              disabled={addBaseInfoLater}
              onChange={
                fieldName === "Company Post Code"
                  ? handleCompanyPostCodeChange
                  : (e) => handleInputChange(e, fieldName)
              }
            />
          ))}
        </Box>
      )}

      <CheckBox
        label={"Add Base Info Later"}
        checked={addBaseInfoLater}
        onChange={onCheckboxChange}
      ></CheckBox>
      {currentPage === fieldSetsPerPage.length - 1 && (
        <SuccessButton type="submit">Speichern</SuccessButton>
      )}
    </Form>
  );
};

export default UserCompanyForm;
