// ButtonStyles.js
import styled from "styled-components";
import { defaultTransitions } from "../../utils/const";
const DefaultBox = styled.div`
  ${({ theme }) => `
    color: ${theme.colors.primaryText};

    transition: ${defaultTransitions(theme)};

  
  `}
`;

export const RowBox = styled(DefaultBox)`
  ${({ theme }) => `
    display: flex;
    flex-direction: row;
    border: none;
  `}
`;

export const ColumnBox = styled(DefaultBox)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
    border: none;
  `}
`;

export const TextFormBox = styled(DefaultBox)`
  ${({ theme }) => `
  display: flex;
  flex-direction: column;
padding: 20px;
border: 1px solid #ddd;
border-radius: 10px;
background-color:${theme.colors.onSurface} ;
box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`}
`;
