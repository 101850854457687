import { dimsDesktop, dimsMobile } from "../../../constants/Dimensions";
import { defaultTransitions } from "../../../utils/const";
//Reload manually if Change
export const LayoutStyles = (theme, isMobile) => {
  const Colors = theme.colors;
  const Spacing = theme.spacing;
  const dims = isMobile ? dimsMobile : dimsDesktop;
  const transitions = defaultTransitions(theme);
  const contentBoxStyle = {
    position: "relative",
    width: "100%",
    marginLeft: Spacing.small,
    marginRight: Spacing.small,
    backgroundColor: theme.colors.surface,
    borderRadius: `${theme.radius.default} ${theme.radius.default} ${theme.radius.default} ${theme.radius.default}`,
    overflow: "hidden",
    padding: ` ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small} ${theme.spacing.small}`,
    transition: transitions,
  };

  const navbarStyles = {
    display: "flex",
    justifyContent: "space-between",
    height: dims.NavBarHeight,
    backgroundColor: Colors.background,
    boxShadow: "none",
    transition: transitions,
  };

  const sidebarStyles = {
    position: "relative",
    width: dims.sidebarWidth,
    backgroundColor: Colors.surface,
    borderRadius: `0 ${theme.radius.default} ${theme.radius.default} 0`,
    boxShadow: "none",
    transition: transitions,
  };

  const menuStyles = {
    display: "flex",
    padding: "10px",
    flexDirection: "column",

    transition: transitions,
  };

  const formStyle = {
    gap: Spacing.small,
    margin: Spacing.large,
  };
  const modalContentStyle = {
    backgroundColor: Colors.surface,
    padding: `calc(${Spacing.large} * 2) ${Spacing.large} ${Spacing.large} ${Spacing.large}`,

    borderRadius: "4px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
    transition: transitions,
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%) scale(0)",
  };

  const modalContentOpenStyle = {
    transform: "translate(-50%, -50%) scale(1)",
  };

  const modalBackdrop = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: `${Colors.background}90`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: transitions,
  };
  return {
    navbarStyles,
    sidebarStyles,
    contentBoxStyle,
    menuStyles,
    formStyle,
    modalContentStyle,
    modalBackdrop,
    modalContentOpenStyle,
  };
};
