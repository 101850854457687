import React, { useState } from "react";
import { Box, Typography, Button } from "../../ui/Index";
import UserCompanyForm from "../forms/UserCompanyForm";
import { AnimationProvider } from "../../provider/AnimationProvider";
import ChangeCompDataForm from "../forms/ChangeCompDataForm";
const ChangeBaseCompInfoModal = ({
  userData,
  compData,
  theme,
  setOnSubmit,
}) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <AnimationProvider>
        <Typography variant={"h2"}>Change Baseinfo Company</Typography>
        <ChangeCompDataForm
          compData={compData}
          userData={userData}
          theme={theme}
          setOnSubmit={setOnSubmit}
        />
      </AnimationProvider>
    </Box>
  );
};

export default ChangeBaseCompInfoModal;
