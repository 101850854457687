// AnimationContext.js
import React, { createContext, useContext, useState } from "react";
import {
  fadeAnimation,
  slideAnimation,
  zoomAnimation,
} from "../anim/animPager";

const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
  const [animationType, setAnimationType] = useState("fade");

  const getModalAnimationStyles = (
    pageIndex,
    fadeOut,
    animating,
    currentPage
  ) => {
    switch (animationType) {
      case "fade":
        return fadeAnimation(pageIndex, fadeOut, animating, currentPage);
      case "slide":
        return slideAnimation(pageIndex, fadeOut, animating, currentPage);
      case "zoom":
        return zoomAnimation(pageIndex, fadeOut, animating, currentPage);
      default:
        return {};
    }
  };

  const value = {
    getModalAnimationStyles,
    setAnimationType,
  };

  return (
    <AnimationContext.Provider value={value}>
      {children}
    </AnimationContext.Provider>
  );
};

export const useAnimation = () => useContext(AnimationContext);
