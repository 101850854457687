import React from "react";

const Box = ({ children, style = {}, onClick }) => {
  return (
    <div
      onClick={onClick} // Correctly setting the onClick handler
      style={style} // Correctly applying the style prop
    >
      {children}
    </div>
  );
};

export default Box;
