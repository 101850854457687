import React, { useContext, useEffect, useState } from "react";
import { Modal } from "../ui/Index";
import { useModalContext } from "./context/ModalContext";
import UserFirstTimeModal from "../widgets/modals/UserFirstTimeModal";
import ChangeBaseCompInfoModal from "../widgets/modals/ChangeBaseCompInfo";
import AddCustomerModal from "../widgets/modals/AddCustomerModal";
import AddcInputModal from "../widgets/modals/AddcInputModal";
const GlobalModal = ({ t, theme, isMobile, userData, compData }) => {
  const { context, changeModalContext } = useModalContext();
  const [isCloseButton, setIsCloseButton] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpenChange = (isOpen) => {
    setIsModalOpen(isOpen);
  };
  useEffect(() => {
    console.log(context);
    if (context === "") {
      setIsModalOpen(false);
    } else {
      setIsModalOpen(true);
    }
    if (context === "UserFirstTime") {
      setIsCloseButton(false);
    }
  }, [context]);

  //This checks the comp_key value app_users inidividually instead of given context
  useEffect(() => {
    if (userData && userData.usr_first_time !== undefined) {
      console.log(userData.usr_first_time + " is already");
      const checkFirstTimeLogin = async () => {
        if (userData.usr_first_time) {
          changeModalContext("UserFirstTime");
        }
      };
      checkFirstTimeLogin();
    }
  }, [userData, changeModalContext]);
  ////////////////////////////////
  let modalContent;

  switch (context) {
    case "UserFirstTime":
      modalContent = (
        <UserFirstTimeModal
          theme={theme}
          isMobile={isMobile}
          userData={userData}
          compData={compData}
        />
      );
      break;
    case "newCustomer":
      modalContent = (
        <AddCustomerModal
          t={t}
          theme={theme}
          isMobile={isMobile}
          setOnSubmit={handleModalOpenChange}
          compData={compData}
        />
      );
      break;
    case "changeCompBaseData":
      modalContent = (
        <ChangeBaseCompInfoModal
          theme={theme}
          isMobile={isMobile}
          compData={compData}
          userData={userData}
          setOnSubmit={handleModalOpenChange}
        ></ChangeBaseCompInfoModal>
      );
      break;
    case "AddcInput":
      modalContent = (
        <AddcInputModal
          t={t}
          theme={theme}
          isMobile={isMobile}
          setOnSubmit={handleModalOpenChange}
          compData={compData}
        ></AddcInputModal>
      );
      break;
    default:
      modalContent = <div>Default Modal Content</div>;
  }

  const handleClose = () => {
    changeModalContext("");
  };

  return (
    <Modal
      onClose={handleClose}
      isOpen={isModalOpen}
      theme={theme}
      isMobile={isMobile}
      isCloseButton={isCloseButton}
    >
      {modalContent}
    </Modal>
  );
};

export default GlobalModal;
