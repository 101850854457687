import React, { useState } from "react";
import styled from "styled-components";
import Box from "./Box";
import { EditButton } from "../../styles/ButtonStyles";
import { useModalContext } from "../../layout/context/ModalContext";

const ContentWrapper = styled.div`
  padding: 1rem;
`;

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1rem;
`;

const GridItem = styled.div`
  padding: 0.5rem;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.3rem;
  font-size: 0.8rem;
  border: none;
  background-color: transparent;
  outline: none;
`;

const BaseData = ({ compData }) => {
  const { changeModalContext, reloadModalPage } = useModalContext();

  const dataFields = [
    { label: "Company ID:", value: compData.comp_id },
    { label: "Company Key:", value: compData.comp_key },
    { label: "Company Name:", value: compData.comp_name },
    { label: "Company Type:", value: compData.comp_type || "N/A" },
    { label: "Street:", value: compData.comp_street },
    { label: "Postal Code:", value: compData.comp_pcode },
    { label: "State:", value: compData.comp_state },
    { label: "Country:", value: compData.comp_country },
    { label: "VAT:", value: compData.comp_vat },
  ];
  const onEditClick = () => {
    changeModalContext("changeCompBaseData");
  };
  return (
    <Box>
      <ContentWrapper>
        <h2 style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
          Company Base Data
        </h2>
        <GridWrapper>
          {dataFields.map((field, index) => (
            <GridItem key={index}>
              <strong>{field.label}</strong>
              <Input type="text" defaultValue={field.value} readOnly />
            </GridItem>
          ))}
          <EditButton type="button" onClick={onEditClick}>
            Edit Company base data
          </EditButton>
        </GridWrapper>
      </ContentWrapper>
    </Box>
  );
};

export default BaseData;
