import React, { useState, useEffect } from "react";
import * as IndexUI from "../ui/Index";
import { LayoutStyles } from "./context/LayoutStyles";
import { dimsDesktop, dimsMobile } from "../../constants/Dimensions";
import Menu from "../widgets/Menu";

const NavBar = ({ t, isMobile, theme, userData, compData }) => {
  const { navbarStyles } = LayoutStyles(theme, isMobile);

  useEffect(() => {
    const fetchData = async () => {};
    console.log("NAVBAR: " + compData, userData);
    fetchData();
  }, []);

  const dims = isMobile ? dimsMobile : dimsDesktop;

  return (
    <IndexUI.Box
      style={{
        ...navbarStyles,
      }}
    >
      <IndexUI.Typography variant={"h3"}>NAME E.V</IndexUI.Typography>

      <Menu t={t} theme={theme} isMobile={isMobile} />

      <IndexUI.Box style={{ display: "flex" }}>
        {userData && (
          <IndexUI.Avatar
            t={t}
            size={dims.AvatarSize}
            src="https://ninasobstundgemuese.soldyn.de/img/image-1704244073076-870237500.png"
            name={userData.usr_email}
          />
        )}
        <IndexUI.ThemeSwitcher />
      </IndexUI.Box>
    </IndexUI.Box>
  );
};

export default NavBar;
