import React from "react";
import styled from "styled-components";

// Styled Components
const BreadcrumbContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BreadcrumbItem = styled.span`
  margin-right: 5px;
  color: ${({ active }) => (active ? "#333" : "#999")};
  font-weight: ${({ active }) => (active ? "bold" : "normal")};
  cursor: ${({ active }) => (active ? "default" : "pointer")};
`;

// Breadcrumb Component
const Breadcrumb = ({ items, onItemClick }) => {
  return (
    <BreadcrumbContainer>
      {items.map((item, index) => (
        <React.Fragment key={index}>
          <BreadcrumbItem
            active={index === items.length - 1}
            onClick={() => onItemClick && onItemClick(item)}
          >
            {item}
          </BreadcrumbItem>
          {index < items.length - 1 && <span>&gt;</span>}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
