import React, { useState } from "react";
import styled, { keyframes } from "styled-components";

// Define animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
  }
`;

// Styled components
const Container = styled.div`
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 1s ease-in-out;
  max-width: 800px;
  margin: 2rem auto;
`;

const Section = styled.div`
  margin-bottom: 1.5rem;
  animation: ${slideUp} 0.5s ease-in-out;
`;

const Title = styled.h1`
  margin-bottom: 1rem;
  color: #333;
`;

const Subtitle = styled.h2`
  margin-bottom: 0.5rem;
  color: #555;
`;

const Placeholder = styled.div`
  height: 2rem;
  background-color: #f0f0f0;
  border-radius: 4px;
  margin-bottom: 1rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin: 0.5rem 0;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.75rem 1.5rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: #0056b3;
  }
  &:disabled {
    background-color: #d6d6d6;
    cursor: not-allowed;
  }
`;

const StepNav = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const StepButton = styled.button`
  padding: 0.5rem 1rem;
  background-color: ${(props) => (props.active ? "#007bff" : "#e0e0e0")};
  color: ${(props) => (props.active ? "#fff" : "#333")};
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => (props.active ? "#0056b3" : "#c0c0c0")};
  }
`;

const ContactCheckbox = styled.input`
  margin-right: 0.5rem;
`;

const ContactLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.div`
  color: #d9534f;
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
`;

const TableHeader = styled.th`
  border: 1px solid #ddd;
  padding: 0.75rem;
  background-color: #f8f9fa;
  text-align: left;
`;

const TableData = styled.td`
  border: 1px solid #ddd;
  padding: 0.75rem;
`;

const contacts = [
  { id: 1, name: "John Doe" },
  { id: 2, name: "Jane Smith" },
  { id: 3, name: "Alice Johnson" },
  { id: 4, name: "Bob Brown" },
];

const dummyInvoices = [
  {
    id: 1,
    customer: "John Doe",
    position: "Position 1",
    quantity: 3,
    price: 100,
    total: 300,
  },
  {
    id: 2,
    customer: "Jane Smith",
    position: "Position 2",
    quantity: 2,
    price: 150,
    total: 300,
  },
];

const BatchInvoiceCreation = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [formData, setFormData] = useState({
    customer: "",
    position: "",
    quantity: 1,
    price: 0,
  });
  const [error, setError] = useState("");
  const [generatedInvoices, setGeneratedInvoices] = useState([]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleContactChange = (contactId) => {
    setSelectedContacts((prevSelectedContacts) => {
      if (prevSelectedContacts.includes(contactId)) {
        return prevSelectedContacts.filter((id) => id !== contactId);
      } else {
        return [...prevSelectedContacts, contactId];
      }
    });
  };

  const handleGenerateInvoices = () => {
    setLoading(true);
    setError("");
    // Simulate API call
    setTimeout(() => {
      setGeneratedInvoices(dummyInvoices);
      setLoading(false);
      setCurrentStep(3);
    }, 2000);
  };

  const handleNextStep = () => {
    if (currentStep === 1 && selectedContacts.length < 2) {
      setError("Please select at least 2 contacts.");
      return;
    }
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <Container>
      <Title>Batch Invoice Creation</Title>
      <StepNav>
        <StepButton onClick={handlePreviousStep} disabled={currentStep === 1}>
          Previous
        </StepButton>
        <StepButton
          onClick={handleNextStep}
          active={currentStep === 3}
          disabled={
            currentStep === 3 &&
            currentStep === 1 &&
            selectedContacts.length < 2
          }
        >
          {currentStep === 3 ? "Finish" : "Next"}
        </StepButton>
      </StepNav>

      {error && <ErrorMessage>{error}</ErrorMessage>}

      {currentStep === 1 && (
        <Section>
          <Subtitle>Customer Selection</Subtitle>
          <div>
            {contacts.map((contact) => (
              <ContactLabel key={contact.id}>
                <ContactCheckbox
                  type="checkbox"
                  checked={selectedContacts.includes(contact.id)}
                  onChange={() => handleContactChange(contact.id)}
                />
                {contact.name}
              </ContactLabel>
            ))}
          </div>
        </Section>
      )}

      {currentStep === 2 && (
        <Section>
          <Subtitle>Position Details</Subtitle>
          <Input
            type="text"
            name="customer"
            placeholder="Customer"
            value={formData.customer}
            onChange={handleInputChange}
          />
          <Input
            type="text"
            name="position"
            placeholder="Position"
            value={formData.position}
            onChange={handleInputChange}
          />
          <Input
            type="number"
            name="quantity"
            placeholder="Quantity"
            value={formData.quantity}
            onChange={handleInputChange}
          />
          <Input
            type="number"
            name="price"
            placeholder="Price"
            value={formData.price}
            onChange={handleInputChange}
          />
        </Section>
      )}

      {currentStep === 3 && (
        <Section>
          <Subtitle>Overview Table</Subtitle>
          <Table>
            <thead>
              <tr>
                <TableHeader>ID</TableHeader>
                <TableHeader>Customer</TableHeader>
                <TableHeader>Position</TableHeader>
                <TableHeader>Quantity</TableHeader>
                <TableHeader>Price</TableHeader>
                <TableHeader>Total</TableHeader>
              </tr>
            </thead>
            <tbody>
              {generatedInvoices.map((invoice) => (
                <tr key={invoice.id}>
                  <TableData>{invoice.id}</TableData>
                  <TableData>{invoice.customer}</TableData>
                  <TableData>{invoice.position}</TableData>
                  <TableData>{invoice.quantity}</TableData>
                  <TableData>{invoice.price}</TableData>
                  <TableData>{invoice.total}</TableData>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button onClick={handleGenerateInvoices} disabled={loading}>
            {loading ? "Generating..." : "Generate Invoices"}
          </Button>
        </Section>
      )}
    </Container>
  );
};

export default BatchInvoiceCreation;
