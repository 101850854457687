import React, { useState } from "react";
import { LoginForm, RegisterForm } from "../../widgets/Index";
import { Box, Typography } from "../../ui/Index";
import { LoginRegisterStyle } from "../../styles/LoginRegisterStyle";
import { useTheme } from "../../provider/ThemeProvider";
import { CardStyles } from "../../styles/CardStyles";
import { useTranslation } from "react-i18next";

const LoginRegister = ({ isMobile }) => {
  const { theme } = useTheme();
  const colors = theme.colors;
  const spacing = theme.spacing;
  const [isLoginFormVisible, setIsLoginFormVisible] = useState(true);
  const { t } = useTranslation();
  const [isAnimating, setIsAnimating] = useState(false);
  const {
    registerBoxStyle,
    loginBoxStyle,
    authBoxStyle,
    shrinkAnimation,
    growAnimation,
  } = LoginRegisterStyle(theme, isMobile);
  const toggleForm = () => {
    setIsAnimating(true);

    setTimeout(() => {
      setIsAnimating(false);
      setIsLoginFormVisible(!isLoginFormVisible);
    }, 600); // Wait for animation to complete before resetting isAnimating
  };

  return (
    <Box style={{ ...authBoxStyle }}>
      {isLoginFormVisible ? (
        <Box
          style={{
            ...loginBoxStyle,
            ...(isAnimating ? shrinkAnimation : growAnimation), // Apply animation based on isAnimating state
          }}
        >
          <LoginForm t={t} isMobile={isMobile} theme={theme} />
          <Typography variant={"link"} onClick={toggleForm}>
            {t("toSignUp")}
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            ...registerBoxStyle,
            ...(isAnimating ? shrinkAnimation : growAnimation),
          }}
        >
          <RegisterForm t={t} isMobile={isMobile} theme={theme} />
          <Typography variant={"link"} onClick={toggleForm}>
            {t("toLogin")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LoginRegister;
