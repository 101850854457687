import React, { useState } from "react";
import { Box, Typography, Button, Divider } from "../../ui/Index";
import UserCompanyForm from "../forms/UserCompanyForm";
import { AnimationProvider } from "../../provider/AnimationProvider";
import AddcInputForm from "../forms/AddcInputForm";
const AddcInputModal = ({ t, theme, setOnSubmit, compData }) => {
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <AnimationProvider>
        <Typography variant={"h2"}>{t("add.addcfield")}</Typography>
        <Divider />
        <AddcInputForm
          theme={theme}
          setOnSubmit={setOnSubmit}
          compData={compData}
          t={t}
        />
      </AnimationProvider>
    </Box>
  );
};

export default AddcInputModal;
