import React, { useState } from "react";
import { Box, Typography, Button } from "../../ui/Index";
import UserCompanyForm from "../forms/UserCompanyForm";
import Pager from "../Pager";
import { AnimationProvider } from "../../provider/AnimationProvider";
const UserFirstTimeModal = ({ theme, userData, compData }) => {
  const [compFormData, setCompFormData] = useState({});
  const [isCompDataValid, setIsCompDataValid] = useState(false);
  const onNextPage = () => {
    console.log("onNextPage");
    console.log(compFormData);
  };
  const onPrevPage = () => {};
  return (
    <Box>
      <AnimationProvider>
        <Pager
          onNextPage={onNextPage}
          onPrevPage={onPrevPage}
          showButtons={false}
          disabledNext={!isCompDataValid}
        >
          <div>
            <Typography variant={"h2"}>First Account Informations</Typography>
            <UserCompanyForm
              theme={theme}
              setFormData={setCompFormData}
              setIsCompDataValid={setIsCompDataValid}
              userData={userData}
              compData={compData}
            />
          </div>
          <div>
            <Typography variant={"h2"}>First Account Informations 2</Typography>
          </div>
          <div>
            <Typography variant={"h2"}>First Account Informations 3</Typography>
          </div>
        </Pager>
      </AnimationProvider>
    </Box>
  );
};

export default UserFirstTimeModal;
