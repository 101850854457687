import React from "react";
import styled from "styled-components";

const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;

const ItemLabel = styled.label`
  font-weight: bold;
`;

const StructureItem = ({ label, children }) => {
  return (
    <ItemContainer>
      <ItemLabel>{label}</ItemLabel>
      {children}
    </ItemContainer>
  );
};

export default StructureItem;
