import React, { useState } from "react";
import styled from "styled-components";
import { Form, TextInput, Dropdown, Checkbox, Divider } from "../../ui/Index";
import { SuccessButton } from "../../styles/ButtonStyles";
import { LayoutStyles } from "../../layout/context/LayoutStyles";
import { useModalContext } from "../../layout/context/ModalContext";
import { fetchAllPrivContactData } from "../../../services/customerService";
import { useComp } from "../../../utils/api/provider/UserProvider";
import { TextFormBox } from "../../styles/BoxStyles";
import { addNewcInput } from "../../../services/customFieldService";

const AddcInputForm = ({ theme, isMobile, setOnSubmit, compData, t }) => {
  const { formStyle } = LayoutStyles(theme, isMobile);
  const { changeModalContext, reloadModalPage } = useModalContext();
  const [error, setError] = useState("");
  const [dropdownerror, setDropdownError] = useState("");
  const [cInputData, setCInputData] = useState({
    name: "",
  });

  const [textInputs, setTextInputs] = useState([
    {
      name: "name",
      title: "Textfeld Name",
      type: "text",
      value: "",
    },
  ]);

  const [selectedType, setSelectedType] = useState(null);
  const [selectedUsecase, setSelectedUsecase] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const typeOptions = [
    { value: "text", label: "text" },
    { value: "number", label: "number" },
  ];

  const requiredFields = ["name"];

  const onInputChange = (e, fieldName) => {
    const { value } = e.target;
    setTextInputs((prevInputs) => {
      return prevInputs.map((input) => {
        if (input.name === fieldName) {
          return { ...input, value: value };
        }
        return input;
      });
    });

    setCInputData((prevData) => ({
      ...prevData,
      [fieldName]: value,
    }));
  };

  const addcInputData = async () => {
    setOnSubmit(false);

    console.log(compData.comp_key);

    try {
      await addNewcInput(compData.comp_key, {
        name: cInputData.name,
        type: "text",
        usecase: selectedUsecase,
        required: isRequired,
      });
    } catch (err) {
      console.error(`Error adding customer:`, err.message);
      throw err;
    } finally {
      changeModalContext("");
      // reloadModalPage();
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    console.log("selected type selected" + selectedType);

    setDropdownError("");
    setError("");
    addcInputData();
  };

  return (
    <TextFormBox>
      <Form style={{ ...formStyle }} onSubmit={onSubmit}>
        {textInputs.map((textInput, index) => (
          <TextInput
            key={index}
            title={textInput.title}
            type={textInput.type}
            placeholder={textInput.title}
            value={textInput.value}
            onChange={(e) => onInputChange(e, textInput.name)}
            error={error && requiredFields.includes(textInput.name) && error}
          />
        ))}

        <Divider />

        <SuccessButton type="submit">Hinzufügen</SuccessButton>
      </Form>
    </TextFormBox>
  );
};

export default AddcInputForm;
