export const filterDataByType = (data, dataType) => {
  return Object.keys(data)
    .filter((key) => data[key].type === dataType)
    .map((key) => data[key]);
};

export const filterDataByTitle = (data, datatitle) => {
  return Object.keys(data)
    .filter((key) => data[key].title === datatitle)
    .map((key) => data[key]);
};

export const filterTypeProperty = (data) => {
  return data.map((item) => {
    const { type, ...rest } = item;
    return rest;
  });
};
export const filterTitleProperty = (data) => {
  return data.map((item) => {
    const { title, ...rest } = item;
    return rest;
  });
};

// utils.js

export const formatContactData = (data) => {
  // Check if data is an array and has at least one element
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid or empty data array provided.");
    return {};
  }

  const contactData = data[0]; // Assuming data is an array with a single object
  const formattedData = {};
  console.log("contactData", contactData);
  // Iterate over each key in contactData
  Object.entries(contactData).forEach(([key, value]) => {
    switch (key) {
      case "phone": {
        try {
          // Default to an empty array if value is null
          const phoneArray =
            value && Array.isArray(value) ? value : JSON.parse(value || "[]");
          const phoneData = phoneArray.map((phoneObj, index) => ({
            type: "phone",
            title: phoneObj.phone_type,
            phone_type: phoneObj.phone_type,
            value: phoneObj.value,
          }));

          phoneData.forEach((phone, index) => {
            formattedData[`phone_${index}`] = phone;
          });
        } catch (error) {
          console.error(`Error parsing phone data for key "${key}":`, error);
          formattedData[key] = {
            type: "unknown",
            title: key,
            value: value,
          };
        }
        break;
      }
      case "customInputs": {
        try {
          // Default to an empty array if value is null
          const customInputsArray =
            value && Array.isArray(value) ? value : JSON.parse(value || "[]");
          const customInputsData = customInputsArray.map((inputObj, index) => ({
            type: "customInput",
            key: inputObj.key,
            title: inputObj.name,
            value: inputObj.value,
          }));

          customInputsData.forEach((input, index) => {
            formattedData[`customInput_${index}`] = input;
          });
        } catch (error) {
          console.error(
            `Error parsing customInputs data for key "${key}":`,
            error
          );
          formattedData[key] = {
            type: "unknown",
            title: key,
            value: value,
          };
        }
        break;
      }
      case "memberStatus": {
        formattedData[key] = {
          type: "status",
          title: "Member Status",
          value: value,
        };
        break;
      }
      default: {
        formattedData[key] = {
          type: "common",
          title: key,
          value: value,
        };
        break;
      }
    }
  });

  return formattedData;
};

export const formatCompanyContactData = (data) => {
  // Check if data is an array and has at least one element
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Invalid or empty data array provided.");
    return {};
  }

  const contactData = data[0];
  const formattedData = {};

  // Iterate over each key in contactData
  Object.entries(contactData).forEach(([key, value]) => {
    switch (key) {
      case "compPhone": {
        try {
          // Default to an empty array if value is null
          const phoneArray =
            value && Array.isArray(value) ? value : JSON.parse(value || "[]");
          const phoneData = phoneArray.map((phoneObj, index) => ({
            type: "phone",
            title: phoneObj.phone_type,
            phone_type: phoneObj.phone_type,
            value: phoneObj.value,
          }));

          phoneData.forEach((phone, index) => {
            formattedData[`phone_${index}`] = phone;
          });
        } catch (error) {
          console.error(`Error parsing phone data for key "${key}":`, error);
          formattedData[key] = {
            type: "unknown",
            title: key,
            value: value,
          };
        }
        break;
      }
      case "compCustomInputs": {
        try {
          // Default to an empty array if value is null
          const customInputsArray =
            value && Array.isArray(value) ? value : JSON.parse(value || "[]");
          const customInputsData = customInputsArray.map((inputObj, index) => ({
            type: "customInput",
            key: inputObj.key,
            title: inputObj.name,
            value: inputObj.value,
          }));

          customInputsData.forEach((input, index) => {
            formattedData[`customInput_${index}`] = input;
          });
        } catch (error) {
          console.error(
            `Error parsing customInputs data for key "${key}":`,
            error
          );
          formattedData[key] = {
            type: "unknown",
            title: key,
            value: value,
          };
        }
        break;
      }
      default: {
        formattedData[key] = {
          type: "common",
          title: key,
          value: value,
        };
        break;
      }
    }
  });

  return formattedData;
};

export const filteredPhoneDataForUpdate = (phoneData) => {
  const initData = filterTypeProperty(phoneData);
  const finalData = filterTitleProperty(initData);
  return finalData;
};
