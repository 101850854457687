export const getToken = () => {
  const tokenWithBearer = localStorage.getItem("token");
  if (tokenWithBearer) {
    if (tokenWithBearer.startsWith("Bearer")) {
      // If token starts with "Bearer ", remove the first 7 characters ("Bearer ")
      return tokenWithBearer.slice(7);
    } else {
      // If it doesn't start with "Bearer ", return the token as is
      return tokenWithBearer;
    }
  } else {
    return null;
  }
};
export const setToken = (token) => {
  // Check if token starts with "Bearer "
  if (token.startsWith("Bearer")) {
    // If it does, remove the "Bearer " prefix before storing in localStorage
    localStorage.setItem("token", token.slice(7)); // Remove the first 7 characters ("Bearer ")
  } else {
    // If it doesn't start with "Bearer ", store the token as is
    localStorage.setItem("token", token);
  }
};
export const deleteToken = () => {
  localStorage.removeItem("token");
};
////////////////////////////////////
export const defaultTransitions = (theme) => `
  background-color ${theme.timing.Themechange} ease-in-out, 
  color ${theme.timing.Themechange} ease-in-out, 
  height ${theme.timing.Themechange} ease-in-out,
  width ${theme.timing.Themechange} ease-in-out,
  padding ${theme.timing.Themechange} ease-in-out,
  transform ${theme.timing.Themechange} ease-in-out,
  opacity ${theme.timing.Themechange} ease-in-out,
  visibility ${theme.timing.Themechange} ease-in-out
`;

/////////////////////////////////
export const contactContexts = {
  CUSTOMER_CREATE_NEW: "customerCreateNew",
  CUSTOMER_LIST_ALL: "customerListAll",
  CUSTOMER_LIST_PRIV: "customerListPriv",
  CUSTOMER_LIST_PRIV_NO_MEMBER: "customerListPrivNoMember",
  CUSTOMER_LIST_COMP: "customerListComp",
};
export const docsContexts = {
  DOCS_CREATE_NEW: "docsCreateNew",
  DOCS_LIST_ALL: "docsListAll",
  DOCS_POSITIONS: "docsPositions",
  DOCS_TEMPLATES: "docsTemplates",
};

////////////////////////////////////////////////////////////////////////
export const currentDate = new Date().toISOString().split("T")[0];
