import React, { useState } from "react";
import styled from "styled-components";

// Styled Components
const TimePickerContainer = styled.div`
  display: inline-block;
`;

const TimeInput = styled.input`
  padding: 8px 12px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
`;

// TimePicker Component
const TimePicker = () => {
  const [selectedTime, setSelectedTime] = useState("");

  const handleTimeChange = (event) => {
    setSelectedTime(event.target.value);
  };

  return (
    <TimePickerContainer>
      <TimeInput type="time" value={selectedTime} onChange={handleTimeChange} />
    </TimePickerContainer>
  );
};

export default TimePicker;
