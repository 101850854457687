import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";

// Styles for different sections of the document
const styles = StyleSheet.create({
  page: {
    padding: 30,
    position: "relative",
    minHeight: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
    borderBottomWidth: 1,
    borderBottomColor: "#bfbfbf",
    paddingBottom: 10,
  },
  logo: {
    width: 100,
    height: 50,
    borderWidth: 1,
    borderColor: "#bfbfbf",
    borderStyle: "dashed",
  },
  headerText: {
    fontSize: 20,
    textAlign: "center",
    flex: 1,
    marginHorizontal: 10,
  },
  footer: {
    fontSize: 10,
    textAlign: "center",
    position: "absolute",
    bottom: 30,
    left: 0,
    right: 0,
    borderTopWidth: 1,
    borderTopColor: "#bfbfbf",
    paddingTop: 10,
  },
  section: {
    marginBottom: 20,
    marginTop: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#bfbfbf",
    marginTop: 10,
    marginBottom: 10,
  },
  tableRow: { flexDirection: "row" },
  tableColHeader: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#f3f3f3",
    textAlign: "center",
    padding: 5,
  },
  tableCol: {
    width: "33%",
    borderStyle: "solid",
    borderWidth: 1,
    textAlign: "center",
    padding: 5,
  },
  tableCellHeader: { fontSize: 12, fontWeight: "bold" },
  tableCell: { fontSize: 12 },
});

// Function to get the label for a key
const getLabelForKey = (key, recipientInputs = [], senderInputs = []) => {
  // Check recipient inputs
  const recipientInput = recipientInputs.find((input) => input.key === key);
  if (recipientInput) return recipientInput.label;

  // Check sender inputs
  const senderInput = senderInputs.find((input) => input.key === key);
  if (senderInput) return senderInput.label;

  // Fallback to key if no label is found
  return key;
};

// Header component with conditional logo rendering
const MyHeader = ({ logoUrl }) => (
  <View style={styles.header}>
    {logoUrl ? (
      <Image style={styles.logo} src={logoUrl} />
    ) : (
      <View style={styles.logo}>
        <Text style={{ textAlign: "center", marginTop: 15 }}>Logo</Text>
      </View>
    )}
    <Text style={styles.headerText}>My Company Invoice</Text>
    <View style={{ width: 100 }} /> {/* Placeholder for spacing if needed */}
  </View>
);

// Footer component
const MyFooter = () => (
  <View style={styles.footer}>
    <Text>Page {Math.ceil(Page.pageNumber / 1)}</Text>
    <Text>© 2024 My Company</Text>
  </View>
);

const CreateDocument = ({
  invoiceData,
  documentRecipientInputs,
  documentSenderInputs,
  logoUrl,
}) => {
  if (!invoiceData) {
    return <Text>Loading...</Text>;
  }

  const { positions = [], ...rest } = invoiceData;

  // Calculate the total sum of all positions
  const totalSum = positions.reduce((sum, position) => {
    return sum + position.price * position.amount;
  }, 0);

  return (
    <Document>
      <Page style={styles.page} wrap>
        <MyHeader logoUrl={logoUrl} />
        <View style={styles.section}>
          {Object.entries(rest).map(([key, value]) => {
            const label = getLabelForKey(
              key,
              documentSenderInputs || [],
              documentRecipientInputs || []
            );
            return (
              <Text
                key={key}
                style={{ marginBottom: 5 }}
              >{`${label}: ${value}`}</Text>
            );
          })}
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <Text style={[styles.tableColHeader, styles.tableCellHeader]}>
                Name
              </Text>
              <Text style={[styles.tableColHeader, styles.tableCellHeader]}>
                Price
              </Text>
              <Text style={[styles.tableColHeader, styles.tableCellHeader]}>
                Amount
              </Text>
            </View>
            {positions.map((position, index) => (
              <View key={index} style={styles.tableRow}>
                <Text style={styles.tableCol}>{position.name}</Text>
                <Text style={styles.tableCol}>{position.price}</Text>
                <Text style={styles.tableCol}>{position.amount}</Text>
              </View>
            ))}
            {/* Display the total sum */}
            <View style={styles.tableRow}>
              <Text
                style={[
                  styles.tableCol,
                  { textAlign: "right", fontWeight: "bold" },
                ]}
              >
                Total
              </Text>
              <Text
                style={[
                  styles.tableCol,
                  { textAlign: "center", fontWeight: "bold" },
                ]}
              >
                {totalSum}
              </Text>
              <Text style={styles.tableCol}></Text>
            </View>
          </View>
        </View>
        <MyFooter />
      </Page>
    </Document>
  );
};

export default CreateDocument;
