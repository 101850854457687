import React from "react";
import styled from "styled-components";
import Typography from "./Typography";

const Card = ({ title, children, style = {} }) => {
  return (
    <div style={style}>
      <Typography variant={"h3"}>{title}</Typography>
      <p>{children}</p>
    </div>
  );
};

export default Card;
