import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

const DocsContext = createContext();

export const DocsProvider = ({ children }) => {
  const [context, setContext] = useState();
  const nav = useNavigate();
  const changeDocsContext = (newContext) => {
    setContext(newContext);
    nav("/docs");
  };

  return (
    <DocsContext.Provider value={{ context, changeDocsContext }}>
      {children}
    </DocsContext.Provider>
  );
};

export const useDocsContext = () => useContext(DocsContext);
